import { convertAmtToKMBWithPrecision, roundUpCash } from "../../common/CurrencyConverter"

export function getCompanyDataList(param) {
    let companyDataList = []
    if (param[0] && param[0].Turnover) {
        param.forEach((companyData) => {
            companyDataList.push({
                company: companyData.CompanyName?.replace(/\w\S*/g, function (t) { return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase() }),
                value: `£${convertAmtToKMBWithPrecision(companyData.Turnover)}`.includes("b") ? `£${convertAmtToKMBWithPrecision(companyData.Turnover)}n` : `£${convertAmtToKMBWithPrecision(companyData.Turnover)}`,
                reg: companyData.REG
            })
        })
    }
    if (param[0] && param[0].Incorporated) {
        param.forEach((companyData) => {
            companyDataList.push({
                company: companyData.CompanyName?.replace(/\w\S*/g, function (t) { return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase() }),
                value: companyData.Incorporated.split("-")[0],
                reg: companyData.REG
            })
        })
    }
    if (param[0] && param[0].TurnoverCAGR3Yr) {
        param.forEach((companyData) => {
            companyDataList.push({
                company: companyData.CompanyName?.replace(/\w\S*/g, function (t) { return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase() }),
                value: `${Math.round(companyData.TurnoverCAGR3Yr * 100)}%`,
                reg: companyData.REG
            })
        })
    }
    return companyDataList;
}

export const getProfilePieChartData = (param) => {
    let chartArray = []
    let sum = 0
    let percentage;
    if (param[0] && param[0].SizeTag) {
        param.forEach((data) => {
            sum = Number(data.SizeCount) + sum;
        })
        param.map((data) => {
            percentage = roundUpCash((Number(data.SizeCount) * 100 / sum),1);
            chartArray.push({ tag: data.SizeTag, percentage });
        })
    }
    if (param[0] && param[0].AgeTag) {
        param.forEach((data) => {
            sum = Number(data.AgeCount) + sum;
        })
        param.forEach((data) => {
            percentage = Math.round((Number(data.AgeCount) * 100 / sum))
            chartArray.push({ tag: data.AgeTag, percentage });
        })
    }
    if (param[0] && param[0].GrowthTag) {
        param.forEach((data) => {
            sum = Number(data.GrowthCount) + sum;
        })
        param.forEach((data) => {
            percentage = Math.round((Number(data.GrowthCount) * 100 / sum));
            chartArray.push({ tag: data.GrowthTag, percentage });
        })
    }
    chartArray = chartArray.sort((a, b) => b.percentage - a.percentage)
    return chartArray;
}