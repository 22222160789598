
module.exports = { 
    capitalizeAllWords: (data) => {
        return data ? data.toLowerCase().replace(/(^|\W|\d|_)([a-z])/gi, (match, prev, letter) => {
            return prev + letter.toUpperCase();
        }) : ""
    },
    capitalizeSentence: (sentence) => {
        if(!sentence) return null;
        let words = sentence?.split(" ");
        words = words.map((word) => {
            return module.exports.capitalizeAllWords(word);
        })
        return words.join(" ").trim();
    },
    handleBgImagesLazyLoad: (className = "lazy-bg") => {
        const targetElements = document.querySelectorAll(`${className ? '.' + className : ""}`);
        const observer = new IntersectionObserver((entries, observer) => {
            entries.map((entry) => {
                if(entry.isIntersecting) {
                    const lazyBgElement = entry.target;
                    lazyBgElement.classList.add("lazyloaded-bg");
                    lazyBgElement.classList.remove('lazy-bg');
                    observer.unobserve(lazyBgElement);
                }
            })
        });
        targetElements.length > 0 && targetElements.forEach((target) => {
            observer.observe(target);
        })
    }
}