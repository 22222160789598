import { all, fork } from 'redux-saga/effects';

import {
    watchCallSearchApiSaga,
    watchgetDirectorListRequestSaga,
    watchgetIndustryListRequestSaga,
    watchgetShareholderListRequestSaga,
    watchgetIndustryFilterDataSaga,
    watchGetCompanySearchResultsSaga,
    watchCallSingleSearchApiSaga,
    watchCallGetSaveSearchesSaga,
    watchCallSaveCurrentSearchSaga,
    watchCallDeleteSavedSearchSaga,
    watchDownloadResults,
    watchIncreaseDownloadResultLimitRequest,
    watchgetChargesListRequestSaga,
    watchResetAllSearchParams,
    watchgetRegionListRequestSaga
} from './searchSaga';
import {
    watchGetCompanyProfileRequest,
    watchGetCompanyHouseFilingsRequest,
    watchGetCompanyHouseDocumentRequest,
    watchGetDirectorStatusListRequest,
    watchGetDirectorShowMoreSaga,
    watchGetShareholderTypeListSaga,
    watchGetShareholderShowMoreSaga,
    watchGetIndustryMultipleSaga,
    watchCallCreditReportSaga,
    watchCheckPdfCreationStatus,
    watchSubmitInvestmentInterestRequest,
    watchSaveIndustryMixChangesSaga,
    watchResetIndustryMixChangesSaga,
    watchDownloadFinancialsSaga,
    watchGetCompanyChargesRequest,
    watchGetCompanyValuationRequest,
    watchGetCompanyValuationUserRequest,
    watchToggleBuyCRModalSaga,
    // watchGetReportPdf
} from './companySaga';
import {
    watchLoginRequestSaga, watchResetPasswordEmailRequest, watchSignupRequest,
    watchResetPasswordRequest, watchClearResetPasswordMessageLogin,
    watchCheckResetPasswordLinkValidity, watchTrackingLoginModal, watchTrackingSignupModal,
    watchTrackingForgotPasswordModal,
    watchTrackingResetPasswordModal, watchHandleLoginSignupRespSaga,
    watchTrackingNewSignupModal, watchTrackingNewLoginModal
} from './loginSignupSaga';
import {
    watchGetUserProfileSaga, watchSaveUserProfileSaga, watchChangePasswordSaga,
    watchContactUsSaga, watchGetCreditReport,watchGetCreditReportPpt, watchCreateAddCardSessionSaga,
    watchCallMakeDefaultPaymentMethodApiSaga, watchSignOutClickSaga,
    watchCompanyHouseEmailNotificationSaga,
    watchGetOrderHistorySaga,
    watchGetValuationReportsList,
    watchGetValuationReportsPptRequest,
    watchGetBusinessPlanReportsPptRequest,
    watchUpdateValuationReportCreditRequest,
    watchSaveVRWhiteLabelDataRequest,
    watchGetVRWhiteLabelInfo,
    watchResetWhiteLabelInfo,
    watchGetBusinessPlansListSaga,
    watchUpdateBusinessPlansCreditRequest,
    watchRegeneratePdfSaga,
    watchRegenerateCpPdfSaga,
    watchRemoveFormData,
    watchSaveAiCurrencyForUserSaga
} from './userAccountSaga';
import { watchGetDirectorDataRequest, watchGetRelatedDirectorRequest } from './directorSaga';
import { watchSendActivityTrackingRequest, watchGetOnloadDataRequest, watchCheckStorageAndSendTrkData, watchCallTrackerAPISaga } from './commonSaga';
import {
    watchCreateStripeSession, watchSubscriptionStatus,
    watchcancelUserSubscriptionSaga, watchCheckFreeSearchForAnonymous, watchCheckAndSetSubscriptionModalData,
    watchHandleSubscriptionSubscribeBtnSaga, watchHandlePaymentSuccessErrorSaga, watchCheckFreeSearchAnonymousForProduct, watchCancelUserFreeTrialSubscriptionSaga
} from './subscriptionSaga';
import { watchGetPopularCompaniesRequest } from './popularCompaniesSaga';

import {
    watchgetBlogListSaga, watchgetBlogDetailSaga
} from './blogSaga';

import { watchGenerateCompanyReport, watchGetCompanyReportUrl, watchGetReportPdf } from './reportSaga'
import { watchSetProductPageScreenSaga, watchHandleProductPlanButtonClickSaga } from './productSaga';
import { watchIndustryProfileSaga, watchIndustryProfileSearchSaga } from './industryProfileSaga';
import { watchGetLocationProfileSaga, watchGetLocationProfileSearchSaga } from './locationProfileSaga';
import { 
    watchGetBusinessPlanDataSaga, watchGetFutureFinancialProjectionDataReqSaga, 
    watchGetIndustryDetailsSaga, watchSaveBusinessPlanDataSaga, 
    watchGetSuggestedCompetitorsSaga, watchSaveFinancialDataSaga, 
    watchBusinessPlanCompanyInsightsSaga, watchUploadTempBussPlanFileSaga, 
    watchGetBusinessPlanPagePreviewSaga, 
    watchGetBusinessPlanIndustryInsightsSaga
} from './businessPlanTemplateSaga';
import {
    watchGetAllOnboardBusinessPlanSaga,
    watchGetBusinessPlanOnboardSaga,
    watchGetLocationBusinessPlan,
    watchSaveOrUpdateBusinessPlanOnboardSaga,
    watchSuggestedNearbyBusinessLocations,
    watchGetActiveBusinessAndSales,
    watchGetAllKeyPeopleSaga,
    watchGetBusinessDescriptionOpenAiSaga,
    watchUpdateBusinessPlansForCreditsSaga
} from './businessPlanSaga';
import { 
    watchAddSelectedCompetitor, watchDownloadCompetitorAnalysisData, 
    watchGetCompetitorAnalysisData, watchGetSelectedCompetitorsAnalysisData, 
    watchRemoveSelectedCompetitor, watchSaveSelectedCompetitor 
} from './competitorAnalysisSaga';
import { 
    watchGetIndustrySicCodesRequest, watchGetSicCodeBasedCompanyListRequest 
} from './industrySicCodesSaga'
import { watchGetCountryListRequest } from './countrySaga';


export default function* rootSaga() {
    yield all([
        fork(watchGetCompanyProfileRequest),
        fork(watchGetCompanyHouseFilingsRequest),
        fork(watchGetCompanyHouseDocumentRequest),
        fork(watchGetCompanySearchResultsSaga),
        fork(watchCallSearchApiSaga),
        fork(watchGetDirectorStatusListRequest),
        fork(watchGetDirectorShowMoreSaga),
        fork(watchGetShareholderTypeListSaga),
        fork(watchGetShareholderShowMoreSaga),
        fork(watchLoginRequestSaga),
        fork(watchSignupRequest),
        fork(watchResetPasswordRequest),
        fork(watchClearResetPasswordMessageLogin),
        fork(watchCheckResetPasswordLinkValidity),
        fork(watchGetUserProfileSaga),
        fork(watchSaveUserProfileSaga),
        fork(watchChangePasswordSaga),
        fork(watchContactUsSaga),
        fork(watchResetPasswordEmailRequest),
        fork(watchGetDirectorDataRequest),
        fork(watchGetRelatedDirectorRequest),
        fork(watchCheckResetPasswordLinkValidity),
        fork(watchgetDirectorListRequestSaga),
        fork(watchgetIndustryListRequestSaga),
        fork(watchgetRegionListRequestSaga),
        fork(watchgetShareholderListRequestSaga),
        fork(watchCreateStripeSession),
        fork(watchSubscriptionStatus),
        fork(watchgetIndustryFilterDataSaga),
        fork(watchGetIndustryMultipleSaga),
        fork(watchSendActivityTrackingRequest),
        fork(watchcancelUserSubscriptionSaga),
        fork(watchCheckFreeSearchForAnonymous),
        fork(watchCheckFreeSearchAnonymousForProduct),
        fork(watchGetOnloadDataRequest),
        fork(watchTrackingLoginModal),
        fork(watchTrackingSignupModal),
        fork(watchTrackingForgotPasswordModal),
        fork(watchTrackingResetPasswordModal),
        // fork(watchCheckFreeDirectorSearchForAnonymous)
        fork(watchCheckStorageAndSendTrkData),
        fork(watchGetCreditReport),
        fork(watchGetCreditReportPpt),
        fork(watchCheckAndSetSubscriptionModalData),
        fork(watchHandleSubscriptionSubscribeBtnSaga),
        fork(watchHandleLoginSignupRespSaga),
        fork(watchHandlePaymentSuccessErrorSaga),
        fork(watchCallCreditReportSaga),
        fork(watchCheckPdfCreationStatus),
        fork(watchCallTrackerAPISaga),
        fork(watchGetPopularCompaniesRequest),
        fork(watchgetBlogListSaga),
        fork(watchgetBlogDetailSaga),
        fork(watchCallSingleSearchApiSaga),
        fork(watchGetReportPdf),
        fork(watchSubmitInvestmentInterestRequest),
        fork(watchCallMakeDefaultPaymentMethodApiSaga),
        fork(watchCreateAddCardSessionSaga),
        fork(watchCallGetSaveSearchesSaga),
        fork(watchCallSaveCurrentSearchSaga),
        fork(watchCallDeleteSavedSearchSaga),
        fork(watchDownloadResults),
        fork(watchIncreaseDownloadResultLimitRequest),
        fork(watchSaveIndustryMixChangesSaga),
        fork(watchResetIndustryMixChangesSaga),
        fork(watchTrackingNewSignupModal),
        fork(watchTrackingNewLoginModal),
        fork(watchDownloadFinancialsSaga),
        fork(watchSignOutClickSaga),
        fork(watchCompanyHouseEmailNotificationSaga),
        fork(watchSetProductPageScreenSaga),
        fork(watchHandleProductPlanButtonClickSaga),
        fork(watchIndustryProfileSaga),
        fork(watchIndustryProfileSearchSaga),
        fork(watchGetLocationProfileSaga),
        fork(watchGetLocationProfileSearchSaga),
        fork(watchgetChargesListRequestSaga),
        fork(watchResetAllSearchParams),
        fork(watchGetCompanyChargesRequest),
        fork(watchGetCompanyValuationRequest),
        fork(watchGetCompanyValuationUserRequest),
        fork(watchToggleBuyCRModalSaga),
        fork(watchGetOrderHistorySaga),
        fork(watchGetValuationReportsList),
        fork(watchGetValuationReportsPptRequest),
        fork(watchGetBusinessPlanReportsPptRequest),
        fork(watchUpdateValuationReportCreditRequest),
        fork(watchUpdateBusinessPlansCreditRequest),
        fork(watchSaveBusinessPlanDataSaga),
        fork(watchGetIndustryDetailsSaga),
        fork(watchGetBusinessPlanDataSaga),
        fork(watchSaveFinancialDataSaga),
        fork(watchGetFutureFinancialProjectionDataReqSaga),
        fork(watchGetLocationBusinessPlan),
        fork(watchSaveOrUpdateBusinessPlanOnboardSaga),
        fork(watchGetAllOnboardBusinessPlanSaga),
        fork(watchGetBusinessPlanOnboardSaga),
        fork(watchGetActiveBusinessAndSales),
        fork(watchSuggestedNearbyBusinessLocations),
        fork(watchSaveVRWhiteLabelDataRequest),
        fork(watchGetVRWhiteLabelInfo),
        fork(watchResetWhiteLabelInfo),
        fork(watchGetSuggestedCompetitorsSaga),
        fork(watchGetBusinessPlansListSaga),
        fork(watchUploadTempBussPlanFileSaga),
        fork(watchBusinessPlanCompanyInsightsSaga),
        fork(watchGetBusinessPlanPagePreviewSaga),
        fork(watchGetBusinessPlanIndustryInsightsSaga),
        fork(watchGetCompetitorAnalysisData),
        fork(watchGetSelectedCompetitorsAnalysisData),
        fork(watchAddSelectedCompetitor),
        fork(watchRemoveSelectedCompetitor),
        fork(watchDownloadCompetitorAnalysisData),
        fork(watchGetIndustrySicCodesRequest),
        fork(watchGetSicCodeBasedCompanyListRequest),
        fork(watchGetAllKeyPeopleSaga),
        fork(watchRegenerateCpPdfSaga),
        fork(watchRegeneratePdfSaga),
        fork(watchGetBusinessDescriptionOpenAiSaga),
        fork(watchCancelUserFreeTrialSubscriptionSaga),
        fork(watchGenerateCompanyReport),
        fork(watchGetCompanyReportUrl),
        fork(watchRemoveFormData),
        fork(watchUpdateBusinessPlansForCreditsSaga),
        fork(watchSaveAiCurrencyForUserSaga),
        fork(watchGetCountryListRequest)
    ])
}

