import React from "react";
import MediaQuery from "react-responsive";
import HomePageCardComponent from "../HomePageCard/HomePageCardComponent";
import "./howToValueBusiness.css";

export const HowToValueBusiness = (props) => {
    const {
        heading = "",
        subHeading = "",
        cardDataList = [],
        componentName = "",
        headingTag = "h1"
    } = props;
    const HeadingTag = headingTag

    return <div className="how-value-business-container">
        <HeadingTag className="how-value-business-heading m-0">{heading}</HeadingTag>
        <MediaQuery maxWidth={991.98}>
            <p className="m-0 pom-font-18-18-16">{subHeading}</p>
        </MediaQuery>
        <div className="how-value-business-cardList">
            <HomePageCardComponent
                cardDataList={cardDataList}
                withOutLazyLoading={false}
            />
        </div>
    </div>
}