module.exports = {
    "valuationReportPageType": {
        "whatsIncludedCard": {
            "mobile": {
                data: [{
                    "icon": "circle_pencil.svg",
                    "width": "30",
                    "height": "30",
                    "cardText": "Edit your basic financial data with the valuation calculator."
                }, {
                    "icon": "customize_icon.png",
                    "width": "15",
                    "height": "15",
                    "cardText": "Customise the industry for a more accurate valuation."
                }, {
                    "icon": "generate_report_icon.svg",
                    "width": "15",
                    "height": "15",
                    "cardText": "Generate a bespoke report with six valuation methods, explained.",
                }, {
                    "icon": "yellow_timer_icon.svg",
                    "width": "18",
                    "height": "18",
                    "cardText": "Editable for 30 days."

                }],
                hideLastElement: false
            },
            "getDataForOtherDevice": (deviceOrTablet) => {
                return {
                    data: [{
                        icon: deviceOrTablet ? "edit_calculator_mobile.png" : "edit_calculator.svg",
                        width: "40",
                        height: "40",
                        cardText: "Edit your basic financial data with the valuation calculator."
                    }, {
                        icon: deviceOrTablet ? "customize_icon_mobile.png" : "customize_icon.png",
                        width: "40",
                        height: "40",
                        cardText: "Customise the industry for a more accurate valuation."
                    }, {
                        icon: deviceOrTablet ? "generate_report_icon_mobile.png" : "generate_report_icon.svg",
                        width: "40",
                        height: "40",
                        cardText: deviceOrTablet ? 'Generate a bespoke report with six valuation methods. Editable for 30 days.' : "Generate a bespoke report with six valuation methods, explained."
                    }, {
                        icon: deviceOrTablet ? "generate_report_icon_mobile.png" : "timer.svg",
                        width: "40",
                        height: "40",
                        cardText: "Editable for 30 days."

                    }],
                    hideLastElement: deviceOrTablet ? true : false
                }
            },
        },
        isolatedSearchBarDropdownItemTag: ""
    },
    "creditReportPageType": {
        "whatsIncludedCard": {
            "mobile": {
                data: [{
                    "icon": "circle_pencil.svg",
                    "width": "30",
                    "height": "30",
                    "cardText": "Detailed company & directors information, credit scores, mortgages, historic financial data & much more..."
                }],
                hideLastElement: false
            },
            "getDataForOtherDevice": (deviceOrTablet) => {
                return {
                    data: [{
                        icon: deviceOrTablet ? "edit_calculator_mobile.png" : "edit_calculator.svg",
                        width: "40",
                        height: "40",
                        cardText: "Detailed company & directors information, credit scores, mortgages, historic financial data & much more..."
                    }],
                    hideLastElement: false
                }
            },
            isolatedSearchBarDropdownItemTag: "p"
        }
    },
    "valuationReportPpcPageType": {
        "whatsIncludedCard": {
            "mobile": {
                data: [{
                    "icon": "circle_pencil.svg",
                    "width": "30",
                    "height": "30",
                    "cardText": "Edit your basic financial data with the valuation calculator."
                }, {
                    "icon": "customize_icon.png",
                    "width": "15",
                    "height": "15",
                    "cardText": "Customise the industry for a more accurate valuation."
                }, {
                    "icon": "generate_report_icon.svg",
                    "width": "15",
                    "height": "15",
                    "cardText": "Generate a bespoke report with six valuation methods, explained.",
                }, {
                    "icon": "yellow_timer_icon.svg",
                    "width": "18",
                    "height": "18",
                    "cardText": "Editable for 30 days."

                }],
                hideLastElement: false
            },
            "getDataForOtherDevice": (deviceOrTablet) => {
                return {
                    data: [{
                        icon: deviceOrTablet ? "edit_calculator_mobile.png" : "edit_calculator.svg",
                        width: "40",
                        height: "40",
                        cardText: "Edit your basic financial data with the valuation calculator."
                    }, {
                        icon: deviceOrTablet ? "customize_icon_mobile.png" : "customize_icon.png",
                        width: "40",
                        height: "40",
                        cardText: "Customise the industry for a more accurate valuation."
                    }, {
                        icon: deviceOrTablet ? "generate_report_icon_mobile.png" : "generate_report_icon.svg",
                        width: "40",
                        height: "40",
                        cardText: deviceOrTablet ? 'Generate a bespoke report with six valuation methods. Editable for 30 days.' : "Generate a bespoke report with six valuation methods, explained."
                    }, {
                        icon: deviceOrTablet ? "generate_report_icon_mobile.png" : "timer.svg",
                        width: "40",
                        height: "40",
                        cardText: "Editable for 30 days."

                    }],
                    hideLastElement: deviceOrTablet ? true : false
                }
            },
        },
        isolatedSearchBarDropdownItemTag: ""
    }
}