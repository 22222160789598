import React from 'react'
import './InfiniteScrollComponent.css'

export default function InfiniteScrollComponent(props) {
    const {scrollContent=[]} =props
  return (
    <div className="horizontal-scroll-container">
   <div className="horizontal-scroll-group">
   {scrollContent?.map((item)=><p className='business-plan-home-ppc-font-18-18-16' key={item}><i className="fas fa-check" aria-hidden="true"></i><span className='ml-2'>{item}</span> </p>)}
    </div>
    <div className="horizontal-scroll-group">
   {scrollContent?.map((item)=><p className='business-plan-home-ppc-font-18-18-16' key={item}><i className="fas fa-check" aria-hidden="true"></i><span className='ml-2'>{item}</span> </p>)}
    </div>
  </div>
  )
}
