import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, SubmissionError, reduxForm } from 'redux-form';
import TextInput from '../common/TextInputForReduxForm';
import './FeatureDetailsWithForm.css';
import SelectInput from '../common/SelectInputForReduxForm';
import { setValuationOnboardDataIntoRedux } from '../../redux/actions/valuationAction';
import { withRouter } from 'react-router';
import { sendActivityTracking } from '../../redux/actions/commonAction';
import { ET_START_VALUATION_BTN_CLICK, ET_VIEW_SAMPLE_REPORT_CLICK } from '../../common/trackingConstants';
import { Link } from 'react-router-dom'

class FeatureDetailsWithForm extends Component {
    submit = (values, dispatch) => {
        const valutionOnboardObj = this.props.valutionOnboardObj
        if (this.validateForm(valutionOnboardObj)) {
            dispatch(setValuationOnboardDataIntoRedux({
                componentId: "select_industry"
            }))
            this.props.dispatchSendActivityTracking({
                eventPage: this.props.eventPage,
                eventType: ET_START_VALUATION_BTN_CLICK
            })
            this.props.history.push('/valuationonboard')
        }

    }
    onChange = (e) => {
        if (e.target.name === "location") {
            const selectedCountryObj = this.props.countryList.find(obj => obj.country?.toLowerCase()?.includes(e.target.value?.toLowerCase()));
            this.props.dispatchSetValuationOnboardDataIntoRedux({ ...this.props.valutionOnboardObj, locationObj: selectedCountryObj, location: selectedCountryObj?.country })
        } else {
            this.props.dispatchSetValuationOnboardDataIntoRedux({ ...this.props.valutionOnboardObj, [e.target.name]: e.target.value?.toString().replace(/,/g, "") })
        }
    }
    validateForm = (values) => {
        const requiredFormData = this.props.formData.filter(obj => obj.isRequired)
        requiredFormData.forEach((data) => {
            if (!values[data.name] || values[data.name] === "") {
                throw new SubmissionError({
                    [data.name]: `Please enter ${data.label}`,
                })
            }
        })
        return true;
    }

    render() {
        const { formData = [], handleSubmit, valutionOnboardObj = {}, countryList } = this.props;
        return (
            <div className='feature-details-with-form-container'>
                <form className='feature-details-with-form-wrapper' onSubmit={handleSubmit(this.submit)}>
                    <div className='feature-details-with-form-content-section'>
                        {formData.map((obj, index) => {
                            return (
                                <div key={`form_input_${index}`} className='feature-details-with-form-input-container'>
                                    <label className='feature-details-with-form-label'>{obj.label}</label>
                                    <Field
                                        cssClass={`feature-details-with-form-input-field ${obj?.cssClass || ""} `}
                                        type={obj.type}
                                        name={obj.name}
                                        placeholder={obj.placeholder}
                                        input={{
                                            name: obj.name,
                                            subText: obj?.subText,
                                            subTextValue: obj?.subText ? valutionOnboardObj.locationObj?.currency : "",
                                            value: obj?.subText ? (valutionOnboardObj[obj.name] ? parseInt(valutionOnboardObj[obj.name] || 0)?.toLocaleString() : "") : valutionOnboardObj[obj.name],
                                            onChange: this.onChange
                                        }}
                                        inputMode={obj?.inputMode}
                                        actionDispatch={obj?.actionDispatch}
                                        component={obj.type === "select" ? SelectInput : TextInput} />
                                </div>
                            )
                        })}
                    </div>
                    <div className='feature-details-with-form-button-section'>
                        <button className='feature-details-with-form-start-valuation-btn' type="submit">Start Valuation</button>
                        <Link onClick={() => this.props.dispatchSendActivityTracking({
                            eventPage: this.props.eventPage,
                            eventType: ET_VIEW_SAMPLE_REPORT_CLICK
                        })} className='feature-details-with-form-view-sample-report-btn' to="/assets/Download/ai_valuation_sample_report.pdf" target="_blank">
                            View Sample Report <img src='/assets/svgs/pom_ai_value_right_arrow.svg' alt='right_arrow' width='10px' height='8px' /></Link>
                    </div>
                </form >
            </div >
        )
    }
}

const ReduxFeatureDetailsWithForm = reduxForm({
    form: 'featureDetailsWithForm'
})(FeatureDetailsWithForm)
const mapStateToProps = (state) => ({
    countryList: state.country.countryList,
    valutionOnboardObj: state.valuation?.valutionOnboardObj,
})

const mapDispatchToProps = (dispatch) => ({
    dispatchSendActivityTracking: (trackingObj) => dispatch(sendActivityTracking(trackingObj)),
    dispatchSetValuationOnboardDataIntoRedux: (onboardObj) => dispatch(setValuationOnboardDataIntoRedux(onboardObj)),
})

const ConnectedReduxFeatureDetailsWithForm = connect(mapStateToProps, mapDispatchToProps)(ReduxFeatureDetailsWithForm)

export default withRouter(ConnectedReduxFeatureDetailsWithForm);