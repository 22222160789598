import React from "react";
import "./ValuationReportPPCPackageSection.css";
import YellowBorderButton from "../../common/Buttons/YellowBorderButton";
import MediaQuery from "react-responsive";

const ValuationReportPPCPackageSection = (props) => {
    const {
        heading = "",
        subHeading = "",
        valuationRpCount = 0,
        onBtnClick = () => { },
        packages = []
    } = props;

    return <div className="valuation-report-ppc-packages-section">
        <div className="valuation-report-packages-info">
            <h2 className="m-0 valuation-report-packages-heading">{heading}</h2>
        </div>
        <div className="valuation-report-packages-main">
            {packages.map((packageObj) => <PPCReportPackageBox
                {...packageObj}
                onBtnClick={onBtnClick}
                key={packageObj.label}
            />)}
        </div>
    </div>
}

const PPCReportPackageBox = (props) => {
    const {
        label = "",
        amount = 0,
        reports = 0,
        isEnterprise = false,
        textNote = "",
        onBtnClick = () => { },
        productId = "",
        eventType = ""
    } = props;

    return <div className={`ppc-report-package-box ${isEnterprise ? "enterprise-ppc-package" : ""}`}>
        <div className="ppc-report-pkg-header">
            <h3 className="mb-0 ppc-report-label">{label}</h3>
            {!isEnterprise ? <h2 className="mb-0 ppc-report-amount-text">{amount}<span className="ppc-report-amount-slash">/</span>
                <span className="pom-font-20 grey-color-text font-weight-light">1 report</span> 
            </h2> : <p className="m-0 pom-font-16 mt-2 enterprise-text-note">{textNote}</p>
}
        </div>
        <div className="ppc-report-package-btn-section">
            <YellowBorderButton pomandae2eKey={productId} handleClick={() => onBtnClick(productId, isEnterprise, eventType)} text={isEnterprise ? "Contact Us" : "START NOW"} />
        </div>
    </div>
}

export default ValuationReportPPCPackageSection;