import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import HomePageFooter from '../HomePage/HomePageFooter/HomePageFooter';
import HomepageFooterV3 from '../HomePage/HomepageFooterV3/HomepageFooterV3';
import './FAQPage.css';
import { sendActivityTracking } from '../../redux/actions/commonAction';
import { connect } from 'react-redux';
import { EP_FAQ, ET_FAQ_ONLOAD } from '../../common/trackingConstants';

class FAQPage extends Component {
  componentDidMount() {
    window.scrollTo({ top: 0 });
    this.props.dispatchSendActivityTracking({
      eventPage: EP_FAQ,
      eventType: ET_FAQ_ONLOAD
    })
  }
  componentWillUnmount() {
    window.scrollTo({ top: 0 });
  }
  render() {
    return (
      <div className="faqs-main-container">
        <Helmet>
          <title>Frequently Asked Questions About Pomanda | Pomanda</title>
          <meta name="Frequently Asked Questions About Pomanda | Pomanda" property="og:title" />
          <meta content="Learn more about Pomanda, what we do and why we do it and get answers to frequently asked questions." name="description" />
        </Helmet>
        <div className="faqs-container">
          <h1><b>FAQ</b></h1>
          <h2><b>What's the point of Pomanda? </b></h2>
          <p>Pomanda is a company information platform that provides the most complete
            data on the UK market. We take data from publicly available data sources,
            including Companies House data, on UK companies and directors, and
            supplement it with our data to help shed some light on one of the largest
            marketplaces in the world. </p>
          <h2><b>Who is Pomanda for?</b></h2>
          <p>Pomanda is for anybody with an interest in the UK market. Whether they are
            looking to check out their customers or suppliers, discover new customers,
            or just being plain nosey, the list goes on as to why people might want to
            find company information. We look to provide as much transparency as
            possible in an otherwise opaque marketplace.</p>
          <h2><b>Your information is incorrect? </b></h2>
          <p>
            Here at Pomanda, we aren't a fan of getting things wrong, but it does happen.
            What Pomanda looks to do is take information from public sources such as
            Companies House and display it in an easy-to-digest format. The problem
            however is that 95% of companies in the UK file only limited information with
            the company register. In this scenario we estimate the missing information to
            give our users a rough idea of how the business is performing, we make no
            promises as to the accuracy of the information as it is intended to give a
            rough idea of size/performance. In the rare scenario that the information
            you can see on the public registry doesn't match our platform, let us know
            and this will be remedied as soon as possible!
          </p>
          <p>
            Our Company web addresses and phone numbers are taken from a third party,
            if these are incorrect get in touch and we will get them updated.</p>
          <h2><b>How do you arrive at your estimates? </b></h2>
          <p>We use established financial methodologies, Companies House data, and
            industry key performance indicators to generate estimates for all UK
            companies, these are only estimates and shouldn't be relied upon, seriously.
            For a more in-depth overview, <Link to="/solutions/estimates">click here</Link>.
            We typically find that if there are no unusual balance sheet movements and
            the company is accurately classified as the correct industry, we can provide
            our users with a good indication of how that business is performing. We make
            no promises on accuracy and warn all our users not to rely on this information.
            Don't like it, file more information. </p>
          <h2><b>Your information is out of date! </b></h2>
          <p>Pomanda's source material is Companies House, our information is therefore as up
            to date as the filings of a business at Companies House, we don't claim to have
            inside information. When new information is filed at Companies House it will be
            updated on Pomanda. We are working on a way to input your figures (that nobody else
            can see) to help model and forecast performance. Keep an eye out for updates to
            Business Valuations, Company Health Checks and Financial Modelling.</p>
          <h2><b>What gives you the right to use my private data? </b></h2>
          <p>The information on Pomanda is derived from publicly available open data which is
            made available under the terms of Crown Copyright from the Companies House registry.
            It appears on Pomanda as we reflect this data. To be an accurate reflection of this
            data we must include all the data that appears on the registry. Whilst we understand
            that it can be frustrating for your information to be displayed in a public manner
            it is nothing that isn't already out there. To maintain our integrity we, therefore,
            do not remove information from our site unless it is removed from the public registry.</p>
          <p>If you are interested in discussing this further, please don't hesitate to get in
            touch with our team at <a href='mailto:theteam@pomanda.com'>
              theteam@pomanda.com</a> and we can discuss your options.</p>
          <h2><b>What about GDPR? I haven't permitted you to use my data</b></h2>
          <p>
            Pomanda is a Company Information platform that facilitates businesses to make
            risk-based financial decisions to enable our users to make better business and
            economic decisions. As such we process the publicly available data under Article
            6 of the GDPR act- Legitimate Interest. This article permits the processing for
            the purposes of the legitimate interests pursued by the controller or by a third
            party. It further states that you can process personal data without given consent
            if you have a genuine and legitimate reason (including commercial benefit), except
            where such interests are overridden by the interests or fundamental rights and
            freedoms of the data subject which require protection of personal data.
          </p>
          <p>
            The legitimate interest that Pomanda operates under is that we are facilitating
            businesses to make risk-based financial decisions to enable our clients to make
            better business and economic decisions. As such we also maintain the legitimate
            interest to make businesses aware of this capability. We will also conduct business
            under the basis of Recitals 47-50 of the GDPR which will be supported by the conduct
            of legitimate interests assessments.
            'The legitimate interests of a controller, including those of a controller to which
            the Personal Data may be disclosed, or of a third party, may provide a legal basis
            for processing, provided that the interests or the fundamental rights and freedoms
            of the data subject are not overriding, taking into consideration the reasonable
            expectations of data subjects based on their relationship with the controller…… The
            processing of personal data for direct marketing purposes may be regarded as carried
            out for a legitimate interest.
            We also protect the subject's rights by, as previously stated, allowing them the
            right to be forgotten (no-index for search engines) and by correcting any erroneous
            data that is presented as factual. Pomanda has also implemented appropriate technical
            and organisational measures to protect data against unauthorized or unlawful
            processing of personal data and accidental loss or destruction of, or damage to,
            personal data. Pomanda has conducted its due diligence into our data sources and
            implemented appropriate controls as to who may access the data and included in our
            Terms of Use who may access this data and its use.
          </p>
          <h2><b>What is the best way to get the most out of Pomanda? </b></h2>
          <p>
            Our users use Pomanda for any number of reasons, from checking out their
            competition, conducting due diligence on suppliers, deciding whether to
            offer a customer credit or looking for new leads; we don't like to dictate
            how our users should use our information. We have, however, had several
            suggestions as to how our features can be utilised.
          </p>
          <ul>
            <li>
              <Link to="/solutions/companyinformation"><b>Company Profile Pages</b></Link>: Dig
              a little deeper into businesses you are thinking of doing business with. If
              the pandemic has taught us anything it's the importance of knowing who you are
              doing business with. Check out the shareholders, directors, and the financial
              stability of that business before getting into bed with them. A number of our
              users also use the company profile pages to monitor the performance of their own
              business. How are they performing compared to their peers? How can they improve
              the value of the business? Even as trivial as digging out their company number.
            </li>
            <li>
              <Link to="/solutions/companyinformation"><b>Director Pages</b></Link>: Interested in
              learning more about a person's company history? You can find all historic and current
              UK directorships as well as links to other people who have companies in common or
              who they share a board with. This can be used for both lead generation and an
              improved level of KYC/risk management.
            </li>
            <li>
              <Link to="/powersearch/company"><b>Power Search</b> </Link>: The Power Search is
              Pomanda's powerful lead generation tool, unlike when you know the name of the
              Company or the person you are looking for you can now filter the entire UK market
              by a set of defined criteria. Our users use this to identify companies similar to
              their existing customers. Identify fast-growing companies in their area or people
              who may be able to help them with their own business. You can filter from everything
              from financials, growth rate, industry, location and much more, the possibilities
              are endless. If you're interested in an example check out this link to Brewers in
              the North East of England generating sales of over £100k here and start Power Searching.
            </li>
            <li>
              <Link to="solutions/industry"><b>Industry Pages</b></Link>: Pomanda aggregates all our
              company and industry data to provide weekly insights into the largest industries in
              the UK. From the fastest growing companies in that sector to the oldest and largest.
              Pomanda gives insight into the state of that industry, is it growing or declining,
              the value of that industry, the breakdown of the industry constituents around the
              UK and much more. This is primarily a research tool and is useful for assessing
              industries you are thinking of dealing with.
            </li>
            <li>
              <Link to="/powersearch/shareholder"><b>Shareholder Search</b></Link>: Interested in finding
              out which UK companies a person has invested in or who the shareholders for a
              specific company are? Try Pomanda's shareholder search.
              For example, <Link to="/powersearch/shareholder?af%3D1&search%3Dshareholder&sf%3DfullName&s
              t%3Dasc&sq%3D&est%3D1&ver%3D1&1%3DAdkins&2%3DAdele">click here</Link> to see Adele's
              UK shareholdings. This function is used to conduct due diligence on the people
              who you're dealing with and the companies that they are involved with.
            </li>
            <li>
              <Link to="/powersearch/charges"><b>Charges Search</b> </Link>: Use Pomanda's
              Power Search to identify companies that have debt with specific lenders or
              dive down into a company's specific charges and see who they have debt with.
            </li>
            <li>
              <Link to="/powersearch/region"><b>Location Pages</b> </Link>: Interested in
              conducting research into specific regions? Check out Pomanda's Location Pages.
              Get a detailed breakdown of the most popular industries in that sector, the
              number of people employed and the fastest, largest and oldest businesses.
              Here is an example for <Link to="/location/14/yorkshireandthehumber">Yorkshire</Link>.
            </li>
          </ul>
          <h2><b>What on earth is a <Link to="/solutions/scorecard"> Company Health Check</Link>? </b></h2>
          <p>
            We have created a benchmarking tool that allows users to compare their business to
            industry averages. Pomanda looks to highlight areas where UK companies are
            outperforming their industry peers or where there might be room for improvement.
            You can check the performance of a business going back up to 10 years against
            their industry. This is still in beta mode, and you can find more information on
            the <Link to="/solutions/scorecard">Company Health Check here</Link>.</p>
          <h2><b>What are company valuations and why do I need them?</b></h2>
          <p>
            Pomanda uses its UK industry data and our team's background in finance to use
            standard valuation methodologies to assign a value to every company in the UK. We
            provide multiple valuations as different industries use different valuation
            methodologies and we wanted to provide as much information as possible. Learn more
            about <Link to="/solutions/companyvaluation">company valuations here</Link>. Our new
            Company Valuation Reports are coming soon and provide a lot more information on how
            we value a business, how you can improve the value of a business and how others compare.
          </p>
          <p>
            Business valuations can be used for many reasons, whether you're looking for validation
            of your hard work within a business, looking to sell your business, estate planning
            or even during a breakup. Understanding the value of your assets can be a great benefit.
          </p>
          <h2><b>What are <Link to="/solutions/companycreditcheck"><b>Company Credit Checks
          </b></Link> and will checking them count as a mark against my credit rating?</b></h2>
          <p>
            Pomanda offers its users the ability to run a credit check on companies, unlike Personal
            Credit Checks, which will have no impact on the credit score of that business. They
            are an attempt to indicate the likelihood of that business failing in the short term.
            The number is not a percentage, merely a scale. Get a free monthly company credit check with
            your <Link to="/product?serviceReq=DEFAULT&url=/company/11837500/black-and-white-
            projects-ltd&href=https://pomanda.com/product">Pomanda Plus subscription</Link> or
            buy a one-off report from the Company Profile pages.
          </p>
          <h2><b>Why are Industry Mixes so important?</b></h2>
          <p>
            A lot of Pomanda's proprietary information is based on the industry
            classification of a company at Companies House, these are known as SIC codes
            (Standard Industrial Classification of economic activities). We use these
            classifications to identify the industry within which these companies trade and
            identify their peers. Unfortunately, SIC codes are from 2007 and the world has
            moved on. Therefore we give our users the ability to blend industries, assign new
            industries and model industry changes for any company. These changes are only
            visible on a user's instance (nobody else can see the changes you have made) and
            will, when changed, rerun our algorithms and calculations allowing you to improve
            the accuracy of our data, model changes or just mess around.
          </p>
          <h2><b>Talk like a normal person, I don't understand your jargon</b></h2>
          <p>
            As we mentioned a lot of our team are from the finance industry, we're
            sorry, we use jargon without realising how pretentious we sound. We've
            created <Link to="/glossary">this glossary</Link> which we hope will help
            shed some light on some of the terms that might not be commonplace in the real world.
          </p>
          <h2><b>Why are you so cheap, what's the catch?</b></h2>
          <p>
            Pomanda created its data platform with the goal that the information within
            it should be available to anybody that wishes to access it. This is why our basic
            information is free to access and our unlimited access is
            only <Link to="/product?serviceReq=DEFAULT&url=/solutions&href=https://pomanda.com/product">
              £24.99 per month or as little as £4.99 for one day access</Link>. We like to think
            we provide extreme value for money, which within the finance/data industry is
            often hard to come by.
          </p>
          <p>
            If you have any other questions or have feedback of any kind please don't hesitate
            to get in touch with us at <a href='mailto:theteam@pomanda.com'>
              theteam@pomanda.com</a>, it may be that your question is a common one
            and we will add it to this list.
          </p>
        </div>
          <div className='homepage-footer-section-wrapper'>
            <div className='homepage-footer-section'>
              <HomepageFooterV3 />
            </div>
          </div>
      </div>
    )
  }
}
const mapDispatchToProps = (dispatch) => ({
  dispatchSendActivityTracking: (trackingObj) => dispatch(sendActivityTracking(trackingObj))
});

export default connect(null, mapDispatchToProps)(FAQPage)