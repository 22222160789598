import React, { useEffect, useState } from 'react'
import { Input, Label } from 'reactstrap'
import './BusinessInformation.css'
import { useDispatch, useSelector } from 'react-redux'
import { setValuationOnboardDataIntoRedux } from '../../../redux/actions/valuationAction'
import { getCountryListRequest } from '../../../redux/actions/countryAction'
import { ET_START_VALUATION_BTN_CLICK } from '../../../common/trackingConstants'

const BusinessInformation = (props) => {
    const dispatch = useDispatch()
    const reduxValuationObj = useSelector(state => state.valuation?.valutionOnboardObj)
    const [searchText, setSearchText] = useState(reduxValuationObj.location || "")
    const [dropdown, setDropdown] = useState(false)
    const countryList = useSelector(state => state.country.countryList);
    const [filterCountryList, setFilterCountryList] = useState(countryList)


    const handleChange = (event) => {
        dispatch(setValuationOnboardDataIntoRedux({ ...reduxValuationObj, [event.target.id]: event.target.value?.toString().replace(/,/g, "") }));
    }

    const onDropdownClick = () => {
        setDropdown(!dropdown)
    }

    const onBtnClick = () => {
        props.handleValuationOnboardTracking({ eventType: ET_START_VALUATION_BTN_CLICK })
        dispatch(setValuationOnboardDataIntoRedux({
            componentId: "select_industry"
        }))
    }

    useEffect(() => {
        dispatch(getCountryListRequest())
        setFilterCountryList(countryList)
    }, [countryList?.length])

    const activeStatus = (reduxValuationObj.companyName && reduxValuationObj.location && reduxValuationObj.revenueSales) ? true : false
    useEffect(() => {
        dispatch(setValuationOnboardDataIntoRedux({ componentActive: activeStatus }))
    }, [activeStatus])

    const onCountrySelect = (selectedCountryObj) => {
        setSearchText(selectedCountryObj.country);
        dispatch(setValuationOnboardDataIntoRedux({ ...reduxValuationObj, locationObj: selectedCountryObj, location: selectedCountryObj.country }))
        setDropdown(!dropdown)
    }

    const onCountryInputSearch = (e) => {
        setSearchText(e.target.value);
        setFilterCountryList(countryList.filter(obj => obj.country?.toLowerCase().includes(e.target.value.toLowerCase())))
        setDropdown(true)
    }
    return (
        <div id="business_information">
            <Label className="val-bus-info-heading">Enter Business Name </Label>
            <Input type="text" className={`val-bus-info-input-field`}
                id="companyName"
                placeholder="Business Name"
                onChange={handleChange}
                value={reduxValuationObj.companyName}
                autoComplete="off" />
            <Label className="val-bus-info-heading">Location</Label>
            <div className={`val-bus-info-input-field-wrapper`}>
                <Input type="text"
                    id="location"
                    className="border-0 p-0"
                    placeholder="Company Location"
                    onClick={() => onDropdownClick()}
                    onChange={onCountryInputSearch}
                    value={searchText}
                    autoComplete="off" />
                {dropdown ? <i className='fas fa-chevron-up yellow-color-text' onClick={() => onDropdownClick()} />
                    : <i className='fas fa-chevron-down yellow-color-text' onClick={() => onDropdownClick()} />}
                {dropdown && <div className='location-dropdown-list-wrapper'>
                    {filterCountryList?.length ? filterCountryList.map(obj => <div className='location-dropdown-div-wrapper'
                        onClick={() => onCountrySelect(obj)}>
                        <p className='location-dropdown-title'>{obj.country}</p>
                        <i className='fas fa-chevron-right ml-auto' />
                    </div>) : <p className='location-dropdown-title grey-color-text ml-5'>No Results Found</p>
                    }
                </div>}
            </div>
            <Label className="val-bus-info-heading">Revenue / Sales </Label>
            <div className={`val-bus-info-input-field`}>
                <Input type="text"
                    id="revenueSales"
                    placeholder="100,000"
                    className="border-0 p-0"
                    onChange={handleChange}
                    value={reduxValuationObj.revenueSales ? parseInt(reduxValuationObj.revenueSales || 0)?.toLocaleString() : ""}
                    autoComplete="off" />
                <p className='ml-auto mb-0'>{reduxValuationObj?.locationObj?.currency}</p>
            </div>
            <div className='valuation-right-side-btn-wrapper'>
                <button className={`valuation-right-side-component-btn ${activeStatus ? "footer-active-btn" : ""}`}
                    disabled={!activeStatus} onClick={() => onBtnClick()}>START VALUATION</button>
            </div>
        </div>

    )
}

export default BusinessInformation;