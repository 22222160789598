import React, { useState } from "react";
import MediaQuery from "react-responsive";
import { Tooltip } from "reactstrap";
import { convertToUKCurrency } from "../../common/CurrencyConverter";
import "./BuyValuationReportForCompany.css"
import DynamicSvg from "../../DynamicSvg";


export const BuyValuationReportForCompany = (props) => {
    const [isToolTipOpen, setIsToolTipOpen] = useState(false);
    const [isToolTipOpenForIndustry, setIsToolTipOpenForIndustry] = useState(false);
    const {
        company,
        btnComp = {},
        imageUrl = {},
        toolTipIndex = "",
        pageType = "",
        redirectToCompanyProfile = ""
    } = props
    const {
        companyName = "",
        address = "",
        industryName = "",
        companyId = "",
        netAssets = {},
        multipleIndustryMixTO = {},
        assets = "",
        companyStatus = ""
    } = company;
    const checkNullAndUdefiend = (obj) => (obj.value === undefined || obj.value === null) ? "-" :
        <span className={`search-companies-company-info ${obj.type === "ESTIMATE" ?
            "pomanda-estimate" : ""}`}>{`${convertToUKCurrency(obj.value)}`}</span>
    const  indutryGrowthValue = multipleIndustryMixTO.value 
    return <div className="search-companies-wrapper">
        <div className="search-companies-container">
            <div className="search-companies-company-details-wrapper">
                <MediaQuery minWidth={575.75}>
                        {imageUrl?.companyIcon ? <DynamicSvg src={imageUrl.companyIcon} className="lazyload"
                            alt={imageUrl.companyAlt}
                            width={imageUrl.companyIconWidth}
                            height={imageUrl.companyIconHeight}/> : null}
                </MediaQuery>
                <div className="search-companies-company-details">
                    <h3 onClick={redirectToCompanyProfile} className="search-companies-company-header">{companyName}</h3>
                    {address && <Tooltip placement="top"
                        isOpen={isToolTipOpen}
                        target={`company_intfo_tool_tip_${toolTipIndex}`}
                        toggle={() => setIsToolTipOpen(!isToolTipOpen)}
                    > {address} </Tooltip>}
                    {address ?
                        <p className="search-companies-company-info with-ellipsis" id={`company_intfo_tool_tip_${toolTipIndex}`}>{address}</p> : ""}
                    <div className="search-companies-details-divider"></div>
                    <MediaQuery minWidth={576}>
                        <div className="company-account-details">
                                {companyId &&
                                    <p className="search-companies-company-subheading search-companies-id-info">Company ID:
                                        <span className="search-companies-company-info">{companyId}</span></p>}
                                <div className="company-accounts-divider"></div>
                                {companyStatus ? <div className="d-flex align-items-center search-companies-company-subheading">
                                    <div className={`list-card-status ${companyStatus === 'Live' ? "green-indicator" : "red-indicator"}`}></div>
                                    <p className="mb-0">{companyStatus}</p>
                                </div> : null}
                                {assets ? <p className="search-companies-company-subheading">Assets: {checkNullAndUdefiend(assets)}</p> : null}
                                {pageType ?  <p className="search-companies-company-subheading">Net Assets: {checkNullAndUdefiend(netAssets)}</p>
                                :
                                <p className="search-companies-company-subheading with-ellipsis search-companies-industry-info">
                                    Industry: <span className="search-companies-company-info"
                                        id={`company_industry_tool_tip_${toolTipIndex}`}>{industryName}</span>
                                </p> }
                        </div>
                    </MediaQuery>
                    <MediaQuery maxWidth={575.98}>
                        <div className="">
                            <div className="company-details-info-box">
                                <p className="search-companies-company-subheading ">Company ID: <span className="search-companies-company-info">
                                    {companyId}</span></p>
                                {companyStatus ? <>
                                    <div className="company-accounts-divider"></div>
                                    <div className="d-flex align-items-center search-companies-company-subheading">
                                        <div className={`list-card-status ${companyStatus === 'Live' ? "green-indicator" : "red-indicator"}`}></div>
                                        <p className="mb-0">{companyStatus}</p>
                                    </div></> : null}
                            </div>
                            <div className="search-companies-details-divider"></div>
                            <div className="company-details-info-box">
                                {assets ? <><p className="search-companies-company-subheading">Assets: {checkNullAndUdefiend(assets)}</p>
                                    <div className="company-accounts-divider"></div>
                                </> : null}
                               {pageType ?  <p className="search-companies-company-subheading">Net Assets: {checkNullAndUdefiend(netAssets)}
                                </p> :
                                 <p className="search-companies-company-subheading with-ellipsis">
                        Industry: <span className="search-companies-company-info "
                            id={`company_industry_tool_tip_${toolTipIndex}`}>{industryName}</span>
                    </p>}
                            </div>
                        </div>
                    </MediaQuery>
                    <div className="search-companies-details-divider"></div>
                    {industryName && <Tooltip placement="top"
                        isOpen={isToolTipOpenForIndustry}
                        target={`company_industry_tool_tip_${toolTipIndex}`}
                        toggle={() => setIsToolTipOpenForIndustry(!isToolTipOpenForIndustry)}
                    > {industryName} </Tooltip>}
                       {!pageType ? ( indutryGrowthValue ? <p className="search-companies-company-info">
                      In this sector Industry Multiples have <span className={indutryGrowthValue > 0 ? 'green-text-color' : 'red-text-color'}>{indutryGrowthValue > 0 ? "increased" : "decreased"} by {Math.floor(indutryGrowthValue * 100)}%</span> in the last financial year.<br/>
                      <b>See what this means for {companyName} valuation</b>
                    </p> :
                    <p className="search-companies-company-info">
                        Edit financials and industries in our <b>Business Valuation Calculator</b> and see the <b>{companyName} valuation</b></p>)
                     : <p className="search-companies-company-subheading with-ellipsis">
                        Industry: <span className="search-companies-company-info"
                            id={`company_industry_tool_tip_${toolTipIndex}`}>{industryName}</span>
                    </p>}
                    {<MediaQuery maxWidth={991.98}>
                        <div className="search-companies-details-divider"></div>
                    </MediaQuery>}
                </div>
            </div>
            {btnComp.btnText && <div className="buy-valuation-report-for-company"
                onClick={() => btnComp.onClick(companyId, companyName)} pomandae2e={btnComp.btnText}>
                {btnComp.btnText}
            </div>}
        </div>
    </div>
}