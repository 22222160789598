import React, { memo } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import "./CompanyValConfirmationModal.css";

export const CompanyValConfirmationModal = memo(function CompanyValConfirmationModal(props) {
    const {
        remainingCredits = 0,
        yesOnclick = () => {},
        noOnclick = () => {}
    } = props;

    return (
        <Modal
            modalClassName="company-val-confirmation-modal"
            backdropClassName="company-val-confirmation-backdrop"
            isOpen={true}
            fade={false}
            backdrop="static">

            <ModalBody>
                <p pomandae2e="creditAvailableModel" className='company-val-confirmation-text'>Are you sure you want to use one of your</p>
                <p className="font-weight-bold company-val-confirmation-text">{remainingCredits} credits</p>
                <p className='company-val-confirmation-text'>to buy this Valuation Report?</p>
                <div className="company-val-confirm-btn-section">
                    <button type="button" pomandae2e="yesContinue" className="company-val-confirm-btn company-val-confirm-yellow-btn cursor-pointer" onClick={yesOnclick}>Yes, Continue</button>
                    <button type="button" pomandae2e="noThanks" className="company-val-confirm-btn company-val-confirm-white-btn cursor-pointer" onClick={noOnclick}>No, thanks</button>
                </div>
            </ModalBody>
        </Modal>
    )

})
