import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import TextInput from "../../../common/TextInputForReduxForm";
import TextAreaInput from "../../../common/TextareaInputForReduxForm";
import EmailInput from '../../../common/EmailInputForReduxForm';
import { emailRegex } from '../../../common/SubmissionError';
import { submitInvestmentInterestRequest } from '../../../../redux/actions/companyAction';
import './SubmitInterestForm.css';
class SubmitInterestForm extends PureComponent {
    submitForm = (values, dispatch) => {
        const {
            companyId = "",
            loggedInUserEmail = "",
            subject = "",
            trackingInfo = {},
            mailRecipient = "team",
            countryCodes = []
        } = this.props;
        const defaultCountryCodes = countryCodes?.length ? { countryCode: "GB", callingCode: "44" } : {}
        if (this.validateForm(values)) {
            trackingInfo.attribute2 = values.firstName
            trackingInfo.attribute3 = values.lastName
            trackingInfo.attribute4 = values.telNumber
            trackingInfo.attribute5 = values.email
            trackingInfo.attribute6 = values.interestDescription
            trackingInfo.attribute7 = countryCodes?.length ? this.state?.countryCode || "GB" : ""
            trackingInfo.attribute8 = countryCodes?.length ? this.state?.callingCode || "44" : ""
            dispatch(submitInvestmentInterestRequest({ ...values, ...defaultCountryCodes, ...this.state, companyId, loggedInUserEmail, subject, mailRecipient }, trackingInfo));
        }
    }
    validateForm = (values) => {
        if (!values.firstName || values.firstName === "") {
            throw new SubmissionError({
                firstName: 'Please enter first name',
            })
        }
        if (!values.lastName || values.lastName === "") {
            throw new SubmissionError({
                lastName: 'Please enter last name',
            })
        }
        if (!values.telNumber || values.telNumber === "" || !/^\d*$/.test(values.telNumber)) {
            throw new SubmissionError({
                telNumber: 'Please enter valid telephone number',
            })
        }
        if (!values.email || values.email === "" || !emailRegex.test(values.email)) {
            throw new SubmissionError({
                email: 'Please enter valid email',
            })
        }
        if (!values.interestDescription || values.interestDescription === "") {
            throw new SubmissionError({
                interestDescription: 'Please enter your message.',
            })
        }

        return true;
    }
    selectOptionClick = (e) => {
        const obj = this.props.countryCodes?.find(obj => obj.countryCode === e.target.value)
        this.setState({ ...obj })
    }
    render() {
        const {
            handleSubmit,
            errorMessage = "",
            placeholder = {
                interestDescription: "300 Character max"
            },
            labels = {
                telephoneNumber: "Telephone Number",
                email: "Email"
            },
            pristine,
            submitting,
            interestDescriptionLabel = "Describe your interest",
            cssClass = "",
            inputBoxWrapper,
            countryCodes = [],
            inputBoxDropDownWrapper = ""
        } = this.props
        return (
            <form id="submit_interest_form" onSubmit={handleSubmit(this.submitForm)} className={cssClass} >
                <div className="row">
                    <div className="col-12">
                        {errorMessage && errorMessage !== "" &&
                            <div className="alert alert-danger" role="alert">
                                {errorMessage}
                            </div>}
                    </div>
                </div>
                <div className={inputBoxWrapper}>
                    <div className="form-group">
                        <label className="interest-form-label" htmlFor="firstName">First Name</label>
                        <Field
                            component={TextInput}
                            name="firstName"
                            pomandae2e="FirstName"
                            placeholder={placeholder && placeholder.firstName}
                            props={{ disabled: false }}
                        />
                    </div>
                    <div className="form-group">
                        <label className="interest-form-label" htmlFor="lastName">Last Name</label>
                        <Field
                            component={TextInput}
                            name="lastName"
                            placeholder={placeholder && placeholder.lastName}
                            pomandae2e="LastName"
                            props={{ disabled: false }}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label className="interest-form-label" htmlFor="email">{labels.email}</label>
                    <Field
                        component={EmailInput}
                        name="email"
                        placeholder={placeholder && placeholder.email}
                        pomandae2e="Email"
                        props={{ disabled: false }}
                        showPlaceholder={true}

                    />
                </div>
                <div className="form-group">
                    <label className="interest-form-label" htmlFor="telNumber">{labels.telephoneNumber}</label>
                        <Field
                            component={TextInput}
                            name="telNumber"
                            placeholder={placeholder && placeholder.telephoneNumber}
                            pomandae2e="TelephoneNumber"
                            props={{ disabled: false }}
                        />
                </div>                
                <div className="form-group">
                    <label className="interest-form-label" htmlFor="interestDescription">{interestDescriptionLabel}</label>
                    <Field
                        component={TextAreaInput}
                        name="interestDescription"
                        pomandae2e="Describe your interest"
                        placeholder={placeholder?.interestDescription}
                        maxLength="300" />
                </div>
                <button
                    className={(pristine || submitting) ? "yellow-bg-btn yellow-bg-btn-disabled" : "yellow-bg-btn"}
                    pomandae2e="SubmitInterst"
                    type="submit"
                    disabled={pristine || submitting}>submit</button>
            </form>
        );
    }
}
const ReduxSubmitInterestForm = reduxForm({
    form: 'submitInterestForm',
    enableReinitialize: true
})(SubmitInterestForm)

const ConnectedSubmitInterestForm = connect(
    (state, props) => {
        const {
            company = {}
        } = state;
        const {
            submitInterestErrorMessage = ""
        } = company;
        return {
            errorMessage: submitInterestErrorMessage,
            initialValues: {
                interestDescription: props.interestDescription,
            }
        }
    }
)(ReduxSubmitInterestForm)
export default ConnectedSubmitInterestForm;