import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './HomepageFooterV3.css';
import { withRouter } from 'react-router';
import { toggleLoginModal } from '../../../redux/actions/loginSignupAction';
import localStorageApi from '../../../api/localStorageApi';
import {
    EP_ACCOUNT, ET_ACCOUNT_ONLOAD, FOOTER_ACCOUNT_BUTTON,
    ET_POWER_SEARCH_COMPANY_ONLOAD, HOMEPAGE_FOOTER_SEARCH_BUTTON,
    FOOTER_POPULAR_COMPANIES_BUTTON, FOOTER_TERMS_LINK,
    ET_POPULAR_COMPANIES_CLICK, EP_FOOTER, ET_TERMS_OF_USE_CLICK,
    ET_PRIVACY_POLICY_CLICK, FOOTER_PRIVACY_POLICY_LINK, LOGIN_EVENT_PAGE,
    LOGIN_TOGGLE_ON_EVENT_TYPE, TOGGLE_SELF_CLICK, ET_POWER_SEARCH_DIRECTOR_ONLOAD,
    ET_POWER_SEARCH_INDUSTRY_ONLOAD, ET_POWER_SEARCH_SHAREHOLDER_ONLOAD,
    FOOTER_CONTACT_US_LINK, ET_INSTANT_CREDIT_REPORT_CLICK,
    FOOTER_INSTANT_CREDIT_REPORT_BUTTON, ET_INDUSTRY_INSIGHTS_CLICK,
    FOOTER_INDUSTRY_INSIGHTS_BUTTON, ET_COMPANY_HEALTHCHECK_CLICK,
    FOOTER_COMPANY_HEALTHCHECK_BUTTON, ET_BLOG_CLICK, FOOTER_BLOG_BUTTON,
    ET_TWITTER_CLICK, FOOTER_TWITTER_BUTTON, ET_LINKEDIN_CLICK, FOOTER_LINKEDIN_BUTTON,
    FOOTER_FAIR_USAGE_POLICY_BUTTON, ET_FAIR_USAGE_POLICY_CLICK,
    FOOTER_ACCEPTABLE_USE_BUTTON, ET_ACCEPTABLE_USE_CLICK, FOOTER_COOKIE_POLICY_BUTTON,
    ET_COOKIE_POLICY_CLICK, ET_ABOUT_US_CLICK, FOOTER_ABOUT_US_BUTTON, FOOTER_FAQ_BUTTON,
    ET_FAQ_CLICK, ET_BUSINESS_VALUATION_CLICK, FOOTER_BUSINESS_VALUATION_BUTTON, FOOTER_CONTACT_US_BUTTON,
    ET_BUSINESS_PLANS_CLICK,
    FOOTER_BUSINESS_PLANS_BUTTON,
} from '../../../common/trackingConstants';
import { sendActivityTracking } from '../../../redux/actions/commonAction';
import { setActivityTrackingData } from '../../../redux/actions/searchAction';
import analyticsApi from '../../../api/analyticsApi';
import { GA_PS_BTN_TAG, PROSPER_USER_TYPE, isBrowser } from "../../../common/config";
import { TOGGLE_LOGIN_MODAL_ON_SUCCESS } from '../../../common/loginSignupSuccessConstants';
import { getOnloadEventType } from '../../common/PowerSearchFunctions';
import { toggleSubmitInvestmentInterestModal } from '../../../redux/actions/companyAction';

class HomepageFooterV3 extends Component {

    getActivityTracking = (actvityObj) => {
        this.props.dispatchSendActivityTracking(actvityObj);
    }

    goToPowerSearchPage = (searchType, isRestrictRedirect) => {
        this.props.setActivityTrackingData({ obj: { "eventCreated": HOMEPAGE_FOOTER_SEARCH_BUTTON, "eventType": getOnloadEventType(searchType) }, type: "powerSearch" })
        if (!isRestrictRedirect) {
            switch (searchType) {
                case "company":
                    this.props.history.push("/powersearch/company");
                    break;
                case "director":
                    this.props.history.push("/powersearch/director");
                    break;
                case "industry":
                    this.props.history.push("/powersearch/industry");
                    break;
                case "charges":
                    this.props.history.push("/powersearch/charges");
                    break;
                case "shareholder":
                    this.props.history.push("/powersearch/shareholder");
                    break;
                case "region":
                    this.props.history.push("/powersearch/region");
                    break;
                default:
                    return {}
            }
        }
        analyticsApi.gtag_report_conversion(GA_PS_BTN_TAG)
    }

    handleContactUsClick = () => {
        this.props.dispatchSubmitInterestModal({
            toggleStatus: true,
            subject: "Contact Us",
            subHeading: "",
            heading: "Contact Us",
            userMessage: "What can we help with?",
            descriptionLabel: "Get in touch",
            mailRecipient: "CONTACT_US_EMAIL_ADDRESS",
            eventPage: EP_FOOTER,
            eventType: FOOTER_CONTACT_US_LINK
        });
    }

    getFooterContentsArr = () => {
        return [
            {
                label: "Our Products",
                fields: [
                    {
                        text: "Business Valuations",
                        renderUrl: "/companyvaluation",
                        onClickFun: () => {
                            this.getActivityTracking({
                                eventPage: EP_FOOTER,
                                eventType: ET_BUSINESS_VALUATION_CLICK,
                                attribute1: FOOTER_BUSINESS_VALUATION_BUTTON
                            })
                        }
                    },
                    {
                        text: "Business Plans",
                        renderUrl: "/businessplan",
                        onClickFun: () => {
                            this.getActivityTracking({
                                eventPage: EP_FOOTER,
                                eventType: ET_BUSINESS_PLANS_CLICK,
                                attribute1: FOOTER_BUSINESS_PLANS_BUTTON
                            })
                        }
                    },
                    
                ]
            },
            {
                label: "Conditions",
                fields: [
                    {
                        text: "Terms of Use",
                        renderUrl: "/assets/Download/Terms_Of_Use.pdf",
                        redirectWindow: true,
                        onClickFun: () => {
                            this.getActivityTracking({
                                eventPage: EP_FOOTER,
                                eventType: ET_TERMS_OF_USE_CLICK,
                                attribute1: FOOTER_TERMS_LINK,
                            })
                        }
                    },
                    {
                        text: "Privacy Policy",
                        renderUrl: "/assets/Download/Pomanda_Privacy_Notice.pdf",
                        redirectWindow: true,
                        onClickFun: () => {
                            this.getActivityTracking({
                                eventPage: EP_FOOTER,
                                eventType: ET_PRIVACY_POLICY_CLICK,
                                attribute1: FOOTER_PRIVACY_POLICY_LINK,
                            })
                        }
                    },
                    {
                        text: "Cookie Policy",
                        renderUrl: "/assets/Download/Cookies_May20.pdf",
                        redirectWindow: true,
                        onClickFun: () => {
                            this.getActivityTracking({
                                eventPage: EP_FOOTER,
                                eventType: ET_COOKIE_POLICY_CLICK,
                                attribute1: FOOTER_COOKIE_POLICY_BUTTON
                            })
                        }
                    },
                    {
                        text: "Acceptable Use",
                        renderUrl: "/assets/Download/Website_acceptable_use.pdf",
                        redirectWindow: true,
                        onClickFun: () => {
                            this.getActivityTracking({
                                eventPage: EP_FOOTER,
                                eventType: ET_ACCEPTABLE_USE_CLICK,
                                attribute1: FOOTER_ACCEPTABLE_USE_BUTTON
                            })
                        }
                    },
                    {
                        text: "Fair Usage Policy",
                        renderUrl: "/assets/Download/Fair_Usage_Policy.pdf",
                        redirectWindow: true,
                        onClickFun: () => {
                            this.getActivityTracking({
                                eventPage: EP_FOOTER,
                                eventType: ET_FAIR_USAGE_POLICY_CLICK,
                                attribute1: FOOTER_FAIR_USAGE_POLICY_BUTTON
                            })
                        }
                    }
                ]
            },
            {
                label: "Learn More",
                fields: [
                    {
                        text: "About Us",
                        renderUrl: "/about",
                        isProsperRectrictedUrl:true,
                        onClickFun: () => {
                            this.getActivityTracking({
                                eventPage: EP_FOOTER,
                                eventType: ET_ABOUT_US_CLICK,
                                attribute1: FOOTER_ABOUT_US_BUTTON
                            })
                        }
                    },
                    {
                        text: "Contact Us",
                        renderUrl: "/contact",
                        onClickFun: () => {
                            this.getActivityTracking({
                                eventPage: EP_FOOTER,
                                eventType: ET_ABOUT_US_CLICK,
                                attribute1: FOOTER_CONTACT_US_BUTTON
                            })
                        }
                    }
                ]
            },
            {
                label: "social",
                fields: [
                    {
                        img: "/assets/svgs/twitter_logo.svg",
                        imgWebp: "/assets/webpImages/new_twitter_logo.webp",
                        imgAlt: "twitter",
                        renderUrl: "https://twitter.com/PomandaCo",
                        redirectWindow: true,
                        isAbsoluteUrl: true,
                        onClickFun: () => {
                            this.getActivityTracking({
                                eventPage: EP_FOOTER,
                                eventType: ET_TWITTER_CLICK,
                                attribute1: FOOTER_TWITTER_BUTTON
                            })
                        }
                    },
                    {
                        img: "/assets/svgs/linkedIn_logo.svg",
                        imgWebp: "/assets/webpImages/new_linkedIn_logo.webp",
                        imgAlt: "linkedin",
                        isAbsoluteUrl: true,
                        renderUrl: "https://linkedin.com/company/pomanda",
                        redirectWindow: true,
                        onClickFun: () => {
                            this.getActivityTracking({
                                eventPage: EP_FOOTER,
                                eventType: ET_LINKEDIN_CLICK,
                                attribute1: FOOTER_LINKEDIN_BUTTON
                            })
                        }
                    }
                ]
            }
        ]
    }

    render() {
        let userTrackerObj = isBrowser && localStorageApi.getUserData()
        let showAccMenu = false;
        if (userTrackerObj && userTrackerObj.accessToken && userTrackerObj.accessToken !== "") {
            showAccMenu = true;
        }
        const { whiteLabelConfig = {},
        userInfo = {}
        } = this.props;
        const iswhiteLabelConfig = whiteLabelConfig?.header ? true : false
        const isProsperTrue = userInfo?.userType === PROSPER_USER_TYPE
        const footerContentsArr = this.getFooterContentsArr();

        return (
            <div className='footer-cmp-section'>
                <div className="footer-cmp-logo-section">
                    <>
                        {whiteLabelConfig?.footer ?
                            <picture>
                                <source type="image/webp" data-srcset={whiteLabelConfig?.footer.sourceset} />
                                <img data-src={whiteLabelConfig?.footer.image} className="lazyload" alt="pomanda" width="160" height="35" />
                            </picture> : <picture>
                                {/* <source type="image/webp" data-srcset="/assets/webpImages/blacklogo.webp" /> */}
                                <img data-src="/assets/svgs/pom_ai_logo_gray.svg" className="lazyload" alt="pomanda" width="160" height="35" />
                            </picture>}
                    </>
                </div>
                <div className='footer-cmp-content-section'>
                    {footerContentsArr && footerContentsArr.map((footerContent) => {
                         const footerFields = isProsperTrue ? footerContent.fields && footerContent.fields.filter(obj=>!obj.isProsperRectrictedUrl ) : footerContent.fields
                        return (
                            <div className='footer-cmp-content-col' key={footerContent.label}>
                                <p className='footer-cmp-content-col-label'>{footerContent.label}</p>
                                {footerFields.map((contentFieldObj) => {
                                    if (!(contentFieldObj.text === 'Business Valuation' && iswhiteLabelConfig)) {
                                    return (
                                        <React.Fragment key={contentFieldObj.text ? contentFieldObj.text : contentFieldObj.imgAlt}>
                                            {contentFieldObj.isAbsoluteUrl ?
                                                <a href={contentFieldObj.renderUrl} target="_blank">
                                                    {contentFieldObj.text ?
                                                        <p className='footer-cmp-content-col-field' >{contentFieldObj.text}</p> :
                                                        <span>
                                                            <picture>
                                                                <source type="image/webp" srcSet={contentFieldObj.imgWebp} />
                                                                <img pomandae2e={contentFieldObj.imgAlt} src={contentFieldObj.img} className="footer-content-img" alt={contentFieldObj.imgAlt} height="45" width="45" />
                                                            </picture>
                                                        </span>
                                                    }
                                                </a> :
                                                <Link to={contentFieldObj.renderUrl} target={contentFieldObj.redirectWindow ? "_blank" : "_self"} rel={contentFieldObj.redirectWindow ? "noopener noreferrer" : ""} onClick={contentFieldObj.onClickFun}>
                                                    {contentFieldObj.text ?
                                                        <p pomandae2e={`${contentFieldObj.text} footerMenu`} className='footer-cmp-content-col-field' >{contentFieldObj.text}</p> :
                                                        <span>
                                                            <picture >
                                                                <source type="image/webp" srcSet={contentFieldObj.imgWebp} />
                                                                <img src={contentFieldObj.img} className="footer-content-img" alt={contentFieldObj.imgAlt} height="45" width="45" />
                                                            </picture>
                                                        </span>
                                                    }
                                                </Link>
                                            }
                                        </React.Fragment>
                                    ) }

                                })}
                            </div>
                        )
                    })}
                </div>
                <p className="footer-copyright-section">
                Copyright © 2024 FinFind Ltd T/A Pomanda (Company No. 09681085) All rights reserved.&nbsp;
                    <Link to="/assets/Download/Terms_Of_Use.pdf" pomandae2e="termsOfUse" target="_blank"
                        rel="noopener noreferrer" onClick={() => {
                            this.getActivityTracking({
                                eventPage: EP_FOOTER,
                                eventType: ET_TERMS_OF_USE_CLICK,
                                attribute1: FOOTER_TERMS_LINK,
                            })
                        }}><u>Terms&nbsp;of&nbsp;Use</u></Link> and&nbsp;
                    <Link to="/assets/Download/Pomanda_Privacy_Notice.pdf" pomandae2e="privacyPolicy" target="_blank"
                        rel="noopener noreferrer" onClick={() => {
                            this.getActivityTracking({
                                eventPage: EP_FOOTER,
                                eventType: ET_PRIVACY_POLICY_CLICK,
                                attribute1: FOOTER_PRIVACY_POLICY_LINK,
                            })
                        }}><u>Privacy&nbsp;Policy</u></Link>
                    &nbsp;applied. Registered Address: 3 Waterhouse Square, 138 Holborn, London EC1N 2SW. VAT No: GB 238771183
                </p>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    token: state.loginSignup.token,
    whiteLabelConfig: state.common.whiteLabelConfig,
    userInfo: state.userProfile.userInfo
});

const mapDispatchToProps = (dispatch) => ({
    toggleLoginModal: (toggleStatus, trackingInfo, onSuccessRender) => dispatch(toggleLoginModal(toggleStatus, trackingInfo, onSuccessRender)),
    dispatchSendActivityTracking: (trackingObj) => dispatch(sendActivityTracking(trackingObj)),
    setActivityTrackingData: trackingObj => dispatch(setActivityTrackingData(trackingObj)),
    dispatchSubmitInterestModal: (submitInterestModalObj) => dispatch(toggleSubmitInvestmentInterestModal(submitInterestModalObj))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomepageFooterV3));