import React, { PureComponent } from 'react'
import MediaQuery from 'react-responsive'
import './ValuationOnboardPage.css'
import { sendActivityTracking, setCurrencyDropdownStatus, setHideHeader } from '../../redux/actions/commonAction'
import { connect } from 'react-redux'
import {
    ET_BUSINESS_INFORMATION_ONBOARD,
    ET_BUY_BUSINESS_VALUATION_ONBOARD,
    ET_MODAL_CLOSE,
    ET_NAVIGATE_BTN_CLICK,
    ET_SELECT_INDUSTRY_ONBOARD
} from '../../common/trackingConstants'

import ProgressMenuComponent from '../BusinessPlanOnboardPage/components/ProgressMenuComponent'
import BuyBusinessValuation from './Components/BuyBusinessValuation'
import BusinessInformation from './Components/BusinessInformation'
import SelectIndustry from './Components/SelectIndustry'
import { handleProductPlanButtonClick } from '../../redux/actions/productAction'
import { removeValuationOnboardDataIntoRedux, setValuationOnboardDataIntoRedux } from '../../redux/actions/valuationAction'
import { checkMixIndustriesUpdateValid } from '../CompanyProfile/IndustryMix/IndustryMix'

const propsProgressMenuSteps = [
    {
        "componentId": "business_information",
        "step": "Basic Company Details",
        "text": "Enter your company basics and initial turnover number.",
    },
    {
        "componentId": "select_industry",
        "step": "Select Industries",
        "text": "Choose the sectors your company operates in",
    },
    {
        "componentId": "buy_business_valuation",
        "step": "Buy Business Valuation",
        "text": "We now have enough information to start your company valuation.",
    },
    {
        "componentId": "business_plan_compnay_search",
        "step": "Start Business Valuation",
        "text": "Open the valuation calculator to edit figures and get your report",
    }
]
const components = [
    {
        "componentId": "business_information",
        "ComponentName": BusinessInformation,
        "btnText": "START VALUATION",
        "trackingObj": {
            eventType: ET_BUSINESS_INFORMATION_ONBOARD
        },
    },
    {
        "componentId": "select_industry",
        "ComponentName": SelectIndustry,
        "btnText": "FIND INDUSTRY",
        "trackingObj": {
            eventType: ET_SELECT_INDUSTRY_ONBOARD
        },
    },
    {
        "componentId": "buy_business_valuation",
        "ComponentName": BuyBusinessValuation,
        "btnText": "GENERATE VALUATION",
        "btnCss": "buy-val-btn",
        "trackingObj": {
            eventType: ET_BUY_BUSINESS_VALUATION_ONBOARD
        },
    },
]

class ValuationOnboardPage extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            activeComponentIndex: 0
        }
    }
    componentDidMount() {
        this.props.dispatchSetHideHeader({ show: false });
        const indexValue = components.findIndex(obj => obj.componentId === this.props.valutionOnboardObj.componentId)
        this.handleValuationOnboardTracking(components[indexValue]?.trackingObj || {})
        this.setState({ activeComponentIndex: indexValue })
        this.props.dispatchSetCurrencyDropdownStatus(false);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.valutionOnboardObj.componentId !== this.props.valutionOnboardObj.componentId) {
            const indexValue = components.findIndex(obj => obj.componentId === this.props.valutionOnboardObj.componentId)
            this.handleValuationOnboardTracking(components[indexValue].trackingObj)
            this.setState({ activeComponentIndex: indexValue })
        }
    }

    componentWillUnmount() {
        this.props.dispatchSetHideHeader({ show: true,
            showSingleSearchbar: false,
         });
        this.props.dispatchRemoveValuationOnboardDataIntoRedux({})
        this.props.dispatchSetCurrencyDropdownStatus(true);
    }

    handleValuationOnboardTracking = (trackingObj) => {
        this.props.dispatchSendActivityTracking({
            eventPage: this.props?.eventPage,
            ...trackingObj
        })
    }

    onForwardAndBackBtnClick = (index) => {
        const { componentActive, industryList = [], componentId = "" } = this.props.valutionOnboardObj
        const checkIndustryIsValid = industryList.length && checkMixIndustriesUpdateValid(industryList)
        if (componentId === "select_industry" && index === 1 && !checkIndustryIsValid) {
            this.props.dispatchSetValuationOnboardDataIntoRedux({ indMixValidtion: !checkIndustryIsValid })
            return
        }
        if (!componentActive && index === 1) {
            return
        }
        this.handleValuationOnboardTracking({ eventType: ET_MODAL_CLOSE })
        const changedComponentsObj = components[this.state.activeComponentIndex + index]
        this.props.dispatchSetValuationOnboardDataIntoRedux({ componentId: changedComponentsObj.componentId })
        this.setState({ activeComponentIndex: this.state.activeComponentIndex + index })
    }
    handleCloseBtn = () => {
        this.props.history.push('/companyvaluation')
        this.handleValuationOnboardTracking({ eventType: ET_NAVIGATE_BTN_CLICK })
    }

    render() {
        const { valutionOnboardObj = {} } = this.props
        const { componentId = "business_information", componentActive } = valutionOnboardObj
        const { activeComponentIndex } = this.state
        const activeProgressMenuStepsObj = propsProgressMenuSteps.find(obj => obj.componentId === componentId)
        return (
            <div id="valuation_onboard_page" >
                <div className="d-flex flex-column flex-lg-row h-100">
                    <div className="valuation-left-side-progress-menu-section">
                        <div className='valuation-left-side-progress-menu-container'>
                            <picture>
                                <source media='(max-width: 991.98px)' srcSet='/assets/svgs/pom_ai_logo_mob.svg' width="35" height="25" />
                                <img src='/assets/svgs/pom_ai_logo_desktop.svg' alt='pom_ai_logo_desktop' width="180" height="40" className="cursor-pointer" onClick={() => this.props.history.push("/")} />
                            </picture>
                            <MediaQuery minWidth={992}>
                                {componentId ? <ProgressMenuComponent
                                    businessPlanActiveComponentId={componentId}
                                    propsProgressMenuSteps={propsProgressMenuSteps} />
                                    : null}
                            </MediaQuery>
                            <MediaQuery maxWidth={991.98}>
                                <i className='fa fa-close val-onboard-close-btn' aria-hidden="true" onClick={this.handleCloseBtn} />
                            </MediaQuery>
                        </div>
                        <MediaQuery minWidth={992}>
                            <div className='valuation-left-side-section-footer'>
                                <p className='mb-0 val-font-14 val-clr-D0D5DD'>© Pomanda AI - {new Date().getFullYear()}</p>
                                <p className='mb-0 val-font-14 val-clr-D0D5DD ml-auto'><a href="mailto:help@pomanda.com"><i class="fa fa-envelope-o" aria-hidden="true"></i> help@pomanda.com</a></p>
                            </div>
                        </MediaQuery>
                    </div>
                    <div className="valuation-right-side-section">
                        {activeComponentIndex < 2 && <MediaQuery maxWidth={991.98}>
                            <div className='progress-menu-step-mob-wrapper'>
                                <i className="fa fa-chevron-left cursor-pointer" onClick={activeComponentIndex > 0 ? () => this.onForwardAndBackBtnClick(-1) : this.handleCloseBtn}></i>
                                <div>
                                    <p className='progress-menu-steps-heading'>{activeProgressMenuStepsObj.step}</p>
                                    <p className='progress-menu-steps-text'>{activeProgressMenuStepsObj.text}</p>
                                </div>
                            </div>
                        </MediaQuery>}
                        <div className='valuation-right-side-component'>
                            {components.map((item, index) => {
                                const { ComponentName, props = {} } = item
                                if (componentId === item.componentId) {
                                    return <React.Fragment key={`valuation_onboard_container_${index}`}>
                                        <ComponentName key={`valuation_onboard_${index}`} eventPage={this.props.eventPage}
                                            {...props} componentId={componentId} handleValuationOnboardTracking={this.handleValuationOnboardTracking}
                                            onBackBtnClick={() => this.onBtnClick(-1)} />
                                        {componentId !== "buy_business_valuation" ? <MediaQuery minWidth={992}>
                                            <div className='valuation-onboard-footer-wrapper'>
                                                {activeComponentIndex > 0 && <button className={`valuation-onboard-footer-btn ${(activeComponentIndex >= 0 || componentActive) ? "footer-active-btn" : ""}`}
                                                    onClick={() => this.onForwardAndBackBtnClick(-1)} pomandae2e="chevron left">
                                                    <i className="fa fa-chevron-left" ></i>
                                                </button>}
                                                <button className={`valuation-onboard-footer-btn ${componentActive ? "footer-active-btn" : ""}`}
                                                    disabled={!componentActive} onClick={() => this.onForwardAndBackBtnClick(1)} pomandae2e="chevron right">
                                                    <i className="fa fa-chevron-right" ></i>
                                                </button>
                                            </div>
                                        </MediaQuery>: null}
                                    </React.Fragment>
                                }
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    valutionOnboardObj: state.valuation?.valutionOnboardObj,
    userInfo: state.userProfile.userInfo,
})

const mapDispatchToProps = (dispatch) => ({
    dispatchSetHideHeader: (headerProps) => dispatch(setHideHeader(headerProps)),
    dispatchHandleProductPlanButtonClick: (productObj, eventPage) => dispatch(handleProductPlanButtonClick(productObj, eventPage)),
    dispatchSetValuationOnboardDataIntoRedux: (valObj) => dispatch(setValuationOnboardDataIntoRedux(valObj)),
    dispatchRemoveValuationOnboardDataIntoRedux: () => dispatch(removeValuationOnboardDataIntoRedux()),
    dispatchSendActivityTracking: (trackingObj) => dispatch(sendActivityTracking(trackingObj)),
    dispatchSetCurrencyDropdownStatus: (status) => dispatch(setCurrencyDropdownStatus(status)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ValuationOnboardPage);