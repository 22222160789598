import { RESTAPI_URL } from "../common/config";
import { commonFetch } from "./fetchComImpl";
import { refreshTokenApi } from "./refreshTokenApi";

export const getCountryListApiCall = async () => {
    try {
        await refreshTokenApi(`${RESTAPI_URL}/refresh/token`,
            'POST',
            {},
            new Headers({
                'content-type': 'application/json'
            })
        );
        const response = await commonFetch(`${RESTAPI_URL}/country/getValuationCountryList`,
            'GET',
            {},
            new Headers({
                'content-type': 'application/json'
            }));
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}