import React from "react";
import YellowBgButton from "../BusinessPlanTemplate/BusinessPlanComponents/YellowBgButton/YellowBgButton";
import { Link } from "react-router-dom";
import xss from "../../common/xssconfig";

const SelectBusinessTypeCard = (props) => {
    const {
        heading = "",
        btnText = "",
        icon = "",
        width = "",
        height = "",
        alt = "",
        subHeading = "",
        onBtnClick = () => { },
        textLink = {},
        componentId = ""
    } = props;
    return <div className="select-business-type-card" id={componentId}>
        {icon ? <img src={icon} width={width} height={height} alt={alt} className="select-business-card-icon" /> : null}
        <p className="select-business-type-heading mb-0">{heading}</p>
        {subHeading ? <p className="select-business-type-subHeading mb-0" dangerouslySetInnerHTML={{__html: xss.process(subHeading)}} /> : null}
        {btnText ? <div className="select-business-type-btn-container">
            <YellowBgButton
                text={btnText}
                onBtnClick={onBtnClick}
                cmpCssClassStr="select-business-type-button"
            />
            {textLink?.url ? <Link target="_blank" className="mt-2 d-block" to={textLink.url}>{textLink.text}</Link> : null}
        </div> : null}
    </div>
}

export default SelectBusinessTypeCard;