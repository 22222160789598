import React from 'react';
import { Helmet } from 'react-helmet';
import HomePageFooter from '../HomePage/HomePageFooter/HomePageFooter';
import './GlossaryPage.css';

function GlossaryPage() {
  return (
    <div className="grossary-page-main-container">
      <Helmet>
        <title>Glossary of Financial Terminology | Pomanda</title>
        <meta content="Glossary of Financial Terminology | Pomanda" property="og:title" />
        <meta content="Translate complex financial terminology into everyday language with 
          Pomanda’s Glossary" name="description" />
      </Helmet>
      <div className="grossary-page-container">
        <h1><b>Glossary</b></h1>
        <p>
          Our extensive glossary guides you through the financial terminology used around
          our site, and particularly in our guide ‘How to value your business for the
          small to medium size business owner.
        </p>
        <h2><b>Absolute Value</b></h2>
        <p>This valuation methodology estimates the “absolute” value of a company based
          on its projected cash flows. </p>
        <p>It differs from relative value methods which calculate the valuation of
          a company by comparing it with its peers.</p>
        <h2><b>Amortisation</b></h2>
        <p>This is the practice of reducing the value of intangible assets over time
          on a company’s balance sheet .</p>
        <p>
          Amortisation is similar to depreciation, but the term is used in reference to
          intangible assets such as goodwill instead of tangible assets such as office equipment.
        </p>
        <p>
          During the amortisation process, the value of the asset that has been reduced
          (written down) is transferred from the balance sheet to the profit and loss account
          because it is considered an operating cost of the business. This in turn can reduce
          the taxable income that a company has to pay.</p>
        <h2><b>Balance Sheet </b></h2>
        <p>The balance sheet shows the financial status of a company at a given moment in time
          (normally financial year end or half year). The balance sheet shows the assets of
          the company, but also how it has paid for those assets – by debt (liabilities) or
          equity issued. Therefore, on a balance sheet, the company’s assets are equal to
          company liabilities plus shareholder’s equity.</p>
        <p>Assets include tangible assets such as land, machinery, capital, receivables and
          cash, along with intangible assets such as patents and goodwill.</p>
        <p>Liabilities show the company’s debts (e.g. accounts payables), while shareholder’s
          equity reflects the equity that shareholders have put in the business. The balance
          sheet is an important document for properly understanding a company’s financial
          situation (and it is considered harder to manipulate than the income statement,
          which can potentially be presented to improve the appearance of a company’s financial health).</p>
        <h2><b>BIMBO</b></h2>
        <p>
          Buy in Management Buy Outs (termed BIMBOs) aim to bring together both Management Buy-Ins and
          Management Buy-Outs. They involve the purchase of a company (or a controlling stake in
          it) by both existing management and new external management. This approach seeks to
          couple internal knowledge of the business with the expertise and skills sets that
          external management may provide.
        </p>
        <p>
          Assets include tangible assets such as land, machinery, capital, receivables and cash, along
          with intangible assets such as patents and goodwill.
        </p>
        <h2><b>Book Value</b></h2>
        <p>
          The book value of a company (also known as “Net Asset Value” or “Net Book Value”) is its
          value listed on a company balance sheet. This is equivalent to the company’s total
          assets minus its total liabilities.
        </p>
        <p>Since on the balance sheet, assets = liabilities + shareholder equity, the book
          value of a company is equivalent to shareholder equity. Accurately valuing assets
          and liabilities is difficult because they do not always have a cash value.
          Historically book value has been used for valuing asset heavy companies, such as
          manufacturing companies and financial institutions.</p>
        <p>
          Book value is sometimes used to compare a firm’s market value to its book value
          (the rationale is that if the assets are worth more than the market value, the
          company is attractive to buy). The multiple of the price paid is described as the
          price to book ratio (or P/B ratio).
        </p>
        <p>
          There are limitations to using book value for valuations. Book value can be impacted
          by depreciation / amortisation and other adjustment to asset values. As a result,
          some investors use “tangible book value” (which is book value – goodwill and intangible
          assets ) as a more meaningful measure.
        </p>
        <h2><b>Capital Expenditure (CAPEX)</b></h2>
        <p>This is investment in long life tangible assets such as industrial buildings, property
          or equipment. It is normally used for new projects or investments by the business. This
          kind of spending is made by companies to maintain or enlarge the scope of their operations.</p>
        <p>Appears on the balance sheet, depreciated through P&L , and impacts cash flow statements.</p>
        <p>Capital investments are depreciated over time to reflect their diminishing value. The
          value of this depreciation is run through the income statement and reduces taxable income.</p>
        <h2><b>Carried Forward Losses</b></h2>
        <p>
          An accounting technique that allows a company to use losses in a financial year to reduce
          future tax bills. This works by a business “carrying forward” tax-generated losses from
          one year to reduce future years’ profits. In turn this reduces the future tax a company has to pay.
        </p>
        <h2><b>Comparable Company Analysis</b></h2>
        <p>This company valuation technique involves comparing a company’s performance to the performance
          of similar ‘public market companies’, which are trading on a public market exchange, normally
          a stock market. Peer companies may be categorised based on any number of criteria, such as
          industry focus, company size, or growth characteristics. Comparable company analysis assumes
          that a company will be valued on a similar basis to its peers, such that it will be worth a
          similar multiple of Revenues or a similar multiple of EBITDA.</p>
        <p>There are some disadvantages to this approach as no company is exactly alike and there may
          be few genuinely comparable companies. Temporary market conditions, such as some unexpected
          political news, can easily impact the trading multiples of public companies.</p>
        <h2><b>Corporate Advisors</b></h2>
        <p>These are professionals who advise SMEs on activities like mergers and acquisitions, corporate
          division and restructuring and other transactions that involve a change of ownership. This
          service is commonly referred to as ‘M&A’, which stands for “Mergers and Acquisitions”.</p>
        <h2><b>Deal Structure</b></h2>
        <p>This is the detail of the terms and conditions agreed in financing an acquisition. The deal
          structure covers whether a business sale will be structured as an asset transfer or a stock sale.
          It also lays out the ownership structures such as the equity ownership structure (i.e. common
          stock, preferred stock or convertible notes) and the corporate structure (i.e. limited company,
          public limited company or limited liability partnership). There are many different factors that
          need to be agreed with in a deal structure, which includes (but is not limited to):</p>
        <ul>
          <li>
            Consideration
          </li>
          <ul>
            <li>
              Will the sale of a business be a stock/share transfer, an asset sale, or a merger?
            </li>
            <li>
              How will the owner be paid – earn out, deferred payment or stock options (when the
              new business owner is liable for delivering a given number of shares to the previous
              owner at a pre-agreed price with a specified period)?
            </li>
            <li>
              Down payments or other part payments
            </li>
          </ul>
          <li>
            Control
          </li>
          <ul>
            <li>Voting rights (depending on the equity ownership structure)</li>
            <li>Directors – who will be the future directors of a company</li>
            <li>Employee share options (if applicable) </li>
          </ul>
          <li>Risk management</li>
          <ul>
            <li>Liquidation preference depending on the ownership structure (who gets paid off
              first in the case of liquidation/sale)</li>
            <li>Conversion – which events will trigger convertible notes (a temporary loan which
              is switched to equity once the value of the business can be established) </li>
            <li>Anti-dilution – provision to stop part owners seeing the value of their investments diminish</li>
            <li>Right of first refusal on further investments</li>
          </ul>
          <li>Financing</li>
          <ul>
            <li>How the deal will be financed</li>
            <li>Outside lender financing terms</li>
            <li>Anti-dilution – provision to stop part owners seeing the value of their investments diminish</li>
            <li>Right of first refusal on further investments</li>
          </ul>
          <p>The deal structure can be complex and requires specialist legal advice.</p>
        </ul>
        <h2><b>Depreciation </b></h2>
        <p>
          An accounting method of allocating the cost of a tangible asset over its useful life. For
          example, if you purchased a piece of machinery for £100, then over time the value of that
          machinery will reduce. This is because the useful working life of the machinery decreases
          over time, and you would not be able to sell it for the amount that you bought it for.
          Accountants will reflect this by adjusting.</p>
        <p>
          How quickly an asset depreciates depends on the method of depreciation and the depreciation rate.
        </p>
        <p>
          If an asset has a “straight line” depreciation rate of 20%, then it will be reduced in value by
          20% of its original value each year. After one year it would be worth 80% of its original value,
          after two years 60% of its original value. After five years it would be worth 0%. Depreciation
          has implications for your P&L account and your balance sheet . A depreciation charge reduces
          the taxable income that you will pay in a given year. It also reduces the value of your asset
          listed on your balance sheet.
        </p>
        <p>Capital investments such as industrial buildings, property or equipment are depreciated
          over time to reflect their diminishing value. The value of this depreciation is run through
          the income statement and reduces taxable income.</p>
        <h2><b>Depreciation Rate</b></h2>
        <p>
          This is the rate at which assets are depreciated for accounting purposes (see depreciation ).
          The depreciation rate is the annual rate at which an asset is depreciated. There are many
          methodologies for calculating depreciation, though straight line depreciation (a set
          percentage depreciation each year) is the most commonly used calculation. For example, if
          an asset has a depreciation rate of 20%, then it will be reduced in value by 20% of its original
          value each year. After one year it would be worth 80% of its original value, after two years 60%
          of its original value. After five years it would be worth 0%. If an asset has a “straight line”
          depreciation rate of 20%, then it will be reduced in value by 20% of its original value each
          year. After one year it would be worth 80% of its original value, after two years 60% of its
          original value. After five years it would be worth 0%. Other accelerated methods can be used
          for depreciation such as the Sum of Year or Double Declining method. These depreciate assets more rapidly.
        </p>
        <h2><b>Discounted Cash Flow Valuation</b></h2>
        <p>
          This is a valuation method that uses estimates of future cash flows to calculate the value
          of the business today.
        </p>
        <p>It is one of the most important and commonly used valuation methods when selling your business.
          It works by estimating as accurately as possible what cash flows are likely to be in the future
          up to a certain point in time, known as the terminal value. At the terminal value, it is assumed
          that cash flows will grow at a steady rate (known as the long term growth rate).</p>
        <p>
          These future cash flows and the terminal value are then discounted to calculate what they would
          be worth today, known as the present value. This is because cash received in the future is worth
          less today (because of inflation and also that cash held today could have been invested elsewhere
          to earn a return- a factor known as the “time value of money”).
        </p>
        <p>
          The further into the future the cash flow is, the more significantly it is discounted. The
          discount rate used also impacts the final present value.
        </p>
        <p>The present value is used to calculate the value of the business.</p>
        <h2><b>Discount Rate</b></h2>
        <p>
          This is the rate used to discount future cash flows in the discounted cash flow valuation model.
          It is normally presented as an annual rate.
        </p>
        <p>
          Future cash flows are discounted to reflect inflation and the “time value of money”. Time value
          of money is a concept which states that cash received in the future is worth less today than if
          you received that same cash today. This is because if you received that same cash today, you would
          be able to invest it to create more money in future.
        </p>
        <p>
          Once applied to future cash flows, the discount rate generates a present value of those cash flows.
          The higher the discount rate, the lower the present value. This present value is in turn used
          to value companies.
        </p>
        <p>Deciding a discount rate is a complex but critical task. Some investors use WACC as the discount
          rate, though there are many other calculation methods.</p>
        <h2><b>Earn Outs</b></h2>
        <p>
          A provision written into some financial transactions whereby the seller of a business will receive
          additional payments based on the future performance of the business sold.
        </p>
        <h2><b>EBIT</b></h2>
        <p>
          EBIT (or Earnings before Interest and Tax) is also known as operating profit. It is a measure of a company’s
          profits over a particular period of time, without including interest payments on its debt and payment of tax.
        </p>
        <p>
          This measure is considered a more accurate measure of company operating performance than “Net Profit” since
          it doesn’t include the impact of the tax regime in which the company operates, and its financing decisions.
        </p>
        <p>EBITDA is often considered an even better measure of company operating performance than EBIT.</p>
        <h2><b>EBITDA</b></h2>
        <p>Earnings before interest, tax, depreciation and amortization (EBITDA) measures a company’s
          operating performance. It is considered an attractive measure for valuing the normal operating
          performance of a company since it removes the impact of the tax regime in which a company
          operates, its financing decisions and its accounting decisions. It is also a good measure of
          operating cash flow because it excludes non-cash charges of depreciation and amortisation .
          EBITDA is the most commonly used measure of operating profits when valuing a company, because
          it shows the true performance of a company.</p>
        <h2><b>EBITDA Multiple Value</b></h2>
        <p>Also known as an ‘enterprise multiple’, this is a ratio used to determine the value of a
          company. The EBITDA multiple value looks at a firm as a potential acquirer would, taking into
          account the company’s debt, which other multiples like the price-to-earnings (P/E) ratio do
          not include.</p>
        <p>EBIDTA multiple value is one of the most commonly used valuations for business acquisitions.</p>
        <h2><b>Effective tax rate</b></h2>
        <p>The actual rate of tax paid. This is calculated as total tax paid divided by total taxable income.
          It shows the percentage tax rate that you pay across all your income.</p>
        <p>EBIDTA multiple value is one of the most commonly used valuations for business acquisitions.</p>
        <h2><b>Entry Cost Valuation</b></h2>
        <p>A method of valuation based on the amount a brand new business of the same nature would cost to start
          from scratch. It involves calculating the costs to the business of:</p>
        <ul>
          <li>Raising finance</li>
          <li>Purchasing any assets</li>
          <li>Developing obligatory products</li>
          <li>Recruiting and training necessary staff</li>
          <li>Cultivating a customer base</li>
        </ul>
        <h2><b>Enterprise Value</b></h2>
        <p>This measures the value of a company and is considered a more comprehensive (and accurate)
          value than market capitalisation.</p>
        <p>It measures the value of a company’s shares, preferred stock (shares that have a claim over
          the company’s earnings), debt and any minority interest (a non-controlling ownership of a
          company), minus the cash and investments in a business.</p>
        <h2><b>Enterprise Value to Revenue Multiple</b></h2>
        <p>A measure of the value of a company that compares a company’s enterprise value to its revenue.
          The enterprise revenue multiple value is often used by potential acquirers to estimate a company’s value.</p>
        <h2><b>Equity Ownership Structures</b></h2>
        <p>These are the different equity ownership structures for available to company owners. These include
          structures such as common stock, preferred stock (shares that have a claim over the company’s
          earnings) or convertible notes (a temporary loan which is switched to equity once the value of
          the business can be established). The equity ownership structure determines who has priority in
          getting back their equity should the company go into liquidation. For example, preferred stockholders
          get priority over common stockholders.</p>
        <p>Convertible notes are favoured by many investors, since they are debt instruments that can be
          converted to equity at a certain point in time. Since they are debt instruments, they are higher
          up the capital structure – i.e. investors will be paid back earlier should the company go bankrupt.</p>
        <h2><b>Financial Buyers/ Investors</b></h2>
        <p>Financial investors invest capital in a company with the expectation of a future financial return
          (i.e. they are looking at investing in a company solely from a financial return perspective).</p>
        <h2><b>Free Cash Flows:</b></h2>
        <p>The cash a company has after all outgoings, including dividends, debt payments, tax, operating
          costs and capital expenditure.</p>
        <p>It is a way to measure how much cash a business makes after taking into capital expenditures on
          items such as buildings or equipment.</p>
        <p>Free cash flow is a key measure used in valuation models such as Discounted Cash Flow model, which
          itself is one of the most commonly used models for valuing businesses. Measuring cash flow is
          often seen as a better measure of company performance than revenue, which can be adjusted by
          various accounting practices. </p>
        <h2><b>Goodwill</b></h2>
        <p>The value of a business to a purchaser over and above the value of identifiable business assets
          (net asset value). Goodwill is an intangible asset that represents the fraction of the business
          value that cannot be attributed to other tangible or intangible business assets.</p>
        <p>Goodwill includes elements such as a Company’s brand, its relationship with its customers,
          employee morale and its value as a going concern.</p>
        <p>Like other tangible and intangible assets, the value of goodwill on the balance sheet is
          reduced over time. This process is known as amortisation.</p>
        <h2><b>Growth Business</b></h2>
        <p>A growth business is a business that is growing faster than the economy as a whole. Growth
          businesses tend to have specific characteristics from an investment perspective. Because of
          their rapid growth, they are more likely to reinvest any earnings back into the business,
          rather than pay out dividends. Growth businesses typically do well in market upturns, and
          perform less well in market downturns.</p>
        <p>In recent years, growth businesses have included technology companies such as Uber or Facebook.</p>
        <h2><b>Growth Capital</b></h2>
        <p>The capital used to buy additional assets. It is needed when an organisation wishes to
          expand and needs to acquire assets like software, equipment and inventory.</p>
        <h2><b>Investors value liquidity</b></h2>
        <p>Investors value liquidity – the ability to buy and sell securities or assets rapidly at fair
          value. If assets are illiquid, then investors will want a significant discount for holding them.</p>
        <p>In the case of private companies, a liquidity discount is applied because they have restricted
          or closely held shares (to reflect the difficulty of buying or selling them at fair value in private markets).</p>
        <h2><b>Industry Profile</b></h2>
        <p>Industry profiles are documents that look at a specific industry in depth; its key trends
          and drivers and its financial and economic prospects.</p>
        <p>They aim to give detailed insights to prospective investors in a sector.</p>
        <h2><b>Intangible Assets</b></h2>
        <p>Intangible assets are assets that have no physical form but are considered valuable assets of
          the business. This covers assets such as patents, trademarks, brands, licences and franchises.</p>
        <p>Intangible assets also include goodwill, which is the value of a business to a purchaser
          over and above the value of identifiable business assets. This takes into account elements
          such as reputation, good customer relations and strong employee morale.</p>
        <p>Intangibles have a value listed on the company’s balance sheet, and are amortised over time.
          As with other forms of depreciation and amortisation, this creates a charge on the income
          statement (P&L) which can reduce taxable income.</p>
        <h2><b>IPO</b></h2>
        <p>IPO stands for “Initial Public Offering”. This is the first time a company’s share are
          offered for sale to the public, rather than other selected institutional or private investors.
          This process is also known as “company flotation”, and involves listing the company’s shares
          for sale in a stock market of a company’s choosing.</p>
        <p>IPOs are typically used to raise money to grow businesses, fund acquisitions, to broaden
          the shareholder base and to provide liquidity (private companies are often illiquid). IPOs
          also create an added benefit of more publicity for a company.</p>
        <p>IPO downsides include the cost of the IPO itself (which can be high) and the requirement
          to adhere to stock market rules. A company will most likely have to communicate with a much
          larger investor base after IPO.</p>
        <h2><b>Leveraged Buyout</b></h2>
        <p>A leveraged buyout is an acquisition of a company or a segment of a company funded mostly
          with debt. A financial buyer invests a small amount of equity and borrows money to fund the
          remainder of the consideration paid to the seller.</p>
        <p>In the case of private companies, a liquidity discount is applied because they have restricted
          or closely held shares (to reflect the difficulty of buying or selling them at fair value in
          private markets).</p>
        <h2><b>Long Term Growth Rate</b></h2>
        <p>A rate measuring the appreciation in value of a company or asset over a relatively long period of
          time. The long term growth rate is an important element within the discounted cash flow
          valuation model. It is used in the calculation of the terminal value, and it estimates what
          will be the long term stable growth rate of the company.</p>
        <p>The terminal value is the current value (present value) of a future point in time when a company’s
          cash flows are expected to grow at a stable rate forever.</p>
        <p>Calculating the long-term growth rate is a complex and subjective task. It involves estimating
          qualitative factors such as organic and inorganic growth strategies for the company, and
          quantitative factors such as historical financial information,management prepared projects and
          estimates of projected economic growth and inflation.</p>
        <p>Since the terminal value can make up a large proportion of the total value of a company, the
          long term growth rate used has a significant impact on the final valuation used.</p>
        <h2><b>Majority stake</b></h2>
        <p>This is when one person or a group holds more than 50% of shares in a company. This is known
          as a controlling stake as the person (or group) is able to exert control over the company.</p>
        <p>Buyers seeking to exert control over a company will have to acquire a majority stake in that company.</p>
        <h2><b>Multiples (relative value)</b></h2>
        <p>Multiple based valuations are founded on the simple principle of comparing a company’s performance
          and valuation with that of its public market competitors. Since this involves how the company
          performs relative to its peers, it is often known as “relative value”. Relative value is usually
          expressed in the context of an industry-wide basis and as a multiple of certain metrics – for example,
          companies in the technology industry may on average be valued at 25 times the EBITDA they produce.
          Therefore, you would say that the technology industry has a multiple of 25 (against EBITDA ).</p>
        <p>The method would then calculate how the company might be valued on the basis of its current
          EBITDA (e.g. 25 times EBITDA) and based on future projections of EBITDA growth.</p>
        <p>n reality the process is more detailed, and involves analysing historical trends
          and adjusting projections according to a variety of factors.</p>
        <h2><b>Management Buy-In</b></h2>
        <p>This is the purchase of a company (or a controlling share in a company) by external
          management who are not employees of the company.</p>
        <p>Management Buy-Ins offer the knowledge and experience of external management, and
          can be useful when companies are seeking to expand into new markets but lack expertise
          amongst their current management team.</p>
        <p>Management buy-ins may face challenges in getting the cooperation of existing
          management and employees.</p>
        <h2><b>Management Buyout</b></h2>
        <p>This is the purchase of a company (or a controlling share in a company) by existing
          management or employees.</p>
        <p>Management Buyouts are frequently used in businesses where the existing shareholders
          are looking to cash out (for example when a business founder is retiring).</p>
        <p>While there are significant advantages in Management Buyouts (continuity of management,
          knowledge of the business), existing management may not have the expertise to grow the business.</p>
        <h2><b>Marginal Tax Rate</b></h2>
        <p>The rate of tax per additional unit of income. For example, if you are an “Additional Rate”
          tax payer in the UK, your marginal tax rate is 45%. This means you would pay 45p in tax for
          each additional £1 you earn.</p>
        <h2><b>Mature Business</b></h2>
        <p>A mature business is a business which is not new and grows (or shrinks) at
          the same rate as the general economy or less.</p>
        <p>Mature businesses are characterised by slow growth but stable profits. They frequently
          pay out greater dividends than growth businesses (in part because they may not have the
          growth opportunities to invest into within the company).</p>
        <h2><b>Method- end year/ mid-year</b></h2>
        <p>The mid-year or end-year method are different ways of calculating discounted cash flow
          or enterprise value calculations.</p>
        <p>The end year method assumes that all cash flows are received at the year-end for the purposes
          of calculating valuations. This can lead to distorted valuations, since most companies’ cash
          flows are likely to be uniformly received throughout the year, rather than just at the end of
          the year. The mid-year method instead approximates uniform cash flow throughout the year – i.e.
          the company receives cash flow evenly throughout the year.</p>
        <p>The mid-year method can help create more accurate valuations.</p>
        <h2><b>Minority Stake</b></h2>
        <p>This is a shareholding stake of less than 50% of a company’s share capital. Minority
          shareholders do not exert control over a company (which is known as a majority/controlling stake).
          Minority stakes have implications for how company reports its profit and loss account
          (for example if a parent company owns a subsidiary which has minority stakes, it must deduct
          the earnings or losses attributable to these minority stakes; this is known as minority interests).</p>
        <h2><b>Net Asset Value (NAV)</b></h2>
        <p>The Net Asset Value of a company is the total assets of the business minus the total liabilities. </p>
        <h2><b>Normalisation</b></h2>
        <p>Normalisation is the process of adjusting company accounts to reflect what a potential purchaser would
          expect to receive under their ownership.</p>
        <p>During the normalisation process, many adjustments are made to accounts. This includes removing investment
          costs for new business operations that have yet to deliver a return, adjusting for the owner’s
          current compensation and removal of one-off costs (also known as non-recurring costs). These
          adjustments are also known as “Add Backs”.</p>
        <p>The normalisation process creates an “Adjusted Net Profit” figure. This is considered a good
          indicator of the true profitability of the business.</p>
        <h2><b>P&L</b></h2>
        <p>P&L stands for “Profit and Loss”. The Profit and Loss account shows the financial results of a
          company’s normal activities (typically over the course of a financial year).</p>
        <p>It shows a company’s revenue and costs over a particular period, along with calculations
          as to profit and loss. It typically includes operating (normal) and non-operating (exceptional) items.</p>
        <h2><b>Present Value</b></h2>
        <p>Present value is an estimate of the current value today of future receipts or payments.</p>
        <p>It is calculated by taking future receipts or payments, and reducing their value by a
          percentage rate. This percentage rate is known as a discount rate.</p>
        <p>The discount rate is used to reflect the “time value of money” concept – the idea that money
          received in the future is worth less than money received today. This is because money received
          today could be invested to receive a greater return in the future (and therefore is worth more
          than just receiving the same money at some stage in the future). In addition, inflation will
          reduce the value of cash flows received in the future.</p>
        <p>The discount rate applied to calculate present value depends on many factors (such as inflation,
          the cost of capital and the risk of those future payments or receipts).</p>
        <h2><b>Projection Period</b></h2>
        <p>This is the time period for projecting future cash flows when calculating the valuation of a
          company. This would typically run between 1 and 10 years, depending on the nature (and history) of the company.</p>
        <p>The projection period used is an important element within the discounted cash flow valuation method.</p>
        <p>The projection period continues until the terminal value , at which point in time it is
          assumed that cash flows will grow at the long term growth rate.</p>
        <h2><b>Revenue</b></h2>
        <p>Revenue is money received by a company over a period of time for its goods and services.
          It is also known as sales or turnover.</p>
        <h2><b>Stake</b></h2>
        <p>A share of a financial involvement in a business.</p>
        <h2><b>Strategic Buyers</b></h2>
        <p>These are investors who acquire stakes in a company as part of their long term business (i.e.
          strategic) goals. They are normally existing companies that are often competitors, suppliers
          or customers of your firm. Their goal is to identify companies whose products or services can
          easily integrate with their existing business to create long term shareholder value.</p>
        <p>Strategic Buyers include Trade Buyers, who are bidding to purchase a business that is
          in the same industry as them. They are the most common type of bidder in a takeover.</p>
        <h2><b>Tangible Asset</b></h2>
        <p>An asset that is tangible in nature such as fixed assets, such as buildings, land and machinery.</p>
        <p>The value of a tangible asset reduces over time. This is known as depreciation.</p>
        <p>Depreciation reduces the value of a tangible asset recorded on the balance sheet, and
          also reduces taxable income on the P&L account.</p>
        <h2><b>Weighted Average Cost of Capital (WACC)</b></h2>
        <p>The average rate of return a company expects to pay its different investors (e.g. equity investors,
          bond holders etc.) This figure represents the cost to the business of its financing costs.</p>
        <p>WACC is typically used to value companies and also as a hurdle rate to decide whether to
          invest in capital projects (i.e. the minimum return). This is a logical hurdle rate, as it
          describes the cost to the company of its financing, which is the minimum return it should
          accept to be profitable</p>
        <p>The WACC is commonly used in the discounted cash flow valuation model as the discount rate
          used to calculate the present value of future cash flows. It is one of the most important
          components in the calculation.</p>
        <h2><b>Working Capital</b></h2>
        <p>The amount of cash and other liquid assets a business holds in order to manage its day-to-day
          trading operations. This is calculated as current assets minus the current liabilities.</p>
        <ul>
          <li>Current assets include cash, marketable securities (financial instruments that can be bought
            or sold), prepaid expenses, accounts receivable and inventory.</li>
          <li>Current liabilities include accounts payable, debt payable, accrued expenses and tax payable.</li>
        </ul>
        <p>There are three key elements in working capital management – accounts receivable, inventory and
          accounts payable. Accounts receivable includes money owed to the company for sales made, but
          where they have not yet received payment for the sale.</p>
        <ul>
          <li>Accounts receivable includes money owed to the company for sales made, but where they
            have not yet received payment for the sale</li>
          <li>Inventory includes the stock of goods (or assets) that the company sells to create revenue.</li>
          <li>Accounts payable – goods that the company has agreed to purchase but not yet paid for in the short term.</li>
        </ul>
        <p>Effective working capital management involves managing accounts receivable to ensure that
          the company is paid promptly, managing inventory turnover to meet demand effectively (and
          avoid overstocking) and ensuring that the company effectively controls its payments.</p>
        <p>Buyers in a company will be interested in how effectively the company is managing its working capital.</p>
        <p>For any further information, please contact us at: <a href='mailto:theteam@pomanda.com'>theteam@pomanda.com</a></p>
        <p>© Pomanda 2022. All rights reserved.</p>
        <div className="grossary-page-footer-section-wrapper">
          <HomePageFooter />
        </div>
      </div>
    </div>
  )
}

export default GlossaryPage