import React from 'react';
import './buttons.css';

const YellowBorderButton = (props) => {
    const {
        text = "",
        handleClick = () => {},
        cssClassName = '',
        pomandae2eKey=""
    }=props
    return (
        <React.Fragment>
            {
            text === "CONTINUE, WITHOUT DOWNLOAD" ?
            <button type="button" className={`payment-response-modal-cr-button ${cssClassName ? cssClassName : ''}`} onClick={handleClick} pomandae2e={text}>{text}</button>
            :
            <button type="button" className={`text-uppercase search-header-button yellow-border-button ${cssClassName ? cssClassName : ''}`} onClick={handleClick} pomandae2e={pomandae2eKey}>{text}</button>
            }    
        </React.Fragment>
    );
};

export default YellowBorderButton;