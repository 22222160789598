const xssObj = require('xss');
const options = {
    whiteList: {
        span:['class'],
        a: [...xssObj.whiteList['a'],'class'],
        div:['class'],
        p: ['class'],
        u: ['class']
    }
}
const xss = new xssObj.FilterXSS(options);
export default xss;