import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isBrowser, REACT_APP_GOOGLE_INDEX } from "../../common/config";
import './CompaniesPPCLandingPage.css';
import {
    sendActivityTracking, setHeaderSingleSearchOptions, setHideHeader, toggleCommonMsgModal
} from "../../redux/actions/commonAction";
import {
    ET_BUY_CREDIT_REPORT_CLICK,
    ET_CREDIT_REPORT_CLICK,
    ET_EXPIRED_BUY_VALUATION_REPORT_BTN_CLICK,
    ET_OPEN_VALUATION_REPORT_BTN_CLICK,
    ET_PRODUCT_HOMEPAGE_SEARCHBAR_ENTER_CLICK,
    ET_PRODUCT_HOMEPAGE_SINGLE_SEARCH, ET_REMAINING_CREDITS_BUY_VALUATION_REPORT_BTN_CLICK, ET_SEARCH_COMPANIES_PPC_LANDING_PAGE_ONLOAD,
    ET_SEARCH_COMPANIES_RETURN_BTN_CLICK, ET_SEE_MORE_COMPANIES_BTN_CLICK,
    ET_VALUATION_REPORT_BUY_CONFIRMATION_NO_CLICK,
    ET_VALUATION_REPORT_BUY_CONFIRMATION_YES_CLICK,
    ET_VALUATION_REPORT_PACKAGE_BUY_NOW_BTN_CLICK, SEARCH_COMPANIES_PPC_LANDING_PAGE_SEARCHBAR_ENTER,
    SEARCH_COMPANIES_PPC_LANDING_PAGE_SEARCHBAR_ICON, SEARCH_COMPANIES_PPC_LANDING_PAGE_SEARCHBAR_OPTION,
    SEARCH_COMPANIES_PPC_LANDING_PAGE_SEARCHBAR_SEARCH
} from "../../common/trackingConstants";
import { callSingleSearchApiAction } from "../../redux/actions/searchAction";
import { BuyValuationReportForCompany } from "../BuyValuationReportForCompany/BuyValuationReportForCompany";
import { MatchingCompaniesHeader } from "../MatchingCompaniesHeader/MatchingCompaniesHeader";
import HomepageFooterV3 from "../HomePage/HomepageFooterV3/HomepageFooterV3";
import { getDefaultFilterWithSearch, getSearchQueryDataObj } from "../../common/SearchCommonFunctions";
import { getValutionReportsList, setRestrictionForProsperUser, updateValuationReportCreditRequest } from "../../redux/actions/userAccountAction";
import { CREDIT_REPORT_OF_DISSOLVED_COMPANY, EXISTING_COMPANY_VALUATION_RP } from "../../common/messageModalConstants";
import { SR_VALUATION_REPORT_PKG_PURCHASE, VR_ONE_TIME_ONE_PURCHASE_REPORT } from "../../common/subscriptionConfigConst";
import { handleProductPlanButtonClick } from "../../redux/actions/productAction";
import { CompanyValConfirmationModal } from "../CompanyProfile/CompanyValConfirmationModal/CompanyValConfirmationModal";
import HomePageCardComponent from "../HomePageCard/HomePageCardComponent";
import MediaQuery from "react-responsive";
import pageData from './CompaniesPPCLandingPageData';
import { getObjForBuyCrModal } from '../../sagas/companySaga';
import { toggleBuyCreditReportModal } from "../../redux/actions/companyAction";
const VALUATION_REPORT_PAGE_TYPE = "valuationReportPageType";
const CREDIT_REPORT_PAGE_TYPE = "creditReportPageType"
const VALUATION_REPORT_PPC_PAGE_TYPE = "valuationReportPpcPageType";

const companySeoTitle = "";
const metaData = ""

const basedOnDeviceCardList = (deviceType,pageType) => {
    const deviceForTablet = deviceType === 'tablet'
    if (deviceType === 'mobile') {
        return pageData[pageType].whatsIncludedCard.mobile;
    } else {
        return pageData[pageType].whatsIncludedCard.getDataForOtherDevice(deviceForTablet);
    }
}
const pageTypeByPath = {
    "/solutions/companyvaluation/companies/ppc": VALUATION_REPORT_PPC_PAGE_TYPE,
    "/solutions/companyvaluation/companies": VALUATION_REPORT_PAGE_TYPE,
    "/solutions/companycreditcheck/companies": CREDIT_REPORT_PAGE_TYPE
}
class CompaniesPPCLandingPage extends React.Component {

    constructor() {
        super();
        this.state = {
            companyValuationConfirmationModal: false,
            expandStatus:false,
            pageType: VALUATION_REPORT_PAGE_TYPE
        }
    }

    componentDidMount() {
        const {
            companyQueryData = {},
            companyDetailsList = [],
            match = {}
        } = this.props;
        const headerPropsRendered = this.props.userType === "basic" ? {} : {
            showSingleSearchbar: false,
            customizedLogoType: "companyValPpc",
            showPowerSearchNav: false,
            isPricingMenuShow: false,
            showSolutionsNav: false,
            showAccountLoginNav: false,
            showSignupNav: false,
            showCompanyValuationNav: true,
            showMenuToggleTabMob: false
        };

        this.setState({ pageType: pageTypeByPath[match.path]})
        this.props.dispatchSetHideHeaders({
            backgroundDesktop: "blue",
            backgroundTab: "blue",
            backgroundMob: "blue",
            ...headerPropsRendered
        })
        this.props.dispatchSetHeaderSingleSearchOptions({
            eventPage: this.props.eventPage
        });
        (companyQueryData?.isolatedSearchText === "" || companyDetailsList?.length <= 1) && this.dispatchCallMethod();  

        window.scrollTo({ top: 0 });
        this.props.dispatchSendActivityTracking({
            eventPage: this.props.eventPage,
            eventType: ET_SEARCH_COMPANIES_PPC_LANDING_PAGE_ONLOAD
        })
        this.props.dispatchSetRestrictionForProsperUser(true)
    }

    componentDidUpdate(prevProps) {
        const { dispatchGetValutionReportsList, userInfo } = this.props;
        if(prevProps.userInfo?._id !== userInfo?._id && [VALUATION_REPORT_PAGE_TYPE,VALUATION_REPORT_PPC_PAGE_TYPE].includes(this.state.pageType)) {
            dispatchGetValutionReportsList(userInfo?._id, {}, "")
        }
    }

    dispatchCallMethod = (countIncrease) => {
        const {
            companyQueryData = {},
        } = this.props

        const size = countIncrease ? companyQueryData?.size + 25 : 25;
        const appliedFilter = getDefaultFilterWithSearch(companyQueryData?.isolatedSearchText)
        this.props.dispatchCallSingleSearchApi({
            searchText: companyQueryData?.isolatedSearchText,
            from: 0,
            size,
            company: getSearchQueryDataObj(companyQueryData, appliedFilter, "company"),
            searchTypes: ["company"]
        });
    }

    componentWillUnmount() {
        const headerPropsRendered = {
            showSingleSearchbar: true,
            customizedLogoType: null,
            showPowerSearchNav: true,
            isPricingMenuShow: true,
            showSolutionsNav: true,
            showAccountLoginNav: true,
            showSignupNav: true,
            showCompanyValuationNav: false,
            showMenuToggleTabMob: true
        }
        this.props.dispatchSetHideHeaders({
            backgroundDesktop: "blue",
            backgroundTab: "blue",
            backgroundMob: "blue",
            trackingPage: this.props.eventPage,
            ...headerPropsRendered
        });
        this.props.dispatchSetRestrictionForProsperUser(false)
    }

    handleBackBtnClick = (matchingCompanyName) => {
        this.props.dispatchSendActivityTracking({
            eventPage: this.props.eventPage,
            eventType: ET_SEARCH_COMPANIES_RETURN_BTN_CLICK,
            attribute1: matchingCompanyName
        })
        const prevPath = this.props.history.location?.state?.prevPath;
        let path = prevPath || ([VALUATION_REPORT_PAGE_TYPE,VALUATION_REPORT_PPC_PAGE_TYPE].includes(this.state.pageType) ? "/solutions/companyvaluation" : "/solutions/companycreditcheck");
        if (!prevPath && this.props.userType !== "basic") {
            path = `${path}/ppc`
        }
        this.props.history.push(path);
    }

    onBtnClick = (productId, isRedirectToCalc, { companyId, companyName }) => {
        const { 
            userAllValuationRp = [],
            dispatchHandleProductPlanButtonClick = () => { }, 
        } = this.props
        const existVReportStatus = userAllValuationRp.findIndex(vReportObj => vReportObj.companyId === companyId && vReportObj.remainingDays>0)
        const companyNameforUrl = encodeURIComponent(companyName.toString().replace(/ /g, "-").toLowerCase())
        if (existVReportStatus !== -1) {
            this.props.dispatchToggleCommonMessageModal({
                toggleStatus: true,
                ...EXISTING_COMPANY_VALUATION_RP,
                redirectUrl: `/company/${companyId}/${companyNameforUrl}/valuation`
            })
        } else {
            dispatchHandleProductPlanButtonClick({
                planSelected: productId,
                serviceRequested: SR_VALUATION_REPORT_PKG_PURCHASE,
                href: isRedirectToCalc ? `${window.location.origin}/company/${companyId}/${companyNameforUrl}/valuation` : window.location.href,
                period: "VALUATION_REPORT_PKG",
                companyId: isRedirectToCalc ? companyId : "",
                companyName: isRedirectToCalc ? companyName : "",
                productId,
                isErrorHref: window.location.href,
            },this.props.eventPage);
        }

        this.props.dispatchSendActivityTracking({
            eventPage: this.props.eventPage,
            eventType: ET_VALUATION_REPORT_PACKAGE_BUY_NOW_BTN_CLICK,
            attribute1: productId,
            attribute2: companyId,
            attribute3: companyName
        })
    }

    handleBuyValuationReport = (paramObj) => {
        const {
            companyId = "",
            valuationRpCount = 0,
            companyName = "",
            remainingDays = 0,
            eventType = ET_VALUATION_REPORT_PACKAGE_BUY_NOW_BTN_CLICK
        } = paramObj;
        this.props.dispatchSendActivityTracking({
            eventPage: this.props.eventPage,
            eventType: eventType,
            attribute1: companyId,
            attribute2: companyName
        })
        if (remainingDays > 0) {
            const path = `/company/${companyId}/${encodeURIComponent(companyName?.toString().replace(/ /g, "-").toLowerCase())}/valuation`;
            this.props.history.push(path);
        } else if (valuationRpCount > 0) {
            this.setState({ companyValuationConfirmationModal: true, companyId, companyName });
        } else {
            this.onBtnClick("ai_oneTime1ValuationReportPkg", true, { companyId, companyName });
        }
    }

    handleSeemoreBtn = (countIncrease) => {
        this.dispatchCallMethod(countIncrease);
        this.props.dispatchSendActivityTracking({
            eventPage: this.props.eventPage,
            eventType: ET_SEE_MORE_COMPANIES_BTN_CLICK,
            attribute1: `${countIncrease}_MORE_COMPANIES`
        })
    }
    viewAllBtnClick = (trackingObj) => {
        this.props.dispatchSendActivityTracking({
            eventPage: this.props.eventPage,
            ...trackingObj
        })
        if (this.props.userType === "basic") {
            const redirectPath = [VALUATION_REPORT_PAGE_TYPE,VALUATION_REPORT_PPC_PAGE_TYPE].includes(this.state.pageType) ? "/solutions/companyvaluation/companies" : "/solutions/companycreditcheck/companies";
            this.props.history.push(redirectPath);
        } else {
            this.props.history.push("/solutions/companyvaluation/companies/ppc")
        }
    }

    toggleCompanyValuationConfirmationModal = (status, trackingObj) => {
        this.setState({ companyValuationConfirmationModal: status, companyId: "", companyName: "" });
        this.props.dispatchSendActivityTracking({
            eventPage: this.props.eventPage,
            ...trackingObj
        })
    }

    getBtnLabelAndEventType = (company) =>{
        const {
            userAllValuationRp = [],
            valuationRpCount = 0,
            userInfo = {}
        } = this.props;
        if([VALUATION_REPORT_PAGE_TYPE,VALUATION_REPORT_PPC_PAGE_TYPE].includes(this.state.pageType)){
            const valuationRpObj = userAllValuationRp.filter((valuationObj) => valuationObj.companyId === company.companyId)[0];
            const valuationBtnLabel = valuationRpObj?.remainingDays > 0 ? "open valuation" : valuationRpCount > 0
                ? "get valuation" : "buy valuation £99";
            const eventType = valuationRpObj?.status === "active" && valuationRpObj?.remainingDays > 0 ?
                ET_OPEN_VALUATION_REPORT_BTN_CLICK : valuationRpCount > 0 ?
                    ET_REMAINING_CREDITS_BUY_VALUATION_REPORT_BTN_CLICK : ET_EXPIRED_BUY_VALUATION_REPORT_BTN_CLICK
            return { btnLabel: valuationBtnLabel, eventType, productInfo: {}, valuationRpObj };
        } else {
            let btnLabel = "", eventType = "", productInfo = {};
            if (userInfo?.subscription?.productName === "PomandaPlus"
                && userInfo?.subscription?.active === "yes"){
                    if(userInfo?.subscription?.freeCR > 0){
                        btnLabel = "get free report";
                        productInfo = getObjForBuyCrModal("freeCR").next()?.value;
                    } else if(userInfo?.reportPkgSubscription?.creditCount > 0){
                        btnLabel = "1 credit";
                        productInfo = getObjForBuyCrModal("oneCredit").next()?.value;
                    } else {
                        btnLabel = "credit report £6.99";
                        productInfo = getObjForBuyCrModal("percent30OffReport").next()?.value;
                    }
                    eventType =  (userInfo?.subscription?.freeCR > 0 || userInfo?.reportPkgSubscription?.creditCount > 0) ? ET_CREDIT_REPORT_CLICK : ET_BUY_CREDIT_REPORT_CLICK;
                    
            } else {
                if(userInfo?.reportPkgSubscription?.creditCount > 0){
                    btnLabel = "1 credit";
                    eventType = ET_CREDIT_REPORT_CLICK;
                    productInfo = getObjForBuyCrModal("oneCredit").next()?.value;
                } else {
                    btnLabel = "credit report £9.99";
                    eventType = ET_BUY_CREDIT_REPORT_CLICK;
                    productInfo = getObjForBuyCrModal("oneTimePurchase").next()?.value;
                }
            }
            return { btnLabel, eventType, productInfo };
        }
    }

    handleBuyButton = (params)=>{
        if([VALUATION_REPORT_PAGE_TYPE,VALUATION_REPORT_PPC_PAGE_TYPE].includes(this.state.pageType)){
            this.handleBuyValuationReport(params);
        } else {
            const { productId, productName, companyId, companyName, productPeriod, serviceRequested } = params;
            this.props.dispatchHandleProductPlanButtonClick({
                planSelected: productName,
                serviceRequested,
                href: window.location.href,
                period: productPeriod,
                companyId,
                companyName,
                productId,
                isErrorHref: window.location.href,
            },this.props.eventPage);
        }
    }

    handleDropdownOptionClick = (e,companyInfo) =>{
        if(this.state.pageType === CREDIT_REPORT_PAGE_TYPE){
            e.preventDefault();
            if (companyInfo.status === "Dissolved") {
                this.props.dispatchToggleCommonMessageModal({
                    toggleStatus: true,
                    ...CREDIT_REPORT_OF_DISSOLVED_COMPANY
                })
            } else {
                const companyNameCap = companyInfo.companyName ? companyInfo.companyName.toString().toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ') : "";
                this.props.dispatchToggleBuyCrModal({
                    toggleStatus: true,
                    companyId: companyInfo.companyId,
                    companyName: companyNameCap,
                    regAddress: companyInfo.address,
                    eventPage: this.props.eventPage
                });
            }
        }
    }
    
    render() {
        const {
            staticContext = {},
            history = {},
            companyDetailsList = [],
            companyQueryData = {},
            companyCount = "",
            valuationRpCount = 0,
            screen={}
        } = this.props;
        const { expandStatus } = this.state
        let href, origin;
        if (isBrowser) {
            href = window.location.href;
            origin = window.location.origin;
        } else {
            href = `${staticContext.baseHref}${history.location.pathname}`
            origin = staticContext.baseHref
        }
        const whatsIncludedCardObj = screen?.deviceType ? basedOnDeviceCardList(screen?.deviceType, this.state.pageType) : {};

        return <div id="companies-search-ppc-landing-page">
            <Helmet>
                <meta name="og:url" content={href} />
                <meta property="og:title" content={companySeoTitle} />
                <meta property="og:description" content="Pomanda provides the most complete insight into UK Companies with Pomanda’s estimates" />
                <meta property="og:image" content={`${origin}/assets/images/sharecompany.png`} />
                <meta name="twitter:title" content={companySeoTitle} />
                <meta name="twitter:image" content={`${origin}/assets/images/sharecompany.png`} />
                <meta name="twitter:url" content={href} />
                <title>{companySeoTitle}</title>
                <meta name="description" content={metaData} />
                <link rel="canonical" href={href} />
                {(!REACT_APP_GOOGLE_INDEX || this?.props?.userType !== "basic") && <meta name="robots" content="noindex" />}
            </Helmet>
            <div className="search-companies-ppc-header">
                <MatchingCompaniesHeader
                    componentName="search-companies-ppc-header"
                    matchingCompanyName={companyQueryData.isolatedSearchText}
                    totalMatchingCompanies={this.props.companyCount}
                    onClickBackBtn={this.handleBackBtnClick}
                    searchBarComp={{
                        singleSearchBarId: "search-companies-ppc-header-searchbar",
                        singleSearchBarType: "searchCompaniesPPCInfoHeaderSearchbar",
                        searchButtonPlaceholder: "Search for a business to value",
                        searchTextboxValue: companyQueryData.isolatedSearchText,
                        eventCreatedKeyDown: SEARCH_COMPANIES_PPC_LANDING_PAGE_SEARCHBAR_ENTER,
                        eventTypeKeyDown: ET_PRODUCT_HOMEPAGE_SEARCHBAR_ENTER_CLICK,
                        eventPage: this.props.eventPage,
                        eventTypeSearch: ET_PRODUCT_HOMEPAGE_SINGLE_SEARCH,
                        eventCreatedSearch: SEARCH_COMPANIES_PPC_LANDING_PAGE_SEARCHBAR_SEARCH,
                        eventCreatedOptionClick: SEARCH_COMPANIES_PPC_LANDING_PAGE_SEARCHBAR_OPTION,
                        eventCreatedIconClick: SEARCH_COMPANIES_PPC_LANDING_PAGE_SEARCHBAR_ICON,
                        dropDowBtnShow: false,
                        singleSearchTypes: ["company"],
                        btnLink: this.viewAllBtnClick,
                        userType: this.props.userType,
                        DropdownItemTag: pageData[this.state.pageType].isolatedSearchBarDropdownItemTag,
                        optionOnClick: (e,companyInfo) => this.handleDropdownOptionClick(e,companyInfo),
                        dropdownItemClassName: "mb-0"
                    }}
                />
            </div>
            <div className="ppc-search-companies-container">
                <div className="ppc-search-companies-left-container">
                    <div className="d-flex">
                        <p className="ppc-search-companies-left-container-heading">What’s Included</p>
                        <MediaQuery maxWidth={575.98}>
                        <p className="expand-collapse-btn">
                            {expandStatus ? <i className="fa fa-chevron-up" onClick={()=>this.setState({expandStatus:false})} ></i>
                            :<i className="fa fa-chevron-down"onClick={()=>this.setState({expandStatus:true})} ></i>}
                        </p>
                        </MediaQuery>
                    </div>
                    {((screen?.width <= 575.98 && expandStatus) || screen?.width > 575.98) &&
                        <HomePageCardComponent
                            cardComponentId="ppc_search_what_include_card"
                            cardDataList={whatsIncludedCardObj?.data} 
                            hideLastElement={whatsIncludedCardObj?.hideLastElement}/>}
                </div>
            <div className="ppc-search-companies">
                {companyDetailsList?.length ?
                    companyDetailsList?.map((company, index) => {
                        const { btnLabel, eventType, productInfo, valuationRpObj } = this.getBtnLabelAndEventType(company);
                        return <BuyValuationReportForCompany
                            pageType = {this.props.route?.pageType}
                            company={company}
                            imageUrl={{
                                companyIcon: "/assets/svgs/valuation_report_company_icon.svg",
                                companyIconWidth: "60px",
                                companyIconHeight: "60px",
                                companyAlt: "company_icon"
                            }}
                            btnComp={{
                                btnText: btnLabel,
                                onClick: (companyId, companyName) => {
                                    this.handleBuyButton({
                                        companyId: companyId,
                                        status: valuationRpObj?.status,
                                        remainingDays: valuationRpObj?.remainingDays ? valuationRpObj.remainingDays : 0,
                                        valuationRpCount,
                                        companyName: companyName,
                                        eventType,
                                        btnLabel,
                                        ...productInfo
                                    })
                                }
                            }}
                            toolTipIndex={index}
                            key={index}
                        />
                    }) : null}

                {companyCount > companyQueryData.size &&
                    <div className="companies-page-seemore-btn-wrapper">
                        <button className="isolated-dropdown-btn companies-page-seemore-btn"
                            onClick={() => this.handleSeemoreBtn(25)} >
                            See More
                        </button>
                    </div>
                }
            </div>
            </div>
            <div className="ppc-valuation-companies-footer-wrapper">
                <div className="ppc-valuation-companies-footer">
                    <HomepageFooterV3 />
                </div>
            </div>
            {this.state.companyValuationConfirmationModal && <CompanyValConfirmationModal
                remainingCredits={valuationRpCount}
                yesOnclick={() => {
                    const { email } = this.props.userInfo;
                    this.props.dispatchUpdateValuationReportCreditRequest({
                        companyId: this.state?.companyId,
                        companyName: this.state?.companyName,
                        userEmail: email,
                        eventPage: this.props.eventPage,
                        serviceRequested: VR_ONE_TIME_ONE_PURCHASE_REPORT
                    })
                    this.toggleCompanyValuationConfirmationModal(false, {
                        eventType: ET_VALUATION_REPORT_BUY_CONFIRMATION_YES_CLICK,
                        attribute1: this.state?.companyId
                    })
                    const path = `/company/${this.state?.companyId}/${encodeURIComponent(this.state?.companyName?.toString().replace(/ /g, "-").toLowerCase())}/valuation`;
                    this.props.history.push(path);
                }}
                noOnclick={() => {
                    this.toggleCompanyValuationConfirmationModal(false, {
                        eventType: ET_VALUATION_REPORT_BUY_CONFIRMATION_NO_CLICK,
                        attribute1: this.state?.companyId
                    })
                }}
            />}
        </div>
    }
}

const mapStateToProps = (state) => ({
    companyDetailsList: state.searchReducer.searchCompanyList,
    companyQueryData: state.searchReducer.companyQueryData,
    companyCount: state.searchReducer.searchCompanyCount,
    userAllValuationRp: state.userProfile?.valuationRptList,
    valuationRpCount: state.userProfile.userInfo?.valuationReportPkgSubscription?.valuationRpCount,
    userInfo: state.userProfile.userInfo,
    screen: state.common.screen
})
const mapDispatchToProps = (dispatch) => ({
    dispatchSetHideHeaders: (headerProps) => dispatch(setHideHeader(headerProps)),
    dispatchCallSingleSearchApi: (searchObj) => dispatch(callSingleSearchApiAction(searchObj)),
    dispatchSendActivityTracking: (trackingObj) => dispatch(sendActivityTracking(trackingObj)),
    dispatchSetHeaderSingleSearchOptions: (optionsObj) => dispatch(setHeaderSingleSearchOptions(optionsObj)),
    dispatchSetRestrictionForProsperUser: (isAccessable) => dispatch(setRestrictionForProsperUser(isAccessable)),
    dispatchHandleProductPlanButtonClick: (productObj,eventPage) => dispatch(handleProductPlanButtonClick(productObj,eventPage)),
    dispatchToggleCommonMessageModal: modalInfoObj => dispatch(toggleCommonMsgModal(modalInfoObj)),
    dispatchUpdateValuationReportCreditRequest: paramsObj => dispatch(updateValuationReportCreditRequest(paramsObj)),
    dispatchGetValutionReportsList: (userId, filter, companyId) => dispatch(getValutionReportsList(userId, filter, companyId)),
    dispatchToggleBuyCrModal: (modalDataObj)=>dispatch(toggleBuyCreditReportModal(modalDataObj))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompaniesPPCLandingPage))