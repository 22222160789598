import { GET_COUNTRY_LIST_FAILED, GET_COUNTRY_LIST_REQUEST, GET_COUNTRY_LIST_SUCCESS } from "./actionConstants"

export const getCountryListRequest = () => ({
    type: GET_COUNTRY_LIST_REQUEST,
    payload: { }
})

export const getCountryListSuccess = (countryListObj) => ({
    type: GET_COUNTRY_LIST_SUCCESS,
    payload: {countryListObj}
})

export const getCountryListFailed = (error) => ({
    type: GET_COUNTRY_LIST_FAILED,
    payload: { error}
})