import React from 'react';
import { FormFeedback,Input } from 'reactstrap';

const TextareaInput=props=>{
    const { input: { onChange, name,value }, meta: { error },placeholder,cssClass,rows=4,maxlength="250",pomandae2e} = props
   

    return(
        <React.Fragment>
            <Input 
            pomandae2e ={pomandae2e}
                type="textarea"
                name={name}
                onChange={(e)=>onChange(e.target.value)}
                invalid={error?true:false}
                placeholder={placeholder}
                className={cssClass}
                value={value}
                rows={rows}
                maxLength={maxlength}
                autoComplete="off"
                />
            <FormFeedback>{error}</FormFeedback>
        </React.Fragment>
    )
}

export default TextareaInput;

