import React from 'react';
import { FormFeedback, Input } from 'reactstrap';

const TextInput = props => {
    const { input: { onChange, name, value = '', subTextValue, subText, type = "text", inputStyle = {} }, meta: { error = "" }, placeholder,
        cssClass, maxlength, disabled, pomandae2e, Tag = Input, status, icon, inputMode, rightText = "" } = props
    return (
        <React.Fragment>
            {!subText ? <><Tag
                pomandae2e={pomandae2e}
                name={name}
                type={type}
                onChange={onChange}
                invalid={error ? true : false}
                placeholder={placeholder}
                className={cssClass}
                value={value}
                maxLength={maxlength}
                autoComplete="off"
                style={inputStyle}
                disabled={disabled}
                inputMode={inputMode}
            />
                {subTextValue ? <p className='ml-auto mb-0'>{subTextValue}</p> : null}
                {icon ? (status ? <i className="fa fa-chevron-up" ></i> :
                    <i className="fa fa-chevron-down"></i>) : null}
                {rightText ? <span>{rightText}</span> : null}
                {<FormFeedback>{error}</FormFeedback>}
            </>
                : <>
                    <div className={"feature-details-input-field-wrapper"}>
                        <Tag
                            pomandae2e={pomandae2e}
                            name={name}
                            type={type}
                            onChange={onChange}
                            invalid={error ? true : false}
                            placeholder={placeholder}
                            className={cssClass}
                            value={value}
                            maxLength={maxlength}
                            autoComplete="off"
                            style={inputStyle}
                            disabled={disabled}
                            inputMode={inputMode}
                        />
                        {subTextValue ? <p className='ml-auto mb-0'>{subTextValue}</p> : null}
                    </div>
                    {icon ? (status ? <i className="fa fa-chevron-up" ></i> :
                        <i className="fa fa-chevron-down"></i>) : null}
                    {rightText ? <span>{rightText}</span> : null}
                    {<p className='invalid-input-message m-auto'>{error}</p>}
                </>}
        </React.Fragment>
    )
}

export default TextInput;