import { 
    SET_SCREEN_WIDTH_HEIGHT, 
    SET_HEADER_HIDE, 
    GET_ONLOAD_DATA_API_SUCCESS,
    SET_CONFIRMATION_MODAL,
    TOGGLE_COMMON_MSG_MODAL,
    SET_DELETE_SEARCH_CONFIRMATION, 
    TOGGLE_CONTENT_SHARE_MODAL,
    TOGGLE_INFORMATION_MODAL,
    TOGGLE_COMING_SOON_MODAL,
    TOGGLE_SHARE_URL_MODAL,
    TOGGLE_SORT_MODAL,
    SET_HEADER_SEARCH_TEXT,
    SET_HEADER_SINGLE_SEARCH_OPTIONS,
    SET_STICKY_HEADER_PROPS,
    SET_LOADING,
    TOGGLE_TEMPLATE_LIST_MODAL,
    SET_SYSTEM_INFO,
    SET_CHOOSE_PACKAGE_MODAL_DATA,
    SET_AB_TEST_USER_VARIANT,
    TOGGLE_SEEMORE_MODAL,
    SET_WHITELIST_IP_STATUS,
    WHITE_LABEL_CONFIG_DATA,
    SET_GOOGLE_BOT_STATUS,
    SET_ACCESS_TOKEN_STATUS,
    SET_CURRENCY_DROPDOWN_STATUS
} from "../actions/actionConstants";

const commonMsgModalInfoObj = {
    heading: "",
    subHeading: "",
    btnText: "",
    image:""
}

const commonReducer = (state = { 
    // isShowHeader: true, 
    headerProps: {
        show: true,
        backgroundDesktop: "blue",
        backgroundTab: "blue",
        backgroundMob: "blue",
        animation: true,
        backgroundOnScroll: "blue",
        desktopLogo: "pom_ai_logo_desktop.svg",
        desktopWebpLogo: "pom_ai_logo_desktop.svg",
        isPricingMenuShow: true,
        isSingleSearchbarSticky: false,
        showSingleSearchbar: false,
        customizedLogoType: null,
        showPowerSearchNav: true,
        showValuationsNav: true,
        showBusinessPlansNav: true,
        showPricingNav: true,
        showSolutionsNav: true,
        showAccountLoginNav: true,
        showSignupNav: true,
        showCompanyValuationNav: false,
        showMenuToggleTabMob: true,
        showBusinessPlanHeading: false
    },
    showConfirmationModal: false, 
    commonMsgModalInfoObj,
    monthlyCsvRowDownloadCount: 1000,
    showDeleteSearchesConfirmation: false,
    isComingSoonModalOpen: false,
    isInformationModalOpen: false,
    shareUrlObj: {},
    contentShareModal: {}, 
    isSortModalOpen: false,
    headerSearchText: "",
    headerSingleSearchOptions: {
        tryPowerSearch: false,
        filters: false,
        eventPage: ""
    },
    stickyHeaderProps: {
        prevPosition: 0,
        isHide: false
    },
    loading : {
        status: false
    },
    showTemplateListModal: false,
    systemInfo: {},
    userVariant: {},
    whitelistIpStatus: false,
    accessTokenStatus: "",
    showCurrencyDropdown: true
}, action) => {
    switch (action.type) {
        case SET_SCREEN_WIDTH_HEIGHT:
            return { ...state, screen: action.payload.screenObject };
        case SET_HEADER_HIDE:
            return { ...state, headerProps: {...state.headerProps, ...action.payload.headerProps} };
        case GET_ONLOAD_DATA_API_SUCCESS:
            return { ...state, 
                actTrkToken: action.payload.actTrackFrontToken, 
                monthlyCsvRowDownloadCount: action.payload.monthlyCsvRowDownloadCount };
        case TOGGLE_COMMON_MSG_MODAL:
            return { ...state, commonMsgModalInfoObj:{ ...action.payload.modalInfoObj }}
        case SET_CONFIRMATION_MODAL:
            return {...state, showConfirmationModal:action.payload.status}
        case SET_DELETE_SEARCH_CONFIRMATION:
            return {...state, showDeleteSearchesConfirmation:action.payload.status}
        case TOGGLE_CONTENT_SHARE_MODAL:
            return {
                ...state, contentShareModal: {
                    openStatus: action.payload.contentShareModalObj.status,
                    type: action.payload.contentShareModalObj.type,
                    name: action.payload.contentShareModalObj.name
                }
            };
        case TOGGLE_COMING_SOON_MODAL:
                return { ...state, isComingSoonModalOpen: action.payload.toggleStatus };
        case TOGGLE_INFORMATION_MODAL:
            return { ...state, isInformationModalOpen: action.payload.toggleStatus };
        case TOGGLE_SHARE_URL_MODAL:
            return { ...state, shareUrlObj: action.payload.shareUrlModalObj };
        case TOGGLE_SORT_MODAL:
            return { ...state, isSortModalOpen: action.payload.toggleStatus };
        case SET_HEADER_SEARCH_TEXT:
            return { ...state, headerSearchText: action.payload.searchText };
        case SET_HEADER_SINGLE_SEARCH_OPTIONS:
            return { ...state, headerSingleSearchOptions: {...state.headerSingleSearchOptions,...action.payload.optionObj} };
        case SET_STICKY_HEADER_PROPS:
            return { ...state, stickyHeaderProps: { ...state.stickyHeaderProps, ...action.payload.headerProps }};
        case SET_LOADING:
            return { ...state, loading: action.payload.loadingObj };
        case TOGGLE_TEMPLATE_LIST_MODAL:
            return { ...state, showTemplateListModal: action.payload.toggleStatus };
        case SET_SYSTEM_INFO:
            return { ...state, systemInfo: {...state.systemInfo, ...action.payload.systemObj} };
        case SET_CHOOSE_PACKAGE_MODAL_DATA:
            return {
                ...state, choosePackageModalData: {
                    ...state.choosePackageModalData, ...action.payload.modalData,
                    showCloseBtn: action.payload.modalData.showCloseBtn === undefined ? true : action.payload.modalData.showCloseBtn
                }
            };
        case SET_AB_TEST_USER_VARIANT:
            return { ...state, userVariant: action.payload };
        case TOGGLE_SEEMORE_MODAL:{
                return { ...state, seeMoreModalObj: { ...action.payload.seeMoreModalObj }};
            }
        case SET_WHITELIST_IP_STATUS:{
            return { ...state, whitelistIpStatus: action.payload.status};
        }
        case SET_GOOGLE_BOT_STATUS:{
            return { ...state, isUserAsGooglBot: action.payload.status};
        }
        case WHITE_LABEL_CONFIG_DATA: {
            return { ...state, whiteLabelConfig: { ...action.payload.whiteLabelConfig } }
        }
        case SET_ACCESS_TOKEN_STATUS: {
            return { ...state, accessTokenStatus: action.payload.accessTokenStatus }
        }
        case SET_CURRENCY_DROPDOWN_STATUS: {
            return { ...state, showCurrencyDropdown: action.payload.status }
        }
        default:
            return state;
    }
}

export default commonReducer;