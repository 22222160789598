import {
    COLLAPSIBLE_MENU_TOGGLE, TOGGLE_MENU_MODAL, UPDATE_ACCOUNT_MENU
} from '../actions/actionConstants';

import {
    ET_PROFILE_TAB_CLICK, 
    ET_CONTACT_US_TAB_CLICK,
    ET_LEGAL_DOCS_TAB_CLICK,
    ET_SUBSCRIPTION_TAB_CLICK,
    ET_ORDER_HISTORY_TAB_CLICK,
    ET_SIGNOUT_TAB_CLICK,
    ET_VALUATION_REPORTS_TAB_CLICK,
    ET_BUSINESS_PLANS_TAB_CLICK
} from '../../common/trackingConstants';

const companyMenuItems = [
    { "name": "Company Information", "sectionId": "companyInformation" },
    { "name": "Valuation", "sectionId": "valuation" },
    { "name": "Health Check", "sectionId": "healthCheck", "isNew": false },
    { "name": "Credit Report", "sectionId": "creditReport" },
    { "name": "Ownership", "sectionId": "ownership" },
    // { "name": "Competitor Analysis", "sectionId": "competitor-analysis"},
    { "name": "Directors", "sectionId": "directors" },
    { "name": "Financials", "sectionId": "financials" },
    { "name": "Company Details", "sectionId": "companyDetails" },
    { "name": "Companies House Filings", "sectionId": "companiesHouse" }
]

const accountMenuItems = [
    { name: "Profile", link: "profile", trackingConstant: ET_PROFILE_TAB_CLICK },
    { name: "Contact Us", link: "contact-us", trackingConstant: ET_CONTACT_US_TAB_CLICK },
    { name: "Valuation Reports", link: "valuation-reports", trackingConstant: ET_VALUATION_REPORTS_TAB_CLICK },
    { name: "Business Plans", link: "business-plans", trackingConstant: ET_BUSINESS_PLANS_TAB_CLICK },
    { name: "Sign Out", link: "#", trackingConstant: ET_SIGNOUT_TAB_CLICK },
]

const INITIAL_STATE = {
    companyMenuItems, accountMenuItems, isMenuModalOpen: false,
    dataForShareModal: {}
}

const menuReducer = (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
        case COLLAPSIBLE_MENU_TOGGLE:
            return { ...state, toggleCollapseMenuStatus: action.payload };
        case TOGGLE_MENU_MODAL:
            return { ...state, isMenuModalOpen: action.payload.toggleStatus };
        case UPDATE_ACCOUNT_MENU:
            return { ...state, accountMenuItems: action.payload.accountMenuItems };
        default:
            return state;
    }
}

export default menuReducer;
