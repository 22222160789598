import React, { useEffect } from 'react';
import './LoadingComponent.css'

const LoadingComponent = (props) => {
    const timeout = props.timeout || 3000
    useEffect(() => {
        props.timeout !== null && setTimeout(() => {
            props.dispatchSetLoading({
                status: false
            })
        }, timeout);
    }, []);

    return (
        <div className="loading-section">
            <img src="/assets/images/spinner_dots_black.gif" alt="spinner" className="" />
        </div>
    )
}

export default LoadingComponent;