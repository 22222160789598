import React, { memo } from 'react';
import './IndustryChartAndCompany.css';
import IndustryChart from './IndustryChart';
import NewEntrantLeaversComparison from '../../NewEntrantLeaversComparison/NewEntrantLeaversComparison';
import { getCompanyDataList } from '../CommonFunction';

const IndustryChartAndCompany = (props) => {
    const {
        sendActivityTracking = () => {},
        handleSeeAllButtonClick = () => {},
        largestTurnoverArray = [],
        oldestIncorporatedArray = [],
        highestGrowthArray = [],
        ageTagArray = [],
        sizeTagArray = [],
        growthTagArray = [],
        entityName = "",
        newEntrantsVSLeaversArray = [],
        seeMoreBtnPositionInPieChartSec = "",
        urlForSeeMoreInPieChartSizeSec="",
        urlForSeeMoreInPieChartAgeSec="",
        urlForSeeMoreInPieChartCompaniesSec="",
        pieChartHeadings = ['Companies by Size','Companies by Age','Fastest Growing Companies'],
        showSeeMoreButton = true
        } = props;

    const allCompanyDataList = [getCompanyDataList(largestTurnoverArray), getCompanyDataList(oldestIncorporatedArray),
        getCompanyDataList(highestGrowthArray)];
    const colorList = ['#FDBC4B', '#4557A9', '#FE6969', '#6AE29B', '#1ED6FF'];
    let chartDataSetsArray = [];
    const getSingleChartData = (param) => {
        let chartArray = []
        let sum = 0
        let percentage;
        if (param[0] && param[0].SizeTag) {
            param.forEach((data) => {
                sum = Number(data.SizeCount) + sum;
            })
            param.map((data, i) => {
                percentage = Math.round((Number(data.SizeCount) * 100 / sum));
                chartArray.push({ tag: data.SizeTag, percentage });
            })
        }
        if (param[0] && param[0].AgeTag) {
            param.forEach((data) => {
                sum = Number(data.AgeCount) + sum;
            })
            param.forEach((data, i) => {
                percentage = Math.round((Number(data.AgeCount) * 100 / sum));
                chartArray.push({ tag: data.AgeTag, percentage });
            })
        }
        if (param[0] && param[0].GrowthTag) {
            param.forEach((data) => {
                sum = Number(data.GrowthCount) + sum;
            })
            param.forEach((data, i) => {
                percentage = Math.round((Number(data.GrowthCount) * 100 / sum));
                chartArray.push({ tag: data.GrowthTag, percentage });
            })
        }
        chartArray = chartArray.sort((a, b) => b.percentage - a.percentage)
        const chartData = {
            datasets: [{
                data: chartArray.map(data => data.percentage),
                backgroundColor: colorList,
                borderWidth: 0,
            }]
        };
        chartDataSetsArray.push(chartData);
        return chartArray;
    }
    const allChartData = [getSingleChartData(sizeTagArray), getSingleChartData(ageTagArray), getSingleChartData(growthTagArray)];
    return (
        <>
            <div className="industry-chart-display-style" >
                <div className="industry-chart-mr-style industry-chart-outer-style">
                    <IndustryChart
                        heading={pieChartHeadings[0]}
                        singleChartData={chartDataSetsArray[0]}
                        chartData={allChartData[0]}
                        companyHeadings={{ companyNameHeading: `Largest ${entityName} Companies`, valueHeading: "Turnover" }}
                        comapnyDataList={allCompanyDataList[0]}
                        keyProps={`singleChartData-first`}
                        handleSeeAllButtonClick={props.handleSeeAllButtonClick}
                        sendActivityTracking={sendActivityTracking} 
                        seeMoreBtnPositionInPieChartSec={seeMoreBtnPositionInPieChartSec}
                        urlForSeeMoreInPieChartSec={urlForSeeMoreInPieChartSizeSec}
                        showSeeMoreButton={showSeeMoreButton}/>
                </div>
                <div className="industry-chart-mr-style industry-chart-outer-style">
                    <IndustryChart
                        heading={pieChartHeadings[1]}
                        singleChartData={chartDataSetsArray[1]}
                        chartData={allChartData[1]}
                        companyHeadings={{ companyNameHeading: `Oldest ${entityName} Companies`, valueHeading: "Established" }}
                        comapnyDataList={allCompanyDataList[1]}
                        keyProps={`singleChartData-second`}
                        handleSeeAllButtonClick={props.handleSeeAllButtonClick}
                        sendActivityTracking={sendActivityTracking} 
                        seeMoreBtnPositionInPieChartSec={seeMoreBtnPositionInPieChartSec}
                        urlForSeeMoreInPieChartSec={urlForSeeMoreInPieChartAgeSec}
                        showSeeMoreButton={showSeeMoreButton}/>
                </div>
                <div className="industry-chart-mr-style industry-chart-outer-style">
                    <IndustryChart
                        heading={pieChartHeadings[2]}
                        singleChartData={chartDataSetsArray[2]}
                        chartData={allChartData[2]}
                        companyHeadings={{ companyNameHeading: `Fastest Growing ${entityName} Companies`, valueHeading: "3yr CAGR" }}
                        comapnyDataList={allCompanyDataList[2]}
                        keyProps={`singleChartData-third`}
                        handleSeeAllButtonClick={handleSeeAllButtonClick} 
                        sendActivityTracking={sendActivityTracking} 
                        seeMoreBtnPositionInPieChartSec={seeMoreBtnPositionInPieChartSec}
                        urlForSeeMoreInPieChartSec={urlForSeeMoreInPieChartCompaniesSec}
                        showSeeMoreButton={showSeeMoreButton}/>
                </div>
                    {props.showComparisonChart ? <div className="industry-chart-mr-style industry-chart-outer-style industry-comparison-chart-width">
                        <NewEntrantLeaversComparison 
                            newEntrantsVSLeaversArray={newEntrantsVSLeaversArray} 
                            heading={`${entityName} Industry Competitive Environment -`}
                            profilePage="industry"/>
                    </div> : null}
            </div>
        </>
    );
}

export default memo(IndustryChartAndCompany);