import React, { memo } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import './MessageModal.css';
import { connect } from 'react-redux';
import { toggleCommonMsgModal } from '../../../redux/actions/commonAction';
import { setIndustryMixUpdateModal, toggleValuationCalculatorModal } from '../../../redux/actions/companyAction';
import { withRouter } from 'react-router';
import industryMixUpdateModalConstants from '../../../common/industryMixUpdateModalConstants';

const MessageModal = (props) => {
    const toggle = (closeStatus) => {
        props.dispatchToggleModal({
            toggleStatus: false,
            showCloseBtn : false,
            heading: "",
            subHeading: "",
            btnText: "",
            openIndustryMixModal : false
        })
        !closeStatus && props.redirectUrl && props.history.push(props.redirectUrl)
        props.dispatchEmailModalMsg({ leaveEmailModalSatus: false })
        !closeStatus && props.openIndustryMixModal && props.dispatchSetIndustryMixUpdateModal(true, industryMixUpdateModalConstants.INDUSTRY_CLICK_IN_ABOUT_SECTION);
    }

    const {
        heading = "",
        subHeading = "",
        toggleStatus = false,
        btnText = "",
        backdropClassName = "common-message-modal-backdrop",
        modalClassName = "common-message-modal",
        showCloseBtn = false
        // image = "",
        // backdropBoolean=false
    } = props;
    return (
        <Modal isOpen={toggleStatus} id="common_message_modal"
            backdropClassName={backdropClassName}
            modalClassName={modalClassName}
            // toggle={image ? toggle:null}
            toggle={null}
            fade={false}
            centered={true}
            // backdrop={backdropBoolean}
            >
        {(props?.redirectUrl || showCloseBtn) && 
                <ModalHeader toggle={() => toggle(true)} className="pb-2 close-modal-btn" />
        }
            <ModalBody>
                {/* Don't Remove */}
                {/* {image && <picture>
                    <source srcSet={image.webpIcon} />
                    <img src={image.pngIcon} />
                </picture>} */}
                <p className="common-message-modal-heading">{heading}</p>
                <p className="common-message-modal-sub-heading" dangerouslySetInnerHTML={{__html : subHeading}}/>
                <div className="common-message-modal-btn-wrapper">
                    {/* {!image &&  */}
                    <button type="button"
                        className="text-uppercase search-header-button yellow-border-button"
                        onClick={() => toggle()} pomandae2e={btnText}>
                        {btnText}
                    </button>
                    {/* } */}
                </div>
            </ModalBody>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    heading: state.common.commonMsgModalInfoObj.heading,
    subHeading: state.common.commonMsgModalInfoObj.subHeading,
    btnText: state.common.commonMsgModalInfoObj.btnText,
    toggleStatus: state.common.commonMsgModalInfoObj.toggleStatus,
    valuationRp: state.common.commonMsgModalInfoObj.valuationRp,
    redirectUrl: state.common.commonMsgModalInfoObj.redirectUrl,
    // image: state.common.commonMsgModalInfoObj.image,
    // backdropBoolean: state.common.commonMsgModalInfoObj.backdropBoolean,
    modalClassName: state.common.commonMsgModalInfoObj.modalClassName,
    showCloseBtn: state.common.commonMsgModalInfoObj.showCloseBtn,
    openIndustryMixModal: state.common.commonMsgModalInfoObj.openIndustryMixModal
});

const mapDispatchToProps = (dispatch) => ({
    dispatchToggleModal: modalInfoObj => dispatch(toggleCommonMsgModal(modalInfoObj)),
    dispatchEmailModalMsg : modalInfoObj => dispatch(toggleValuationCalculatorModal(modalInfoObj)),
    dispatchSetIndustryMixUpdateModal: (toggleStatus, modalTextsObj) => dispatch(setIndustryMixUpdateModal(toggleStatus, modalTextsObj)),
})
export default withRouter(memo(connect(mapStateToProps, mapDispatchToProps)(MessageModal)));