
export const isBrowser = typeof window !== 'undefined'
import createStore from '../redux/store/createStore';
import rootSaga from '../sagas/rootSaga'
let envVar = {}
// if (isBrowser) {
//     const initialState = window.INITIAL_STATE;
//     envVar = initialState.envVarLoad
// }else{

const store = createStore(isBrowser);
store.sagaTask(rootSaga)
let storeData = store.getState()
envVar = storeData.envVarLoad
// }

export const RESTAPI_URL = envVar.RESTAPI_URL;
export const ACTIVITY_TRACKING_FRONTEND_URL = envVar.ACTIVITY_TRACKING_FRONTEND_URL;
export const STRIPE_PUBLIC_KEY = envVar.STRIPE_PUBLIC_KEY;
export const COMPANY_DIRECTOR_SEARCH_LIMIT_FOR_ANONYMOUS = envVar.COMPANY_DIRECTOR_SEARCH_LIMIT_FOR_ANONYMOUS;
export const REACT_APP_GOOGLE_INDEX = envVar.REACT_APP_GOOGLE_INDEX 
export const GA_AD_SENSE_ID = envVar.GA_AD_SENSE_ID;
export const GA_TRACKING_ID = envVar.GA_TRACKING_ID;
export const GA_PS_BTN_TAG = envVar.GA_PS_BTN_TAG;
export const GA_SIGNUP_BTN_TAG = envVar.GA_SIGNUP_BTN_TAG;
export const GA_CONTACT_US_BTN_TAG = envVar.GA_CONTACT_US_BTN_TAG;
export const GA_7DAY_TRAIL_BTN_TAG = envVar.GA_7DAY_TRAIL_BTN_TAG;
export const GA_CR_PAYMENT_BTN_TAG = envVar.GA_CR_PAYMENT_BTN_TAG;
export const GA_SIGNUP_HEADER_BTN_TAG = envVar.GA_SIGNUP_HEADER_BTN_TAG;
export const GA_HP_FIND_YOUR_COMP_BTN = envVar.GA_HP_FIND_YOUR_COMP_BTN;
export const GA_HP_GENERATE_LEAD_BTN = envVar.GA_HP_GENERATE_LEAD_BTN;
export const GA_HP_SEARCHBAR_COMPANY = envVar.GA_HP_SEARCHBAR_COMPANY;
export const GA_HP_SEARCHBAR_DIRECTOR = envVar.GA_HP_SEARCHBAR_DIRECTOR;
export const GA_HP_SIGNUP_NOW_BTN = envVar.GA_HP_SIGNUP_NOW_BTN;
export const GA_HP_START_RESEARCHING_BTN = envVar.GA_HP_START_RESEARCHING_BTN;
export const GA_VALUATION_REPORT_PAYMENT_BTN_TAG = envVar.GA_VALUATION_REPORT_PAYMENT_BTN_TAG;
export const GA_BUSINESS_PLAN_PAYMENT_BTN_TAG = envVar.GA_BUSINESS_PLAN_PAYMENT_BTN_TAG;
export const REACT_APP_ADS_ENABLE = envVar.REACT_APP_ADS_ENABLE
export const USER_IP_LOG = envVar.USER_IP_LOG ? envVar.USER_IP_LOG : false;
export const GOOGLE_MAP_KEY = envVar.GOOGLE_MAP_KEY;
export const GOOGLE_CLIENT_ID = envVar.GOOGLE_CLIENT_ID;
export const LINKEDIN_CLIENT_ID = envVar.LINKEDIN_CLIENT_ID;
export const LINKEDIN_OAUTH_URL = envVar.LINKEDIN_OAUTH_URL
export const REACT_APP_ADSENSE_970_90 = envVar.REACT_APP_ADSENSE_970_90;
export const REACT_APP_ADSENSE_728_90 = envVar.REACT_APP_ADSENSE_728_90;
export const REACT_APP_ADSENSE_300_50 = envVar.REACT_APP_ADSENSE_300_50;
export const REACT_APP_ADSENSE_CLIENT_ID = envVar.REACT_APP_ADSENSE_CLIENT_ID;
export const REACT_APP_PROSPER_SIGNOUT_REDIRCT_URL = envVar.REACT_APP_PROSPER_SIGNOUT_REDIRCT_URL;
export const BUSINESS_VALUATION_YOUTUBE_VIDEO_ID = envVar.BUSINESS_VALUATION_YOUTUBE_VIDEO_ID;
export const POWER_SEARCH_YOUTUBE_VIDEO_ID = envVar.POWER_SEARCH_YOUTUBE_VIDEO_ID;
export const MICROSOFT_CLARITY_ID = envVar.MICROSOFT_CLARITY_ID;   

export const EXPIRE_DAILY_LIMIT_MSG = "You've Reached Your Daily Limit";
export const COUNTRY_CODE = 'gb';
export const CONTINUE_POMANDA_USE = "You've Reached Your Daily Limit. Unlock the full power of Pomanda and explore 12.6m UK businesses.";
export const CHOOSE_PLAN = "Choose a Plan";
export const UNLOCK_FULL_POWER = "Unlock the full power of Pomanda and explore 12.6m UK businesses.";
export const UPGRADE_PLAN = "Upgrade your plan";
export const COOKIE_NOTICE = "We use cookies and similar technologies on our site to improve your experience. By using our site, you agree to our ";
export const FACEBOOK_PIXEL_ID = 1842275045787447;
export const LINKEDIN_INSIGHT_ID = 2126993;
export const LINKEDIN_SIGNUP = 2360628;
export const LINKEDIN_SUBSCRIPTION = 2360620;
export const INDUSTRY_ADD_LIMIT = 5;
export const RESET_INDUSTRY_MIX_LOADING_MSG = "Resetting the Industry Breakdown to the default selections derived from Companies House.";
export const SAVE_INDUSTRY_MIX_LOADING_MSG = "Recalculating Valuations and Pomanda Estimates";
export const TRACKING_RESTAPI_URL = isBrowser && window.location.origin;
export const PROSPER_USER_TYPE = "PROSPER";
export const VALUATION_ACCESS_LOADING_MSG = "Checking User Access....";
export const COMPETITOR_ANALYSIS_LOADING_MSG = "Preparing Competitor Analysis...";
export const MAIL_RECIPIENT = 'CONTACT_US_EMAIL_ADDRESS'
export const MAX_CSV_DOWNLOAD_LIMIT = 5000;

