import React from 'react';
import { Input, FormFeedback } from 'reactstrap';

const EmailInput = props => {
    const { input: { onChange, onFocus, name, value }, meta: { error }, cssClass, disabled, showPlaceholder,placeholder , pomandae2e  } = props
        return (
        <div>
            <Input type="text"
                name={name}
                pomandae2e={pomandae2e}
                onChange={onChange}
                placeholder={showPlaceholder?placeholder:""}
                invalid={error?true:false}
                value={value}
                className={cssClass}
                autoComplete="off"
                disabled={disabled}
                onFocus={(e)=>onFocus(e)}/>
            <FormFeedback>{error}</FormFeedback>
        </div>
    )
}

export default EmailInput;

