import React, { Component } from 'react'
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { withRouter } from 'react-router';
import {
    callSingleSearchApiAction, resetAllSearchParams,
    setCompanySearchAppliedFilter, setCompanySearchParams
} from '../../redux/actions/searchAction';
import IsolatedSearchDropdown from './IsolatedSearchDropdown';
import IsolatedSearchModalBar from './IsolatedSearchModalBar';
import './IsolatedSearchBar.css'
import { isBrowser } from '../config';
import { getDefaultFilterWithSearch, getSearchQueryDataObj } from '../SearchCommonFunctions';
class IsolatedSearchBar extends Component {
    constructor() {
        super();
        this.state = {
            searchText: "",
            isModalOpen: false,
            modalInputId: "",
            modalPlaceholder: "",
            modalEventCreated: "",
            modalEventType: "",
            modalOptionEventCreated: "",
            renderedSearchtext: null,
            typingTimeout: 0
        }
    }
    componentDidUpdate() {
        this.props &&
            this.state.renderedSearchtext !== this.props.searchTextboxValue &&
            this.setState({ renderedSearchtext: this.props.searchTextboxValue })
    }
    onKeyPressed = (e, eventCreatedKeyDown, eventTypeKeyDown) => {
        const {
            eventPage = ""
        } = this.props;
        if (e.keyCode === 13) {
            e.preventDefault();
            this.handleSearchIconClick({
                eventType : eventTypeKeyDown,
                attribute1 : eventCreatedKeyDown,
                eventPage
            })
        }
    }
    resetSearchParams = (searchParam) => {
        this.props.dispatchResetAllSearchParams(searchParam);
    }
    callSearchApi = (target, trackingObj) => {
        const {
            singleSearchTypes = [],
            isEstimateOn = false,
            companyQueryData={}
        } = this.props;
        const searchText = target?.value
        const value = Math.round((window.outerHeight - 160) / 25)
        const size = value < 25 ? 25 : value;
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }
        const appliedFilter = getDefaultFilterWithSearch(searchText)
        let newCompanyQueryData = JSON.parse(JSON.stringify(companyQueryData))
        newCompanyQueryData.isolatedSearchText = searchText
        this.props.dispatchSetCompanySearchParams(newCompanyQueryData)
        this.setState({
            typingTimeout: setTimeout(() => {
                this.props.dispatchCallSingleSearchApi({
                    searchText,
                    from: 0,
                    size,                   
                    company: getSearchQueryDataObj(companyQueryData,appliedFilter,"company"),
                    isEstimateOn,
                    searchTypes: singleSearchTypes,
                    trackingObj
                });

            }, 1000)
        });
        this.resetSearchParams({
            searchText
        });
        let searchQueryData = {};
        searchQueryData.scrollPosition = { scroll: 0, scrollRetain: false };
        searchQueryData.size = size;
        searchQueryData.searchText = searchText
        this.resetSearchParams(searchQueryData);
    }
    handleSearchIconClick = (trackingObj) => {
        const {
            searchTextboxValue = "",
            companyQueryData = {}
        } = this.props;

        this.props.btnLink({
            ...trackingObj
        })
        if (searchTextboxValue === "" || companyQueryData.isolatedSearchText === "") {         
            let newCompanyQueryData = JSON.parse(JSON.stringify(companyQueryData));
            newCompanyQueryData.searchText = "";
            this.props.dispatchSetCompanySearchParams(newCompanyQueryData)
            companyQueryData.sortField === "score" && (companyQueryData.sortField = "companyName");
        
            this.callSearchApi({ value: "" }, trackingObj);
        }
    }
    handleChangeSearch = (event, eventType, eventCreated, eventPage) => {
        const {
            setSearchTextboxValue = () => { },
            inputId = "",
            formId = "",
        } = this.props;
        setSearchTextboxValue(event.target.value);
        this.callSearchApi(event.target, {
            eventPage,
            eventType,
            eventCreated,
            searchText: event.target.value
        });
        let menuElement = document.getElementById(`${inputId}_menu`);
        let inputElement = document.getElementById(inputId);
        let dropdownElement = document.getElementById(formId);
        menuElement && menuElement.classList.add("show");
        dropdownElement && dropdownElement.classList.add("show");
        inputElement.setAttribute("aria-expanded", "true");
    }
    toggleSearchModal = (status, inputId, placeholder, eventCreated, eventType, eventCreatedOption) => {
        const inputElement = document.getElementById(inputId);
        status && inputElement && inputElement.blur();
        this.setState({
            isModalOpen: status,
            modalInputId: `${inputId}_modal`,
            modalPlaceholder: placeholder,
            modalEventCreated: eventCreated,
            modalEventType: eventType,
            modalOptionEventCreated: eventCreatedOption
        }, () => {
            setTimeout(() => {
                document.getElementById(`${inputId}_modal`) && document.getElementById(`${inputId}_modal`).focus();
            }, 1);
        })
    }

    handleIsolatedModalBtnLink = () => {
        this.setState({isModalOpen: false})
        this.props.btnLink();
    }

    render() {
        const {
            formId = "",
            searchButtonPlaceholder = "Search for a company...",
            searchTextboxValue = "",
            inputId = "",
            eventCreatedKeyDown = "",
            eventTypeKeyDown = "",
            eventTypeSearch = "",
            eventCreatedSearch = "",
            eventPage = "",
            eventCreatedOptionClick = "",
            eventCreatedIconClick = "",
            companyList = [],
            companyCount = 0,
            headerSingleSearchOptions = {},
            singleSearchTypes = ["company"],
            isShow = false,
            btnLink,
            dropDowBtnShow,
            userType="",
            companyQueryData,
            disableSingleSearchBarDropDown = false,
            DropdownItemTag="",
            optionOnClick = () => { },
            dropdownItemClassName = "",
            inputBeforeIcon = false,
            showSearchText = false
        } = this.props;

        const newCompanyList = searchTextboxValue ? companyList : [];
        const pathname = isBrowser && window.location.pathname
        return (
            <div className='isolated-searchbar-info-wrapper'>
                <div className="isolated-searchbar-section">
                    <div id="single_search_bar_cmp">
                        <form autoComplete="off">
                            <div className="dropdown isolated-search-input-container" id={formId}>
                               {inputBeforeIcon ? <picture>
                                    <source srcSet={inputBeforeIcon.webpImageUrl} />
                                    <img alt={inputBeforeIcon.alt} width={inputBeforeIcon.width} height={inputBeforeIcon.height} src={inputBeforeIcon.imageUrl} className='layload' />
                                </picture> : ''}
                                <MediaQuery minWidth={992}>
                                    <input id={inputId} className="search-button w-100 h-100 dropdown-toggle"
                                        type="text" placeholder={searchButtonPlaceholder}
                                        value={searchTextboxValue ? searchTextboxValue : companyQueryData.isolatedSearchText } tabIndex="0" data-flip="false"
                                        onKeyDown={(e) => this.onKeyPressed(e, eventCreatedKeyDown, eventTypeKeyDown)}
                                        onChange={(e) => this.handleChangeSearch(e, eventTypeSearch, eventCreatedSearch, eventPage)}
                                        data-toggle="dropdown" autoComplete="off" autoCorrect="off" spellCheck="off"
                                        pomandae2e={searchButtonPlaceholder}
                                    />

                                </MediaQuery>
                                <MediaQuery maxWidth={991.98}>
                                    <input id={inputId} className="search-button w-100 h-100"
                                        pomandae2e={searchButtonPlaceholder}
                                        type="text" placeholder={searchButtonPlaceholder}
                                        value={searchTextboxValue ? searchTextboxValue : companyQueryData.isolatedSearchText} tabIndex="0" data-flip="false"
                                        onKeyDown={(e) => this.onKeyPressed(e, eventTypeKeyDown, eventCreatedKeyDown)}
                                        onFocus={() => !disableSingleSearchBarDropDown && this.toggleSearchModal(true, inputId, searchButtonPlaceholder, eventCreatedSearch, eventTypeSearch, eventCreatedOptionClick)}
                                        onChange={disableSingleSearchBarDropDown ? (e) => this.handleChangeSearch(e, eventTypeSearch, eventCreatedSearch, eventPage) : null}
                                        data-toggle="dropdown" autoComplete="off" autoCorrect="off" spellCheck="off" readOnly={!disableSingleSearchBarDropDown}
                                    />
                                </MediaQuery>
                                <button className='search-bar-icon' onClick={() => this.handleSearchIconClick({
                                    eventPage : eventPage,
                                    eventType : eventCreatedIconClick,
                                    attribute1 : eventCreatedSearch                                   
                                })}>
                                    <i className="fa fa-search" aria-hidden="true" pomandae2e={eventCreatedIconClick}></i>
                                    {showSearchText ? 'search' : ''}
                                </button>
                                {!disableSingleSearchBarDropDown && <MediaQuery minWidth={992}>
                                    <div id={`${inputId}_menu`} className={`dropdown-menu ${isShow && searchTextboxValue ? "show" : ""}`} aria-labelledby={formId}>
                                        <IsolatedSearchDropdown
                                            companyList={newCompanyList}
                                            companyCount={companyCount}
                                            searchTextboxValue={searchTextboxValue ? searchTextboxValue : companyQueryData.isolatedSearchText}
                                            btnLink={btnLink}
                                            dropDowBtnShow={dropDowBtnShow}
                                            userType={userType}
                                            eventPage={eventPage}
                                            prevPath ={pathname}
                                            eventCreatedOptionClick={eventCreatedOptionClick}
                                            DropdownItemTag={DropdownItemTag}
                                            optionOnClick={optionOnClick}
                                            dropdownItemClassName={dropdownItemClassName}
                                        />
                                    </div>
                                </MediaQuery>}
                            </div>
                            <MediaQuery maxWidth={991.98}>
                                {this.state.isModalOpen && <IsolatedSearchModalBar
                                    isOpen={this.state.isModalOpen}
                                    handleModalBackLink={this.toggleSearchModal}
                                    inputPlaceholder={this.state.modalPlaceholder}
                                    handleOnChange={this.handleChangeSearch}
                                    inputSearchValue={searchTextboxValue  ? searchTextboxValue : companyQueryData.isolatedSearchTexte}
                                    handleSearchClick={this.handleSearchIconClick}
                                    inputId={this.state.modalInputId}
                                    eventCreated={this.state.modalEventCreated}
                                    eventType={this.state.modalEventType}
                                    eventPage={eventPage}
                                    eventCreatedOption={this.state.modalOptionEventCreated}
                                    eventCreatedIconClick={eventCreatedIconClick}
                                    eventCreatedKeyDown={eventCreatedKeyDown}
                                    eventTypeKeyDown={eventTypeKeyDown}
                                    companyList={newCompanyList}
                                    companyCount={companyCount}
                                    headerSingleSearchOptions={headerSingleSearchOptions}
                                    singleSearchTypes={singleSearchTypes}
                                    btnLink={this.handleIsolatedModalBtnLink}
                                    dropDowBtnShow={dropDowBtnShow}
                                    handleOnKeyDown={this.onKeyPressed}
                                    prevPath ={pathname}
                                    userType={userType}
                                    dropdownItemClassName={dropdownItemClassName}
                                    DropdownItemTag={DropdownItemTag}
                                    optionOnClick={(e,companyInfo)=>{
                                        optionOnClick(e,companyInfo);
                                        this.setState({ isModalOpen: false });
                                    }}
                                />}
                            </MediaQuery>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

}
const mapStateToProps = (state) => ({
    companyList: state.searchReducer.searchCompanyList,
    companyCount: state.searchReducer.searchCompanyCount,
    userInfo: state.userProfile.userInfo,
    companyQueryData: state.searchReducer.companyQueryData,
    isEstimateOn: state.searchReducer.isEstimateOn,

})
const mapDispatchToProps = (dispatch) => ({
    setActivityTrackingData: trackingObj => dispatch(setActivityTrackingData(trackingObj)),
    dispatchCallSingleSearchApi: (searchObj) => dispatch(callSingleSearchApiAction(searchObj)),
    dispatchCompanySearchAppliedFilter: (appliedFilter) => dispatch(setCompanySearchAppliedFilter(appliedFilter)),
    dispatchResetAllSearchParams: searchParam => dispatch(resetAllSearchParams(searchParam)),
    dispatchSetCompanySearchParams: (searchObj) => dispatch(setCompanySearchParams(searchObj))
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IsolatedSearchBar));