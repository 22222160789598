import { SubmissionError } from 'redux-form';

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
function submit(values) {
  //return sleep(100).then(() => {
  // simulate server latency
  if (values.email === "" || !emailRegex.test(values.email)) {
    throw new SubmissionError({
      email: 'Invalid Email Address',
      _error: 'Login failed!'
    })
  }
  if (!values.password || values.password === '') {
    throw new SubmissionError({
      password: 'Invalid Password',
      _error: 'Login failed!'
    })
  }
  return true;

  //})
}

export default submit
