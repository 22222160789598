import React, { PureComponent } from 'react';
import MediaQuery from 'react-responsive';
import './BasicInformation.css';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import { ET_INDUSTRY_SHARE_BUTTON_CLICK } from '../../common/trackingConstants';

class BasicInformation extends PureComponent {
    
    toggleShareHandler = () => {    
        const shareUrl = `${window.location.origin}${window.location.pathname}`;
        let count = 0;
        let countText = "";
        this.props.toggleShareUrlModal({
            show: true,
            urlToShare: shareUrl,
            urlToShareEmail: `I found ${count.toLocaleString()} ${countText} on the Pomanda Power Search. Click here to view them now.\r\n\r\n ${shareUrl}`,
            subjectToShare: "Check out these results from the Pomanda Power Search.",
        });
        this.props.sendActivityTracking({ eventType: ET_INDUSTRY_SHARE_BUTTON_CLICK, attribute3: shareUrl });
    }
    render() {
        const {
            heading = "",
            description = "",
            basicInfoArray = [],
            tagsString = ""
        } = this.props;
      
        return (
            <div className="industry-info-parent">
                <div className="indus-info-table">
                    <div className="indus-info-table-text-area">
                        <div className="mb-4">
                            <p className="indus-info-heading" > {heading} </p>
                            <p className="indus-info-details" >
                                {description}</p>
                        </div>
                        <div className="indus-info-container" >
                            {basicInfoArray.map((item, index) =>
                                <AboutIndustry key={`${index}_industry}`} heading={item.heading}
                                    value={item.value} icon={item.icon} 
                                    className="indus-info-business-data" 
                                    toolTipText={item.toolTipText} />)}
                            <div>
                                <p className="indus-info-business-head" > Tags </p >
                                <p className="indus-info-footer" > {tagsString}. </p>
                            </div>
                        </div>
                        <MediaQuery maxWidth={575.98}>
                            <button onClick={this.toggleShareHandler} className="industry-page-share">SHARE</button>
                        </MediaQuery>
                    </div>
                </div >
            </div>
        )
    }
}
const AboutIndustry = (props) => {
    const {
        icon = "",
        heading,
        toolTipText = ""
    } = props;
    const tooltipName = `${heading.replace(/\s/g, "")}_name`;
    return <div className="indus-info-sub-container">
        <p className="indus-info-business-head">
            {heading} 
            {icon ? <>&nbsp;<i id={tooltipName} className={icon} pomandae2e={tooltipName} pomandae2e_tooltiptext={toolTipText}>
                <UncontrolledTooltip
                    placement="right"
                    target={tooltipName}
                    innerClassName="tooltip-inner-content">
                    {toolTipText}
                </UncontrolledTooltip>
            </i></> : null}
        </p >
        <p className={props.className} > {props.value} </p>
    </div>
}

export default BasicInformation;