import { 
    GET_INDUSTRY_DETAILS_REQUEST,
    GET_BUSINESS_PLAN_DATA_REQUEST,
    SAVE_BUSINESS_PLAN_DATA_REQUEST,
    SAVE_BUSINESS_PLAN_DATA_SUCCESS,
    SET_BUSS_TEMP_PAGE_STATUS, SET_INDUSTRY_DETAILS,
    SAVE_FINANCIAL_DATA, 
    GET_FUTURE_FINANCIAL_PROJECTION_DATA_REQUEST,
    SET_BUSS_TEMP_MAIN_PAGE_DATA, 
    GET_SUGGESTED_COMPETITORS_DATA_REQUEST,
    UPLOAD_AND_GET_TEMP_BUSS_PLAN_FILE_URL,
    SET_BUSINESS_PLAN_MENU_DATA,
    SET_BUSINESS_PLAN_UPLOADED_TEMP_FILES,
    GET_BUSINESS_PLAN_COMPANY_INSIGHTS_DATA,
    SET_BUSINESS_PLAN_COLOUR_SCHEME,
    GET_BUSINESS_PLAN_PAGE_PREVIEW,
    SET_BUSINESS_PLAN_PAGE_PREVIEW_HTML,
    GET_BUSINESS_PLAN_INDUSTRY_INSIGHTS,
    SET_BUSINESS_PLAN_INDUSTRY_INSIGHTS,
    SET_BUSINESS_PLAN_PREVIEW_PAGE_LOADING
} from './actionConstants';

export const setBussTempMainPageData = (pageObj) => ({
    type: SET_BUSS_TEMP_MAIN_PAGE_DATA,
    payload: {pageObj}
})

export const setBussTempPageStatus = (pageObj) => ({
    type: SET_BUSS_TEMP_PAGE_STATUS,
    payload: {pageObj}
})

export const saveBusinessPlanDataRequest = (businessPlanObj, uploadedTempFiles,isOpenReport=false) => ({
    type: SAVE_BUSINESS_PLAN_DATA_REQUEST,
    payload: {businessPlanObj, uploadedTempFiles,isOpenReport}
})

export const saveBusinessPlanDataSuccess = (planObj) => ({
    type: SAVE_BUSINESS_PLAN_DATA_SUCCESS,
    payload: {planObj}
})

export const getIndustryDetails=(sicCodes)=>({
    type: GET_INDUSTRY_DETAILS_REQUEST,
    payload: { sicCodes }
});

export const setIndustryDetails=(industryDetails)=>({
    type: SET_INDUSTRY_DETAILS,
    payload: { industryDetails }
});

export const getBusinessPlanDataRequest = (uniqueId) => ({
    type: GET_BUSINESS_PLAN_DATA_REQUEST,
    payload: {uniqueId}
});

export const saveFinancialData = (paramsObj)=>({
    type: SAVE_FINANCIAL_DATA,
    payload: { paramsObj }
})

export const getFutureFinancialProjectionDataRequest=(paramsObj)=>({
    type: GET_FUTURE_FINANCIAL_PROJECTION_DATA_REQUEST,
    payload: { paramsObj }
})

export const getSuggestedCompetitorsDataRequest = (companyId) => ({
    type: GET_SUGGESTED_COMPETITORS_DATA_REQUEST,
    payload: { companyId }
})

export const uploadTempBussPlanFile = (uploadedFileObj) => ({
    type: UPLOAD_AND_GET_TEMP_BUSS_PLAN_FILE_URL,
    payload: { uploadedFileObj }
})

export const setBussPlanData = (businessPlanObj) => ({
    type: SET_BUSINESS_PLAN_MENU_DATA,
    payload: { businessPlanObj }
})

export const setBussPlanUploadedTempFiles = (uploadedTempFiles) => ({
    type: SET_BUSINESS_PLAN_UPLOADED_TEMP_FILES,
    payload: { uploadedTempFiles }
})

export const getBusinessPlanCompanyInsights = (companyId) => ({
    type: GET_BUSINESS_PLAN_COMPANY_INSIGHTS_DATA,
    payload: { companyId }
})

export const setBussPlanColourScheme = (colourScheme) => ({
    type: SET_BUSINESS_PLAN_COLOUR_SCHEME,
    payload: { colourScheme }
})

export const getBussPlanPagePreview = (previewObj) => ({
    type: GET_BUSINESS_PLAN_PAGE_PREVIEW,
    payload: { previewObj }
})

export const setBussPlanPagePreviewHtml = (previewPageHtml) => ({
    type: SET_BUSINESS_PLAN_PAGE_PREVIEW_HTML,
    payload: { previewPageHtml }
})

export const getBusinessPlanIndustryInsights = (indMix) => ({
    type: GET_BUSINESS_PLAN_INDUSTRY_INSIGHTS,
    payload: { indMix }
})

export const setBusinessPlanIndustryInsights = (industryInsight) => ({
    type: SET_BUSINESS_PLAN_INDUSTRY_INSIGHTS,
    payload: { industryInsight }
})

export const setPreviewPageLoading = (loadingObj) => ({
    type: SET_BUSINESS_PLAN_PREVIEW_PAGE_LOADING,
    payload: { loadingObj }
})
