import React from "react";
import "./HeroSectionWithBgVideo.css"
import YellowBgButton from "../BusinessPlanTemplate/BusinessPlanComponents/YellowBgButton/YellowBgButton";

const HeroSectionWithBgVideo = (props) => {
    const {
        heading = "",
        subHeading = "",
        patnerObj = {
            heading: "In partnership with",
            imageUrl: "/assets/svgs/swoop_logo.svg",
            width: "176",
            height: "64",
            alt: "swoop_logo"
        },
        buttonComp = {}
    } = props;

    return <div className="hero-section-with-bg-video">
        <video className="hero-section-video" autoPlay loop muted playsInline webkit-playsinline="true">
            <source src="/assets/videos/business_plan_swoop_video.mp4" type="video/mp4" />
            Your browser does not support the video tag.
        </video>
        <div className="gradient-over-video"></div>
        <div className="hero-section-details">
            <h2 className="heading-text">{heading}</h2>
            <p className="subheading-text mb-0">{subHeading}</p>
        </div>
        {buttonComp?.text ? <YellowBgButton 
            text={buttonComp.text}
            cmpCssClassStr={buttonComp.cssClass}
            onBtnClick={buttonComp.onBtnClick}
        /> : null}
        {patnerObj ? <div className="hero-section-partnership">
            <p className="mb-4 pom-font-18">{patnerObj.heading}</p>
            <img className="swoop-img" src={patnerObj.imageUrl} width={patnerObj.width} height={patnerObj.height} alt={patnerObj.alt} />
        </div> : null}
    </div>
}

export default HeroSectionWithBgVideo;