import React, { memo, useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import "./CurrencyDropdown.css"
import { saveAiCurrencyForUser, setAiCurrency } from '../../redux/actions/userAccountAction';
import localStorageApi from "../../api/localStorageApi"
import { isBrowser } from '../../common/config';

const currencyList = [{
    label: "gbp",
    imgUrl: "/assets/svgs/gbp_flag.svg"
},
{
    label: "eur",
    imgUrl: "/assets/svgs/eur_flag.svg"
},
{
    label: "usd",
    imgUrl: "/assets/svgs/usd_flag.svg"
}, {
    label: "chf",
    imgUrl: "/assets/svgs/chf_flag.svg"
},
{
    label: "aud",
    imgUrl: "/assets/svgs/aud_flag.svg"
},
{
    label: "jpy",
    imgUrl: "/assets/svgs/jpy_flag.svg"
}]


const CurrencyDropdown = memo(function CurrencyDropdown(props) {
    const dispatch = useDispatch();
    const aiCurrency = useSelector(store => store.userProfile?.userInfo?.aiCurrency || store.userProfile?.countryBasedCurrency);
    const selectedCurrencyObj = currencyList.filter((currencyObj) => currencyObj.label.toLocaleLowerCase() === aiCurrency.toLocaleLowerCase())[0];
    const handleCurrencyChange = (currency) => {
        const token = localStorageApi.getUserAuthToken();
        if (token && isBrowser) {
            dispatch(saveAiCurrencyForUser(currency));
        } else {
            dispatch(setAiCurrency(currency));
        }

    }

    return (
        <div className="dropdown currency-dropdown">
            <button className="btn dropdown-toggle currency-dropdown-btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" >
                <picture >
                    <img src={selectedCurrencyObj?.imgUrl} alt="currency_flag" width={22} height={22}></img>
                </picture>
                <span className='currency-dropdown-label'>{selectedCurrencyObj?.label}</span>
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {currencyList.map((currencyObj, i) => {
                    return <p className="dropdown-item cursor-pointer" onClick={() => { handleCurrencyChange(currencyObj.label)}} key={`currency_option_${i}`}>
                        <picture >
                            <img src={currencyObj?.imgUrl} alt="currency_flag" width={22} height={22}></img>
                        </picture>
                        <span className='currency-dropdown-label'>{currencyObj.label}</span>
                    </p>
                })}
            </div>
        </div>
    )
});

export default CurrencyDropdown;