import React from 'react';
import App from './App';
import loadable from '@loadable/component'
import { setHideHeader, setUserVariant } from './redux/actions/commonAction';
import { Redirect } from 'react-router-dom';
import { getCompanyProfileFailed, getCompanyProfileSuccess } from './redux/actions/companyAction'
import {
  getCompanyAndDirectorData, getIndustryProfileData, getUserVariants,
  callBlogDetailApi, callBlogListApi, getLocationProfileData, getPopularCompanyData
} from './ServerService/apiService';
import { getDirectorDataSuccess } from './redux/actions/directorAction';
import {
  getBlogListSuccess, getBlogDetailSuccess
} from "./redux/actions/blogAction";
import { getIndustryProfileSuccess } from './redux/actions/industryProfileAction';
import LocationProfileComponent from './components/LocationProfileComponent/LocationProfileComponent';
import { getLocationProfileFailed, getLocationProfileSuccess } from './redux/actions/locationProfileAction';
import SolutionsHomePage from './components/WhatWeDo/SolutionsHome';
import FAQPage from './components/Faqs/FAQPage';
import GlossaryPage from './components/Glossary/GlossaryPage';
import { getPopularCompaniesSuccess } from './redux/actions/popularCompaniesAction';
import  ValuationReportPPCHomePage  from './components/ValuationReportPPCHomePage/ValuationReportPPCHomePage';
import CompaniesPPCLandingPage from './components/CompaniesPPCPage/CompaniesPPCLandingPage';
import { EP_DIRECT_SEARCH_COMPANIES_PPC_LEADPAGE, EP_DIRECT_SELECT_PACKAGE_PPC_LEADPAGE, 
  EP_DIRECT_VALUATION_REPORT_PPC_LEADPAGE, EP_SEARCH_COMPANIES_PPC_LANDING_PAGE, 
  EP_SELECT_PACKAGE_PPC_LANDING_PAGE, EP_VALUATION_REPORT_PPC_HOMEPAGE, 
  EP_BUY_SELL_BUSINESS_PPC_HOME_PAGE, EP_BUSINESSPLAN_HOMEPAGE, EP_BUSINESS_PLAN_PPC_HOMEPAGE, 
  ET_BUSINESSPLAN_HOMEPAGE_ONLOAD, ET_BUSINESS_PLAN_PPC_HOMEPAGE_ONLOAD, EP_BUSINESS_PLAN_ONBOARD, ET_BUSINESS_PLAN_SAMPLE_CLICK, EP_BUSINESS_VALUATION_ONBOARD 
} from './common/trackingConstants';
  import BusinessPlanPPCHomePage from './components/BusinessPlanPPCHomePage/BusinessPlanPPCHomePage';
import ValuationOnboardPage from './components/ValuationOnboardPage/ValuationOnboardPage';
const ValuationOnboard = (props) => <ValuationOnboardPage {...props} eventPage={EP_BUSINESS_VALUATION_ONBOARD} />

const SwoopLandingPage = loadable(() => import('./components/SwoopLandingPage/SwoopLandingPage'));
const BusinessValuationPricingPage = loadable(() => import('./components/BusinessValuationPricingPage/BusinessValuationPricingPage'));
const AboutUs = loadable(() => import( './components/AboutUs/AboutUs'));
const ContactUs = loadable(() => import( './components/ContactUsPage/ContactUs'));
const BusinessPlanOnboardPage = loadable(()=>import('./components/BusinessPlanOnboardPage/BusinessPlanOnboardPage'));
const BusinessPlanOnboard = (props) => <BusinessPlanOnboardPage {...props} eventPage={EP_BUSINESS_PLAN_ONBOARD} />
const SelectPackagePPCLandingPage = loadable(()=>import('./components/SelectPackagePPCLandingPage/SelectPackagePPCLandingPage'));
const {
  checkWhitelistIPS,
} = require('./ServerService/apiUtilities')
const HomePage = loadable(() => import('./components/HomePage/Homepage'))
const EstimateHomePage = loadable(() => import('./components/EstimateHomePage/EstimateHomePage'));
const ProsperCompanyDirectorInfo = loadable(() => import('./components/ProsperCompanyDirectorInfo/ProsperCompanyDirectorInfo'));
const CompanyProfile = loadable(() => import('./components/CompanyProfile/CompanyProfile'))
const PopularCompanies = loadable(() => import('./components/PopularCompanies/PopularCompanies'))
const PowerSearch = loadable(() => import('./components/PowerSearch/PowerSearch'))
const Account = loadable(() => import('./components/Accounts/Account'))
const Director = loadable(() => import('./components/DirectorPage/DirectorPage'))
const CompanyHouseFilingDoc = loadable(() => import('./components/CompanyHouseFilingDoc/CompanyHouseFilingDoc'))
const CompanyHouse = loadable(() => import('./components/CompanyHouse/CompanyHouse'))
const CreditReportDoc = loadable(() => import('./components/CreditReportDoc/CreditReportDoc'))
const PPTReport = loadable(() => import('./components/common/PPTReport'))
const ABFLandingPage = loadable(() => import("./components/ABFLandingPage/ABFLandingPage"))
const BlogListPage = loadable(() => import("./components/BlogPage/BlogListPage"))
const BlogDetailPage = loadable(() => import('./components/BlogPage/BlogDetailPage'))
const ReportDownload = loadable(() => import("./components/ReportDownload/ReportDownload"))
const LandingPage = loadable(() => import("./components/LandingPageTemplate/LandingPage"))
const CreditReportHomePage = loadable(() => import('./components/CreditReportHomePage/CreditReportHomePage'));
const CompanyDirectorInformation = loadable(() => import('./components/CompanyDirectorInformation/CompanyDirectorInformation'));
const leadGenerationHomePage = loadable(() => import('./components/LeadGenerationHomepage/LeadGenerationHomepage'));
// const InvestmentHomePage = loadable(() => import('./components/InvestmentHomePage/InvestmentHomePage'));
const ProductPage = loadable(() => import('./components/ProductPage/ProductPage'));
const ScoreCardHomepage = loadable(() => import('./components/ScoreCardHomepage/ScoreCardHomepage'));
const IndustryHomepage = loadable(() => import('./components/IndustryInsightHomePage/IndustryInsightHomePage'));
const IndustryProfileComponent = loadable(() => import('./components/IndustryProfileComponent/IndustryProfileComponent'));
const ValuationCalculatorPage = loadable(() => import('./components/CompanyProfile/ValuationCalculator/ValuationCalculatorPage/ValuationCalculatorPage'));
// const BusinessPlanHomePage = loadable(() => import("./components/BusinessPlanHomePage/BusinessPlanHomePage"))
const CompetitorAnalysisPage = loadable(()=>import("./components/CompetitorAnalysis/CompetitorAnalysis"));
const LinkedInCallbackComp = loadable(()=>import("./components/LinkedInCallbackComp/LinkeInCallbackComp"));
const BuySellBusinessHomePage = loadable(()=>import("./components/BuySellBusinessHomePage/BuySellBusinessHomePage"));
//const StandardIndustryClassification = loadable(() => import("./components/StandardIndustryClassification/StandardIndustrySicCode/StandardIndustryClassificationSicCode"))
//const StandardIndustryClassificationCompanyList = loadable(() => import("./components/StandardIndustryClassification/StandardIndustryClassificationCompanyList/StandardIndustryClassificationCompanyList"))
// const { matches } = require('ip-matching')
const BusinessTemplateBuilder = loadable(() => import('./components/BusinessPlanTemplate/BusinessPlanTemplate'));
const HelpCentrePage = loadable(() => import("./components/HelpCentre/Pages/HelpCentrePage/HelpCentrePage"));

const ValuationReportPPCHomePageWrapper = (props) => <ValuationReportPPCHomePage {...props} userType="basic" eventPage={EP_VALUATION_REPORT_PPC_HOMEPAGE}/>
const CompaniesPPCLandingPageWrapper = (props) => <CompaniesPPCLandingPage {...props} userType="basic" eventPage={EP_SEARCH_COMPANIES_PPC_LANDING_PAGE}/>
const SelectPackagePPCLandingPageWrapper = (props) => <SelectPackagePPCLandingPage {...props} userType="basic" eventPage={EP_SELECT_PACKAGE_PPC_LANDING_PAGE}/>
const DirectValuationReportPPCLeadpage = (props) => <ValuationReportPPCHomePage {...props} userType="anonymous" eventPage={EP_DIRECT_VALUATION_REPORT_PPC_LEADPAGE} />
const DirectCompaniesPPCLeadPage = (props) => <CompaniesPPCLandingPage {...props} userType="anonymous" eventPage={EP_DIRECT_SEARCH_COMPANIES_PPC_LEADPAGE} />
const DirectSelectPackagePPCLeadPage = (props) => <SelectPackagePPCLandingPage {...props} userType="anonymous" eventPage={EP_DIRECT_SELECT_PACKAGE_PPC_LEADPAGE} />
const BuySellBusinessHomePageWrapper = (props) => <BuySellBusinessHomePage {...props} userType="anonymous" eventPage={EP_BUY_SELL_BUSINESS_PPC_HOME_PAGE}/>
const CreditReportCompaniesPPCLandingPageWrapper = (props) => <CompaniesPPCLandingPage {...props} userType="basic" eventPage={EP_SEARCH_COMPANIES_PPC_LANDING_PAGE}/>
const BusinessPlanHomePageWrapper = (props) => <BusinessPlanPPCHomePage {...props} userType="basic" eventPage={EP_BUSINESSPLAN_HOMEPAGE} eventType={ET_BUSINESSPLAN_HOMEPAGE_ONLOAD}/>
const BusinessPlanPPCHomePageWrapper = (props) => <BusinessPlanPPCHomePage {...props} userType="basic" eventPage={EP_BUSINESS_PLAN_PPC_HOMEPAGE} eventType={ET_BUSINESS_PLAN_PPC_HOMEPAGE_ONLOAD}/>

export default [
  {
    component: App,
    routes: [
      {
        component: HomePage,
        path: '/',
        exact: true,
        loadData: async ({dispatch}) => {
          dispatch(setHideHeader({
            backgroundDesktop: "transparent",
            backgroundTab: "transparent",
            backgroundMob: "transparent",
            isSingleSearchbarSticky: false
          }))
        }
      },
      {
        component: HomePage,
        path: "/resetpassword/:email?",
        exact: true
      },
      
      
      {
        component: Account,
        path: [
          "/account/profile", 
          "/account/contact-us", 
          "/account/valuation-reports",
          "/account/whitelabel", 
          "/account/business-plans"
        ],
        exact: true
      },
      
      {
        component : BusinessPlanHomePageWrapper,
        path : "/businessplan",
        exact : true,
        loadData: async ({dispatch}) => {
          dispatch(setHideHeader({
            backgroundDesktop: "transparent",
            backgroundTab: "transparent",
            backgroundMob: "transparent",
            showSingleSearchbar: false,
            showPowerSearchNav: false,
          }))
        }
      },
      {
        component : BusinessPlanPPCHomePageWrapper,
        path : "/solutions/businessplan/ppc",
        exact : true
      },
      {
        component: ValuationReportPPCHomePageWrapper,
        path: "/companyvaluation",
        exact: true
      },
      {
        component: CompaniesPPCLandingPageWrapper,
        path: "/solutions/companyvaluation/companies",
        exact: true
      },
      {
        component : SelectPackagePPCLandingPageWrapper,
        path : "/solutions/companyvaluation/companies/:companyId/selectpackage",
        exact : true
      },
      {
        component : DirectValuationReportPPCLeadpage,
        path : "/solutions/companyvaluation/ppc",
        exact : true
      },
      {
        component : DirectCompaniesPPCLeadPage,
        path : "/solutions/companyvaluation/companies/ppc",
        exact : true
      },
      {
        component : DirectSelectPackagePPCLeadPage,
        path : "/solutions/companyvaluation/companies/:companyId/selectpackage/ppc",
        exact : true
      },
      
      {
        component: BusinessTemplateBuilder,
        path: [
          "/businessplan/:uniqueId/summary", "/businessplan/:uniqueId/description", "/businessplan/:uniqueId/history",
          "/businessplan/:uniqueId/history","/businessplan/:uniqueId/industry-insight","/businessplan/:uniqueId/key-people",
          "/businessplan/:uniqueId/objectives","/businessplan/:uniqueId/ownership","/businessplan/:uniqueId/strengths-weeknesses",
          "/businessplan/:uniqueId/personas","/businessplan/:uniqueId/competitive-analysis","/businessplan/:uniqueId/product-services",
          "/businessplan/:uniqueId/pricing-strategy", "/businessplan/:uniqueId/customer-acqusition", "/businessplan/:uniqueId/sales-forecast",
          "/businessplan/:uniqueId/location-of-operation", "/businessplan/:uniqueId/key-suppliers-relationships",
          "/businessplan/:uniqueId/staff-employment-plans", "/businessplan/:uniqueId/legal-regulatory-insurance", "/businessplan/:uniqueId/financial-projections",
          "/businessplan/:uniqueId/funding-requirement-funds", "/businessplan/:uniqueId/business-valuation", "/businessplan/:uniqueId/appendix", "/businessplan/:uniqueId/branding-personalisation"
        ],
        exact: true,
      },
      {
        component: BusinessPlanOnboard,
        path: ["/businessplanonboard", '/businessplanonboard/:businessType'],
        exact: true,
        loadData: ({ dispatch }) => {
          dispatch(setHideHeader({ show: false }));
        }
      },{
        component: ValuationOnboard,
        path: ["/valuationonboard"],
        exact: true,
        loadData: ({ dispatch }) => {
          dispatch(setHideHeader({ show: false }));
        }
      }
      ,{
        component: BuySellBusinessHomePageWrapper,
        path: "/solutions/companyvaluation/selling/ppc",
        exact: true
      },
      {
        component: AboutUs,
        path: "/about",
        exact: true,
      },
      // {
      //   component: StandardIndustryClassification,
      //   path: "/sic/:index/:pagenumber",
      //   exact : true,
      //   loadData : async({ dispatch }, { params }, token, ipAddress) =>{
      //     try{
      //         const response = await getIndustrySicCodeData( params.index, params.pagenumber, 500, token, ipAddress);
      //         const {data=[], pageNumber=1, index='A', size=0, totalRecords=0} = response?.industrySicCodes;
      //         dispatch(getIndustrySicCodesResponse(data, pageNumber, index, size, totalRecords));
      //         return;
      //     }
      //     catch(error){
      //       console.log('error : ', error)
      //     }
      //   }
      // },
      // {
      //   component: StandardIndustryClassificationCompanyList,
      //   path: "/sic/:siccode/:sicdesc/:pageNumber/companylist",
      //   exact : true,
      //   loadData : async({ dispatch }, { params }, token, ipAddress) =>{
      //     try{
      //         const response = await getIndustrySicCodeCompanyList( params.siccode, 500, params.pageNumber, token, ipAddress);
      //         const {data=[], sicCode='0', size=0, totalRecords=0} = response?.companyList
      //         dispatch(getSicCodeBasedCompanyListResponse(data,  sicCode, size, totalRecords))
      //         return;
      //     }
      //     catch(error){
      //       console.log('error : ', error)
      //     }
      //   }
      // },
      {
        component: ContactUs,
        path: "/contact",
        exact: true,
      },
      {
        component: BusinessValuationPricingPage,
        path: "/company/:companyId/:companyName/valuationproductpage",
        exact: true
      },
      {
        component: ValuationCalculatorPage,
        path: "/company/:companyId/:companyName/valuation",
        exact: true,
        // loadData: async ({ dispatch }, { params }, token, ipAddress, accessToken, trackerId, sessionId) => {        }
      },
      {
        component: ReportDownload,
        path: [
          "/downloadreport/:reporttype/:id/:userId",
          "/downloadreport/:reporttype/:usrRptMappingId",
          "/downloadreport/:reporttype/:cohId/:userId/generate-report",
        ],
        exact: true
      },
      {
        path: "*",
        component: function () {
          return <Redirect to='/' />
        }

      }     
    ]
  }
];
