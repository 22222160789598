import React from "react";
import Carousel from "react-multi-carousel";
import "./CarouselWithScrollBar.css";
import "react-multi-carousel/lib/styles.css";
import { CarouselBusinessHomePPCConfig } from "../../../common/CarouselConfig";

const ALPHA_SECTION_CONTENT = ["Executive Summary", "Company Description", "History & Milestones", "Key People", "Ownership", "Shareholders", "Business Vision",
    "Objectives", "Industry Insight", "Target Market", "Customer Personas", "Strengths & Weaknesses", "Opportunities & Threats", "Competitive Analysis",
    "Competitive Analysis- Charts", "Products & Services", "Pricing Model & Strategy", "Customer Acquisition Strategy", "Customer Acquisition Channels", "Sales Forecast", "Business Locations",
    "Key Suppliers", "Current & Future Staffing", "Financial Projections", "Funding Requirements & Uses", "Business Valuation", "Appendix"]

class CarouselWithScrollBar extends React.Component {
    state = { additionalTransfrom: 0 };
    splitArrayIntoChunks = (array, startIndex, endIndex) => {
        const chunkedArray = array.slice(startIndex, endIndex);
        return <div className='business-plan-home-ppc-alpha-section-content-container'>
            {chunkedArray.map((itm, index) =>
                <div className='business-plan-home-ppc-alpha-section-content'
                    key={`business-plan-ppc-alpha-content-${index}`}>
                    {itm}
                </div>)}
        </div>
    }
    onChange = (e,value,carouselItemWidth) => {
        if (this.Carousel.isAnimationAllowed) {
            this.Carousel.isAnimationAllowed = false;
        }
        const nextTransform = e.target.value * value;
        const nextSlide = Math.round(nextTransform / carouselItemWidth);
        if (
            e.target.value == 0 &&
            this.state.additionalTransfrom === 150
        ) {
            this.Carousel.isAnimationAllowed = true;
            this.setState({ additionalTransfrom: 0 });
        }
        this.Carousel.setState({
            transform: -nextTransform, // padding 20px and 5 items.
            currentSlide: nextSlide
        });
    }
    render() {
        const CustomSlider = ({ carouselState }) => {
            let value = 0;
            let carouselItemWidth = 0;
            if (this.Carousel) {
                carouselItemWidth = this.Carousel.state.itemWidth;
                const maxTranslateX = Math.round(
                    carouselItemWidth *
                    (this.Carousel.state.totalItems -
                        this.Carousel.state.slidesToShow) +
                    150
                );
                value = maxTranslateX / 100; // calculate the unit of transform for the slider
            }
            const { transform } = carouselState;
            const maxWidth = (carouselItemWidth *
                (carouselState.totalItems - carouselState.slidesToShow) +
                (this.state.additionalTransfrom === 150 ? 0 : 150)) /
            value
            return (
                <div className="custom-carousel-slider">
                    <input
                        type="range"
                        value={Math.round(Math.abs(transform) / value)}
                        max={maxWidth}
                        onChange={(e)=>this.onChange(e,value,carouselItemWidth)}
                        className="custom-carousel-slider-input"
                    />
                </div>
            );
        };
        const CustomDot = (props) => {
            return <div onClick={props.onClick} className={`carousel-state-indicator ${props.active ? "active" : ""}`}></div>
        }
        return (
            <Carousel responsive={CarouselBusinessHomePPCConfig}
                swipeable={true}
                slidesToSlide={2}
                partialVisible={true}
                autoPlay={true}
                infinite={true}
                ssr={true}
                arrows={false}
                ref={(el) => (this.Carousel = el)}
                partialVisbile={false}
                containerClass="carousel-container-with-scrollbar"
                showDots
                customDot={<CustomDot />}
                additionalTransfrom={-this.state.additionalTransfrom}
            >
                {this.splitArrayIntoChunks(ALPHA_SECTION_CONTENT, 0, 7)}
                {this.splitArrayIntoChunks(ALPHA_SECTION_CONTENT, 7, 14)}
                {this.splitArrayIntoChunks(ALPHA_SECTION_CONTENT, 14, 21)}
                {this.splitArrayIntoChunks(ALPHA_SECTION_CONTENT, 21, 27)}

            </Carousel>
        );
    }
}

export default CarouselWithScrollBar;
