import React from "react";
import MediaQuery from "react-responsive";
import { convertToUkCurrencyWithoutPoundSymbol } from "../../common/CurrencyConverter";
import IsolatedSearchBar from "../../common/IsolatedSearchBar/IsolatedSearchBar";
import "./MatchingCompaniesHeader.css";

export const MatchingCompaniesHeader = (props) => {
    const {
        componentName = "",
        searchBarComp = {},
        totalMatchingCompanies = "",
        matchingCompanyName = "",
        onClickBackBtn = "",
        headerText = '',
        headerTextCss = ""
    } = props;


    const {
        singleSearchBarId = "",
        searchButtonPlaceholder = "",
        searchTextboxValue = "",
        setSearchTextboxValue = "",
        eventCreatedKeyDown = "",
        eventTypeKeyDown = "",
        eventPage = "",
        eventTypeSearch = "",
        eventCreatedSearch = "",
        eventCreatedOptionClick = "",
        eventCreatedIconClick = "",
        singleSearchBarType = "",
        singleSearchTypes = ["company"],
        dropDowBtnShow="",
        btnLink= () => {},
        userType = "",
        DropdownItemTag = "",
        optionOnClick = () => {},
        dropdownItemClassName = ""
    } = searchBarComp;

    return <div className="matching-companies-header-wrapper" id={componentName}>
        <div className="matching-companies-header-container">
            <div className="total-companies-matching">
                {searchBarComp?.singleSearchBarId ? <MediaQuery minWidth={992}>
                    <i className="fas fa-solid fa-angle-left back-to-valuation-report-ppc-page" pomandae2e="back" onClick={() => onClickBackBtn(matchingCompanyName)}></i>
                </MediaQuery> : <i className="fas fa-solid fa-angle-left back-to-valuation-report-ppc-page" pomandae2e="back" onClick={() => onClickBackBtn(matchingCompanyName)}></i>}
                {searchBarComp?.singleSearchBarId && <MediaQuery maxWidth={991.98}>
                    <div className="box-divider"></div>
                </MediaQuery>}
                <div className="total-companies-matching-main">
                    {matchingCompanyName ? <p className="total-companies-matching-company-name mb-0">Companies Matching <span
                         className="searched-company-name" >{matchingCompanyName}...</span> </p> : <p className={`total-companies-matching-company-name mb-0 ${headerTextCss ? headerTextCss : ""}`}>{headerText ? headerText : "Return"}</p>}
                    {totalMatchingCompanies ? <p className="total-companies-matching-ppc">{totalMatchingCompanies ? `(${convertToUkCurrencyWithoutPoundSymbol(totalMatchingCompanies)})` : 0} Results found</p>:null}
                </div>
            </div>
            {searchBarComp?.singleSearchBarId && <div className="business-value-searchbar-container">
                <MediaQuery maxWidth={991.98}>
                    <i className="fas fa-solid fa-angle-left back-to-valuation-report-ppc-page" pomandae2e="back" onClick={() => onClickBackBtn(matchingCompanyName)}></i>
                </MediaQuery>
                <div className="business-value-searchbar">
                    <IsolatedSearchBar
                        formId={singleSearchBarId}
                        searchButtonPlaceholder={searchButtonPlaceholder}
                        searchTextboxValue={searchTextboxValue}
                        inputId={`${singleSearchBarId}_input`}
                        eventCreatedKeyDown={eventCreatedKeyDown}
                        eventTypeKeyDown={eventTypeKeyDown}
                        eventPage={eventPage}
                        eventTypeSearch={eventTypeSearch}
                        eventCreatedSearch={eventCreatedSearch}
                        eventCreatedOptionClick={eventCreatedOptionClick}
                        eventCreatedIconClick={eventCreatedIconClick}
                        type={singleSearchBarType}
                        singleSearchTypes={singleSearchTypes}
                        dropDowBtnShow={dropDowBtnShow}
                        btnLink={btnLink}
                        userType={userType}
                        DropdownItemTag={DropdownItemTag}
                        optionOnClick={optionOnClick}
                        dropdownItemClassName={dropdownItemClassName}
                    />
                </div>
            </div>}
        </div>
    </div>
}