import React, { memo, useState } from 'react';
import "./BusinessValuationSteps.css";
import MediaQuery from 'react-responsive';
import YouTubePlayVideoModal from '../YouTubeComponent/YouTubePlayVideoModal';
import { ET_BUSINESS_VALUATION_CLOSE_VIDEO_BTN_CLICK, ET_BUSINESS_VALUATION_VIDEO_CLICK } from '../../common/trackingConstants';
import { BUSINESS_VALUATION_YOUTUBE_VIDEO_ID } from '../../common/config';

export const BusinessValuationSteps = memo(function BusinessValuationSteps(props) {
    const {
        sendActivityTracking = () => {},
        eventPage = "",
    } = props;

    const [isVideoModalOpen, setVideoModalStatus] = useState(false);

    const businessValuationSteps = [
        {
            heading: "Provide Company Information",
            subHeading: "Enter basic company and industry details.",
            icon: {
                iconSrc: "/assets/svgs/pom_ai_valuation_step1.svg",
                iconWidth:"235px",
                iconHeight:"223px"    
            }
        },
        {
            heading: "Access Valuation Calculator",
            subHeading: "Fine tune your financial data for more accurate results.",
            icon: {
                iconSrc: "/assets/svgs/pom_ai_valuation_step2.svg",
                iconWidth:"235px",
                iconHeight:"223px",
                tabletImageSrc:"/assets/svgs/pom_ai_val_tablet_cal.svg",
                mobileImageSrc: "/assets/svgs/pom_ai_buss_valuation_mobile_cal.svg"
            }
        },
        {
            heading: "Generate a Professional Report",
            subHeading: 'Includes six valuation methods with full calculations and helpful information.',
            icon: {
                iconSrc: "/assets/svgs/pom_ai_valuation_step3.svg",
                iconWidth:"235px",
                iconHeight:"55px"    
            }
        }
    ];

    const handleBusinessValVideoClick = (eventType, toggleStatus) => {
        setVideoModalStatus(toggleStatus);
        sendActivityTracking({
            eventPage,
            eventType
        })
    }
    return (
        <div className="business-val-steps-main">
            <div className='business-val-steps-info-container'>
                <p className='business-val-steps-heading'>How it works</p>
                <div className='business-val-steps-wrapper'>
                    {businessValuationSteps?.map((businessValObj, i) => {
                        return (
                            <BusinessValuationStepBox
                                index={i + 1}
                                heading={businessValObj.heading}
                                subHeading={businessValObj.subHeading}
                                key={`businessValuationStep_${i}`}
                                iconObj={businessValObj.icon}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
})

const BusinessValuationStepBox = (props) => {
    const {
        heading = "",
        subHeading = "",
        iconObj= {},
    } = props;

    return <div className='business-val-step-box'>
        <div className='business-val-step-box-icon-wrapper'>            
            <MediaQuery minWidth={992} >
                <img src={iconObj.iconSrc} width={iconObj.iconWidth} height={iconObj.iconHeight} />
            </MediaQuery>         
            <MediaQuery maxWidth={991.98} minWidth={576} > 
                <img src={iconObj.tabletImageSrc ? iconObj.tabletImageSrc : iconObj.iconSrc} width={iconObj.iconWidth} height={iconObj.iconHeight} />
            </MediaQuery>       
            <MediaQuery maxWidth={575.98} >
                <img src={iconObj.mobileImageSrc ? iconObj.mobileImageSrc : iconObj.iconSrc} width={iconObj.iconWidth} height={iconObj.iconHeight} />
            </MediaQuery>            
        </div>
        <div className='business-val-info-box'>
            <p className='business-val-info-box-heading'>
                {heading}
            </p>
            <p className='business-val-info-box-sub-heading'>{subHeading}</p>
        </div>
    </div>
}
