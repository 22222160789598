import React, { PureComponent } from 'react';
import './TreeMapParent.css';
import isEqual from 'lodash.isequal';
import D3TreeMap from './D3TreeMap';
import { isBrowser } from '../../common/config';
class TreeMapParent extends PureComponent {
    state = {
        processGraphData: { RegRegion: "UK", children:[] }
    }

    componentDidMount = () => {
        let processGraphData = {
            RegRegion: "UK",
            children: [
                ...this.props.graphData
            ]
        };
        this.setState({ processGraphData });
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps && this.props &&
            !isEqual(prevProps.graphData, this.props.graphData)) {
            let processGraphData = {
                RegRegion: "UK",
                children: [
                    ...this.props.graphData
                ]
            };
            this.setState({ processGraphData });
        }
    }

    render() {
        const {
            screen = {},
            sendActivityTracking = () => {},
            onClickUrl = () => {},
            onClickEventType = "",
            heading = ""
        } = this.props;
        const {
            processGraphData = {}
        } = this.state;
        return (
            <div id="tree_map_parent">
                <h2 className="industry-page-section-header" pomandae2e={heading}>{heading}</h2>
                <div className="graph-wraper">
                    { isBrowser && <D3TreeMap  
                        data={processGraphData}
                        screen={screen}
                        sendActivityTracking={sendActivityTracking} 
                        onClickUrl={onClickUrl}
                        onClickEventType={onClickEventType}
                        resetAllSearchParams={this.props.resetAllSearchParams}/>}
                </div>
            </div>
        );
    }
}

export default TreeMapParent;