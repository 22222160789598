export function convertToUKCurrency(value) {
    try {
        return '£' + Number(value).toLocaleString('en-GB');
    } catch (e) {
        console.log('inside convertToUKCurrency ', e);
        throw e;
    }
}

export function convertToUkCurrencyWithoutPoundSymbol(value) {
    try {
        if(value !== undefined && value !== "undefined" && value !== null){
            return Number(value).toLocaleString('en-GB');
        } else {
          return ""  
        }
    } catch (e) {
        console.log('inside convertToUKCurrency ', e);
        throw e;
    }
}

export function convertAmtToKMB(labelValue, _index, _values) {
    // Nine Zeroes for Billions
    let isValueNegative = labelValue < 0 ? true : false;
    let absLabelValue=Math.abs(Number(labelValue)) >= 1.0e+9

        ? Math.abs(Number(labelValue)) / 1.0e+9 + "b"
        // Six Zeroes for Millions 
        : Math.abs(Number(labelValue)) >= 1.0e+6

            ? Math.abs(Number(labelValue)) / 1.0e+6 + "m"
            // Three Zeroes for Thousands
            : Math.abs(Number(labelValue)) >= 1.0e+3

                ? Math.abs(Number(labelValue)) / 1.0e+3 + "k"

                : Math.abs(Number(labelValue));
    
    return isValueNegative?"-".concat(absLabelValue):absLabelValue;
}

export function convertAmtToKMBWithPrecision(labelValue, precision) {
    // Nine Zeroes for Billions
    let isValueNegative = labelValue < 0 ? true : false;
    let absLabelValue=Math.abs(Number(labelValue)) >= 1.0e+9

        ? roundUpCash(Math.abs(Number(labelValue)) / 1.0e+9,1) + "b"
        // Six Zeroes for Millions 
        :  Math.abs(Number(labelValue)) >= 1.0e+6

            ? roundUpCash(Math.abs(Number(labelValue)) / 1.0e+6,1) + "m"
            // Three Zeroes for Thousands
            :  Math.abs(Number(labelValue)) >= 1.0e+3

                ? roundUpCash(Math.abs(Number(labelValue)) / 1.0e+3,1) + "k"

                : roundUpCash(Math.abs(Number(labelValue)),1);
    
    return isValueNegative?"-".concat(absLabelValue):absLabelValue;
}

export function convertAmtToKMBWithoutPrecision(labelValue) {
    // Nine Zeroes for Billions
    let isValueNegative = labelValue < 0 ? true : false;
    let absLabelValue=Math.abs(Number(labelValue)) >= 1.0e+9

        ? roundUpCash(Math.abs(Number(labelValue)) / 1.0e+9,0) + "b"
        // Six Zeroes for Millions 
        :  Math.abs(Number(labelValue)) >= 1.0e+6

            ? roundUpCash(Math.abs(Number(labelValue)) / 1.0e+6,0) + "m"
            // Three Zeroes for Thousands
            :  Math.abs(Number(labelValue)) >= 1.0e+3

                ? roundUpCash(Math.abs(Number(labelValue)) / 1.0e+3,0) + "k"

                : roundUpCash(Math.abs(Number(labelValue)), 0);
    
    return isValueNegative?"-".concat(absLabelValue):absLabelValue;
}

export function convertAmtToWordsWithoutPrecision(labelValue) {
    // Nine Zeroes for Billions
    let isValueNegative = labelValue < 0 ? true : false;
    let absLabelValue=Math.abs(Number(labelValue)) >= 1.0e+9

        ? roundUpCash(Math.abs(Number(labelValue)) / 1.0e+9,0) + " billion"
        // Six Zeroes for Millions 
        :  Math.abs(Number(labelValue)) >= 1.0e+6

            ? roundUpCash(Math.abs(Number(labelValue)) / 1.0e+6,0) + " million"
            // Three Zeroes for Thousands
            :  Math.abs(Number(labelValue)) >= 1.0e+3

                ? roundUpCash(Math.abs(Number(labelValue)) / 1.0e+3,0) + " Thousand"

                : roundUpCash(Math.abs(Number(labelValue)),1);
    
    return isValueNegative?"-".concat(absLabelValue):absLabelValue;
}

export function roundUpCash(num, precision=0)   {
    precision = Math.pow(10, precision)
    let value = parseFloat(Math.round(num * precision) / precision, 1);
    return isNaN(value) ? 0 : value;
}

export function rountUpIndustryMultiple(labelValue, _index, _values) {
    let precision;
    precision = Math.pow(10, 2);
    let value = parseFloat(Math.round(labelValue * precision) / precision, 1);
    return isNaN(value) ? 0 : value+"x";
}

export function convertAmtToMillionBillion(labelValue) {
    // Nine Zeroes for Billions
    let isValueNegative = labelValue < 0 ? true : false;
    let absLabelValue=Math.abs(Number(labelValue)) >= 1.0e+9

        ? roundUpCash(Math.abs(Number(labelValue)) / 1.0e+9,1) + " billion"
        // Six Zeroes for Millions 
        :  Math.abs(Number(labelValue)) >= 1.0e+6

            ? roundUpCash(Math.abs(Number(labelValue)) / 1.0e+6,1) + " million"
            // Three Zeroes for Thousands
            :  Math.abs(Number(labelValue)) >= 1.0e+3

                ? roundUpCash(Math.abs(Number(labelValue)) / 1.0e+3,1) + " thousand"

                : roundUpCash(Math.abs(Number(labelValue)),1);
    
    return isValueNegative?"-".concat(absLabelValue):absLabelValue;
}

export function convertAmtToUKCurrencyAndKMBWithPrecision(labelValue, precision) {
    // Nine Zeroes for Billions
    let isValueNegative = labelValue < 0 ? true : false;
    let absLabelValue=Math.abs(Number(labelValue)) >= 1.0e+9

        ?  roundUpCash(Math.abs(Number(labelValue)) / 1.0e+9,1).toLocaleString('en-GB') + "b"
        // Six Zeroes for Millions 
        :  Math.abs(Number(labelValue)) >= 1.0e+6

            ? roundUpCash(Math.abs(Number(labelValue)) / 1.0e+6,1).toLocaleString('en-GB') + "m"
            // Three Zeroes for Thousands
            :  Math.abs(Number(labelValue)) >= 1.0e+3

                ? roundUpCash(Math.abs(Number(labelValue)) / 1.0e+3,1).toLocaleString('en-GB') + "k"

                : roundUpCash(Math.abs(Number(labelValue)),1);
    
    return isValueNegative?"-".concat(absLabelValue):('£' + absLabelValue);
}

export const getFormattedValue = (value, format, postFixSymbol) => {
    const clonedValue =  value && value !== "undefined" ? value : 0
    switch (format) {
        case "regionalCurrencyWithKMB":
            return `${convertAmtToKMBWithoutPrecision(clonedValue)}`;
        case "currencyFormatWithoutPoundSymbol":
            return convertToUkCurrencyWithoutPoundSymbol(clonedValue);
        case "valueWithPostFixSymbol":
            const currency = postFixSymbol === "%" ? clonedValue * 100 : clonedValue;
            return convertToUkCurrencyWithoutPoundSymbol(currency) + postFixSymbol
        default:
            return clonedValue
    }
}

export const getCurrencySymbol = (currency) => {
    switch (currency) {
        case "aud":
            return "A$";
        case "chf":
            return "CHF";
        case "eur":
            return "€";
        case "gbp":
            return "£";
        case "jpy":
            return "JP¥"; 
        case "usd":
            return "US$";    
        default:
            return "US$"
    }
}

