import React, { useState } from 'react'
import './BuyBusinessValuation.css'
import { useDispatch, useSelector } from 'react-redux'
import { SR_VALUATION_REPORT_PKG_PURCHASE } from '../../../common/subscriptionConfigConst'
import { handleProductPlanButtonClick } from '../../../redux/actions/productAction'
import localStorageApi from '../../../api/localStorageApi';
import { CompanyValConfirmationModal } from '../../CompanyProfile/CompanyValConfirmationModal/CompanyValConfirmationModal'
import { ET_GENERATE_VALUATION_BTN_CLICK, ET_VALUATION_REPORT_BUY_CONFIRMATION_NO_CLICK, ET_VALUATION_REPORT_BUY_CONFIRMATION_YES_CLICK, LOGIN_TOGGLE_FOR_ANONYMOUS_USER, SIGNUP_EVENT_PAGE } from '../../../common/trackingConstants'
import { sendActivityTracking } from '../../../redux/actions/commonAction'
import { updateValuationReportCreditRequest } from '../../../redux/actions/userAccountAction'
import { withRouter } from 'react-router';


function BuyBusinessValuation(props) {

    const reduxValuationObj = useSelector(state => state.valuation?.valutionOnboardObj) || {}
    const userInfo = useSelector(state => state?.userProfile?.userInfo)||{}
    const valuationRpCount = useSelector(state => state.userProfile.userInfo?.valuationReportPkgSubscription?.valuationRpCount)||0
    const dispatch = useDispatch()
    const [companyValuationConfirmationModal, setCompanyValuationConfirmationModal] = useState(false)

    const onBuyBtnClick = () => {
        const {
            companyName = "",
        } = reduxValuationObj;
        const { email } = userInfo;

        let token = localStorageApi.getUserAuthToken();
        dispatch(sendActivityTracking({
            eventPage: props.eventPage,
            eventType: ET_GENERATE_VALUATION_BTN_CLICK,
            attribute1: companyName,
            attribute2: token ? email : SIGNUP_EVENT_PAGE
        }))
        if (valuationRpCount > 0) {
            setCompanyValuationConfirmationModal(true);
        } else {
            onPaymentFlowBtnClick();
        }
    }

    const onPaymentFlowBtnClick = () => {
        const { companyName = "",
            locationObj,
            location,
            revenueSales,
            industryList,
            industryFinder
        } = reduxValuationObj
        const companyId = `val_${new Date().getTime()}${Math.floor(Math.random() * 10)}`
        const businessNameforUrl = encodeURIComponent(companyName.toString().replace(/ /g, "-").toLowerCase())
        dispatch(handleProductPlanButtonClick({
            planSelected: "ai_oneTime1ValuationReportPkg",
            serviceRequested: SR_VALUATION_REPORT_PKG_PURCHASE,
            href: `${window.location.origin}/company/${companyId}/${businessNameforUrl}/valuation`,
            period: "VALUATION_REPORT_PKG",
            companyId,
            companyName,
            productId: "ai_oneTime1ValuationReportPkg",
            isErrorHref: window.location.href,
            saveValuationObj: {
                locationObj,
                location,
                revenueSales,
                companyId,
                industryList,
                industryFinder,
                companyName
            }
        }, {
            eventPage: props?.eventPage
        }))
    }
    const toggleCompanyValuationConfirmationModal = (status, trackingObj) => {
        setCompanyValuationConfirmationModal(status);
        dispatch(sendActivityTracking({
            eventPage: props?.eventPage,
            ...trackingObj
        }))
    }
    const buyValuationCards = [{
        heading: "Edit & Customise",
        desc: "Fine tune the P&L and Balance Sheet with your latest financial data.",
        icon: "assets/svgs/pom_ai_valuation_calculator_icon.svg"
    }, {
        heading: "Valuation Calculator",
        desc: "Model different scenarios using the Valuation Calculator for 30 days post purchase.",
        icon: "assets/svgs/pom_ai_generate_val_report_icon.svg"
    },
    {
        heading: "Download Report",
        desc: "Instantly generate a 17 page valuation report with 6 different valuation methods.",
        icon: "assets/svgs/pom_ai_valuation_access_limit_icon.svg"
    }]

    const { companyName = "" } = reduxValuationObj
    const { email="" } = userInfo;
    return (
        <div className='buy-val-wrapper'>
            <img src='assets/svgs/pom_ai_square_shape_success_orange_icon.svg' width={35} height={35} alt='square shape success orange icon' />
            <h3 className='buy-val-heading'>Thanks, we're ready to start valuing this business</h3>
            <div className='buy-val-container'>
                {buyValuationCards.map(cardObj => <div key={cardObj.heading} className='buy-val-card-container'>
                    <img src={cardObj.icon} width="100%" height={170} alt={`${cardObj.heading} icon`} />
                    <div className='buy-val-sub-container'>
                        <h5 className='buy-val-container-heading'>{cardObj.heading}</h5>
                        <p className='buy-val-container-desc'>{cardObj.desc}</p>
                    </div>
                </div>)}
            </div>
            <div className='valuation-right-side-btn-wrapper'>
                <button className='valuation-right-side-component-btn footer-active-btn' onClick={() => onBuyBtnClick()}>Access Valuation Calculator</button>
            </div>
            {companyValuationConfirmationModal && <CompanyValConfirmationModal
                remainingCredits={valuationRpCount}
                yesOnclick={() => {
                    const businessNameforUrl = encodeURIComponent(companyName.toString().replace(/ /g, "-").toLowerCase())
                    const companyId = `val_${new Date().getTime()}${Math.floor(Math.random() * 10)}`
                    dispatch(updateValuationReportCreditRequest({
                        onboard: { ...reduxValuationObj, companyId },
                        userEmail: email,
                        eventPage: props.eventPage,
                        serviceRequested: "ai_oneTime1ValuationReportPkg"
                    }))
                    toggleCompanyValuationConfirmationModal(false, {
                        eventType: ET_VALUATION_REPORT_BUY_CONFIRMATION_YES_CLICK,
                        attribute1: companyName,
                        attribute2: email
                    })
                    const path = `/company/${companyId}/${businessNameforUrl}/valuation`;
                    props.history.push(path);
                }}
                noOnclick={() => {
                    toggleCompanyValuationConfirmationModal(false, {
                        eventType: ET_VALUATION_REPORT_BUY_CONFIRMATION_NO_CLICK,
                        attribute1: companyName,
                        attribute2: email
                    })
                }}
            />}
        </div>
    )
}

export default withRouter(BuyBusinessValuation)