import React, { memo } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import './SubmitInterestModal.css';
import SubmitInterestForm from './SubmitInterestForm';
import { toggleSubmitInvestmentInterestModal } from '../../../../redux/actions/companyAction';

const SubmitInterestModal = (props) => {
    const toggle = (toggleStatus) => {
        props.dispatchToggleSubmitInvestmentInterestModal({toggleStatus});
    }
    const {
        submitInterestModalStatus = {},
        userInfo = {}
    } = props;

    const {
        toggleStatus = false,
        companyId = null,
        subject = "",
        subHeading = "",
        eventPage = "",
        eventType = "",
        userMessage = "",
        heading = "submit interest",
        descriptionLabel = undefined,
        mailRecipient = undefined,
    } = submitInterestModalStatus;

    const {
        email = "",
    } = userInfo;

    return (
        <Modal isOpen={toggleStatus} id="submit_interest_modal"
            backdropClassName="submit-interest-backdrop"
            modalClassName="submit-interest-modal">
            {/* <ModalHeader className="pt-2 border-none" toggle={() => props.toggle(false)} /> */}
            <ModalBody>
                <span className="submit-interest-modal-close-btn" onClick={() => toggle(false)} >
                    <i className="fa fa-times " pomandae2e="Register Your Interest"></i>
                </span>
                <p className="heading-text">{heading}</p>
                <p className="sub-heading-text">{subHeading}</p>
                <SubmitInterestForm
                    loggedInUserEmail={email}
                    companyId={companyId}
                    subject={subject}
                    interestDescription={userMessage}
                    interestDescriptionLabel={descriptionLabel}
                    mailRecipient={mailRecipient}
                    trackingInfo = {{
                        eventPage,
                        eventType,
                        attribute1: companyId
                    }} />
            </ModalBody>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    submitInterestModalStatus: state.company.submitInterestModalStatus,
    userInfo: state.userProfile.userInfo
});

const mapDispatchToProps = (dispatch) => ({
  dispatchToggleSubmitInvestmentInterestModal: (toggleStatus) => dispatch(toggleSubmitInvestmentInterestModal(toggleStatus))
})

export default memo(connect(mapStateToProps,mapDispatchToProps)(SubmitInterestModal));

