import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './HomePageFooter.css';
import { withRouter } from 'react-router';
import { toggleLoginModal } from '../../../redux/actions/loginSignupAction';
import localStorageApi from '../../../api/localStorageApi';
import { 
    EP_ACCOUNT, ET_ACCOUNT_ONLOAD, FOOTER_ACCOUNT_BUTTON, 
    ET_POWER_SEARCH_COMPANY_ONLOAD, HOMEPAGE_FOOTER_SEARCH_BUTTON,
    FOOTER_POPULAR_COMPANIES_BUTTON, FOOTER_TERMS_LINK,
    ET_POPULAR_COMPANIES_CLICK, EP_FOOTER, ET_TERMS_OF_USE_CLICK,
    ET_PRIVACY_POLICY_CLICK, FOOTER_PRIVACY_POLICY_LINK, LOGIN_EVENT_PAGE, 
    LOGIN_TOGGLE_ON_EVENT_TYPE, TOGGLE_SELF_CLICK, ET_POWER_SEARCH_DIRECTOR_ONLOAD, 
    ET_POWER_SEARCH_INDUSTRY_ONLOAD, ET_POWER_SEARCH_SHAREHOLDER_ONLOAD, FOOTER_CONTACT_US_LINK, 
} from '../../../common/trackingConstants';
import { sendActivityTracking } from '../../../redux/actions/commonAction';
import { setActivityTrackingData } from '../../../redux/actions/searchAction';
import analyticsApi from '../../../api/analyticsApi';
import {GA_PS_BTN_TAG,isBrowser } from "../../../common/config";
import { TOGGLE_LOGIN_MODAL_ON_SUCCESS } from '../../../common/loginSignupSuccessConstants';
import { getOnloadEventType } from '../../common/PowerSearchFunctions';
import { toggleSubmitInvestmentInterestModal } from '../../../redux/actions/companyAction';
import MediaQuery from 'react-responsive';

class HomePageFooter extends Component {

    openLoginModal = () => {
        this.props.toggleLoginModal({ status:true }, { 
            eventPage: LOGIN_EVENT_PAGE, 
            eventType: LOGIN_TOGGLE_ON_EVENT_TYPE, 
            attribute1: TOGGLE_SELF_CLICK 
        },{
            toggleType: TOGGLE_LOGIN_MODAL_ON_SUCCESS
        });
    }

    goToAccountPage = () => {
        this.props.dispatchSendActivityTracking({
            eventPage: EP_ACCOUNT,
            eventType : ET_ACCOUNT_ONLOAD,
            attribute1: FOOTER_ACCOUNT_BUTTON,
        });
        this.props.history.push("/account/profile");
    }

    goToHomePage = () => {
        this.props.history.push("/");
    }

    goToPowerSearchPage = (searchType) => {
        this.props.setActivityTrackingData({ obj: { "eventCreated": HOMEPAGE_FOOTER_SEARCH_BUTTON, "eventType": getOnloadEventType(searchType) }, type: "powerSearch" })
        switch (searchType) {
            case "company":
                this.props.history.push("/powersearch/company");
                break;
            case "director":
                this.props.history.push("/powersearch/director");
                break;
            case "industry":
                this.props.history.push("/powersearch/industry");
                break;
            case "charges":
                this.props.history.push("/powersearch/charges");
                break;
            case "shareholder":
                this.props.history.push("/powersearch/shareholder");
                break;
            case "region":
                this.props.history.push("/powersearch/region");
                break;
            default:
                return {}
        }
        analyticsApi.gtag_report_conversion(GA_PS_BTN_TAG)
    }

    getActivityTrackingForPopularCompanies = () => {
        this.props.dispatchSendActivityTracking({
            eventPage: EP_FOOTER,
            eventType : ET_POPULAR_COMPANIES_CLICK,
            attribute1: FOOTER_POPULAR_COMPANIES_BUTTON,
            attribute2: "a",
        });
    }

    getActivityTrackingForTermsPdf = () => {
        this.props.dispatchSendActivityTracking({
            eventPage: EP_FOOTER,
            eventType : ET_TERMS_OF_USE_CLICK,
            attribute1: FOOTER_TERMS_LINK,
        });
    }

    getActivityTrackingForPrivacyPolicy = () => {
        this.props.dispatchSendActivityTracking({
            eventPage: EP_FOOTER,
            eventType : ET_PRIVACY_POLICY_CLICK,
            attribute1: FOOTER_PRIVACY_POLICY_LINK,
        });
    }

    handleContactUsClick = () => {
        this.props.dispatchSubmitInterestModal({
            toggleStatus: true,
            subject: "Contact Us",
            subHeading:"",
            heading: "Contact Us",
            userMessage:"What can we help with?",
            descriptionLabel: "Get in touch",
            mailRecipient: "CONTACT_US_EMAIL_ADDRESS",
            eventPage: EP_FOOTER,
            eventType: FOOTER_CONTACT_US_LINK
        });
    }

    render() {
        let userTrackerObj = isBrowser && localStorageApi.getUserData()
        let showAccMenu = false;
        if (userTrackerObj && userTrackerObj.accessToken && userTrackerObj.accessToken !== "") {
            showAccMenu = true;
        }
        const { whiteLabelConfig = {}
        } = this.props;
        const { headerDecoration } = this.props;

        return (
            <div className="homepage-footer">
                <div className="homepage-footer-nav-section">
                <div className={`homepage-footer-image-section ${whiteLabelConfig?.footer ? "prosper_homepage-footer-image_section" : ""}`}>
                        <>
                            {whiteLabelConfig.footer ?
                                <picture>
                                    <source type="image/webp" srcSet={whiteLabelConfig.footer.sourceset} />
                                    <img src={whiteLabelConfig.footer.image} className="lazyload" alt="pomanda" />
                                </picture> :
                                <picture>
                                    <source type="image/webp" srcSet="/assets/webpImages/pomandaFooter.webp" />
                                    <img src="/assets/images/pomandaFooter.png" className="lazyload" alt="pomanda" />
                                </picture>}
                        </>
                    </div>                   
                    {headerDecoration && <MediaQuery maxWidth={575.75}>
                            <div className='header-decoration'></div>
                        </MediaQuery>}
                    <div className="homepage-footer-nav ml-sm-auto"><Link to="/faqs">FAQ</Link></div>
                    <div className="homepage-footer-nav " pomandae2e="BlogDetails"><Link to="/blog">blog</Link></div>
                    <div className="homepage-footer-nav" pomandae2e="popular-companies"><Link to="/popular-companies/a/1" onClick={this.getActivityTrackingForPopularCompanies}>popular companies</Link></div>
                    {showAccMenu ?
                        <div className="homepage-footer-nav" pomandae2e="accountbutton" onClick={this.goToAccountPage}>ACCOUNT</div> :
                        <div className="homepage-footer-nav" onClick={this.openLoginModal} pomandae2e="Log In">LOG IN</div>}
                    <div className="homepage-footer-nav" onClick={() => this.goToPowerSearchPage("company")} pomandae2e="search">search</div>
                    <div className="homepage-footer-nav" onClick={this.handleContactUsClick} pomandae2e="search">contact us</div>
                </div>
                <p className="homepage-footer-copyright mb-0">
                    © pomanda 2018. All rights reserved. 
                    <Link to="/assets/Download/Terms_Of_Use.pdf" target="_blank"
                        rel="noopener noreferrer" onClick={this.getActivityTrackingForTermsPdf}><u> Terms&nbsp;of&nbsp;Use</u></Link> and
                    <Link to="/assets/Download/Pomanda_Privacy_Notice.pdf" target="_blank"
                        rel="noopener noreferrer" onClick={this.getActivityTrackingForPrivacyPolicy}><u> Privacy&nbsp;Policy </u></Link>
                    applied. Finfind ltd T/A Pomanda 
                </p>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    token: state.loginSignup.token,
    whiteLabelConfig: state.common.whiteLabelConfig
});

const mapDispatchToProps = (dispatch) => ({
    toggleLoginModal: (toggleStatus, trackingInfo, onSuccessRender) => dispatch(toggleLoginModal(toggleStatus, trackingInfo, onSuccessRender)),
    dispatchSendActivityTracking: (trackingObj) => dispatch(sendActivityTracking(trackingObj)),
    setActivityTrackingData: trackingObj => dispatch(setActivityTrackingData(trackingObj)),
    dispatchSubmitInterestModal: (submitInterestModalObj) => dispatch(toggleSubmitInvestmentInterestModal(submitInterestModalObj))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomePageFooter));