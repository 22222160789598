import React from "react";
import "./NewEntrantLeaversComparison.css";
import { Line } from "react-chartjs-2";
import moment from 'moment';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
const tooltipData = `The chart highlights the [6 month moving average] number of company formations ('New Entrants') 
and number of company dissolutions ('Leavers');`

const NewEntrantLeaversComparison = (props) => {
    const {
        heading = "",
        newEntrantsVSLeaversArray = [],
        graphHeight = 0,
        graphWidth = 0,
        profilePage = ""
    } = props;
    let dateList = [];
    let incTotListMA = [];
    let disTotListMA = [];
    newEntrantsVSLeaversArray.forEach(data => {
        dateList.push((moment(data.AccToDate).format("MMM YYYY")));
        if(profilePage === "industry"){
            incTotListMA.push(data.mov_avg_IncTot_6m === null ? 0 : Math.round(data.mov_avg_IncTot_6m));
            disTotListMA.push(data.mov_avg_DisTot_6m === null ? 0 : Math.round(data.mov_avg_DisTot_6m));
        } else {
            incTotListMA.push(data.IncTot === null ? 0 : Math.round(data.IncTot));
            disTotListMA.push(data.DisTot === null ? 0 : Math.round(data.DisTot));
        }
    })
    const maxValue = incTotListMA.length>0 && disTotListMA.length>0 && Math.max(...incTotListMA, ...disTotListMA);
    const roundedMaxValue = Math.round(maxValue/10) * 10;
    const minValue = incTotListMA.length>0 && disTotListMA.length>0 && Math.min(...incTotListMA, ...disTotListMA);
    const roundedMinValue = Math.round(minValue/10) * 10;
    const opt = {
        maintainAspectRatio: false,
        legend: {
            display: false
        },
        elements: {
            line: {
                tension: 0,
                lineWidth: 0
            }
        },
        scales: {
            yAxes: [{
                    gridLines: {
                        drawBorder: false,
                        display: false  
                    },
                    ticks: {
                        callback: function (value, index, values) {
                            return index === 0 || index === values.length - 1 ? index === 0 ? roundedMaxValue : roundedMinValue : null;
                        },
                    }
                }
            ],
            xAxes: [{
                ticks: {
                    autoSkip: true,
                    callback: function (value, index, values) {
                        return moment(value, "MMM YYYY").format("YYYY");
                    },
                },
                gridLines: {
                    drawBorder: false,
                    display: false
                },
                type: 'time',
            }],
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, _data) {
                    return tooltipItem.value;
                },
                title: function (tooltipItem, _data) {
                    return moment(tooltipItem[0].label).format("MMM YYYY");
                }
            }
        }
    };
    const getData = () => {
        return {
            labels: dateList.map(year => moment(year, "MMM YYYY").toDate()),
            pointRadius: 0,
            datasets: [{
                label: "Leavers",
                data: disTotListMA,
                fill: true,
                borderColor: "#E19D32",
                backgroundColor: "rgba(0,0,0,0)",
                borderCapStyle: "square",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderWidth: 1,
                pointBackgroundColor: "#E19D32",
                pointHoverRadius: 5,
                pointRadius: 0,
                pointHitRadius: 10,
                datalabels: {
                    display: false
                }
            },
            {
                label: "New Entrants",
                data: incTotListMA,
                fill: true,
                borderColor: "#3DAEEA",
                backgroundColor: "rgba(0,0,0,0)",
                borderCapStyle: "square",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderWidth: 1,
                pointBackgroundColor: "#3DAEEA",
                pointHoverRadius: 5,
                pointRadius: 0,
                pointHitRadius: 10,
                datalabels: {
                    display: false
                }
            }
            ]
        }
    };
    return (
        <>
            <div className="industry-comparison-chart-heading" pomandae2e={heading}>
                <p>{heading}
                    <span className="industry-comparison-title-prefix"> New Entrants </span>vs
                    <span className="industry-comparison-title-suffix"> Leavers</span>
                    <span className="industry-comparison-chart">
                    <i id="industry-comparison" className="ml-1 fas fa-question-circle">
                        <UncontrolledTooltip placement="right" target="industry-comparison" innerClassName="tooltip-inner-content">
                        {tooltipData}
                        </UncontrolledTooltip>
                    </i>
                    </span>
                </p>
            </div>
            <div className="industry-comparison-chart-div" pomandae2e="industry-comparison-chart">
                { graphHeight && graphHeight > 0 && graphWidth && graphWidth > 0 
                    ? <Line data={() =>getData()} options={opt} height={300} width={300}/> 
                    : <Line data={() =>getData()} options={opt}/>}
            </div>
        </>
    );
}

export default NewEntrantLeaversComparison;