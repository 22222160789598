module.exports = {
    EDIT_INDUSTRY_MULTIPLE : {
        heading: "Having an accurate industry classification is essential!",
        subHeading: "To improve the accuracy of your Valuation, Scorecard, and Estimates, we recommend that you update the industry breakdown to resemble the business operations."
    },
    INDUSTRY_CLICK_IN_ABOUT_SECTION : {
        heading: "Would you like to update the industry breakdown?",
        subHeading: "Create a customised industry breakdown that more accurately resembles the business operations and we will re-calculate the Pomanda rating, financial estimates & valuation"
    },
    AGREE_NO_VALUATION : {
        heading: "Possibly we are using the wrong industry multiples?",
        subHeading: "Create a customised industry breakdown that more accurately resembles the business operations and we will re-calculate the valuation."
    },
    AGREE_NO_SCORE: {
        heading: "Possibly we are comparing against the wrong industry averages?",
        subHeading: "Create a customised industry breakdown that more accurately resembles the business operations and we will re-calculate the Pomanda Health Check."
    },
    PERFORMACE_INDICATOR_TABLE_VALUE_EDIT: {
        heading: "Possibly we are estimating using the wrong industry data?",
        subHeading: "Create a customised industry breakdown that more accurately resembles the business operations and we will re-calculate the Pomanda Estimated Financials."
    },
    AGREE_NO_FINANCIAL: {
        heading: "Possibly we are estimating with the wrong industry averages?",
        subHeading: "Create a customised industry breakdown that more accurately resembles the business operations and we will re-calculate the Pomanda estimated financial data."
    },
    VALUATION_CALCULATOR_WITH_NO_FINANCIAL: {
        heading: "What industry(s) is this business involved in?",
        subHeading: "Enter the industry breakdown that resembles the business operations and we will calculate the valuation."
    }
}