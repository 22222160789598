import React, { PureComponent } from 'react'
import './ProfilePageHeader.css';
import MediaQuery from 'react-responsive';

class ProfilePageHeader extends PureComponent {
    toggleShareHandler = () => {    
        const shareUrl = `${window.location.origin}${window.location.pathname}`;
        
        this.props.toggleShareUrlModal({
            show: true,
            urlToShare: shareUrl,
            urlToShareEmail: `I found this information on the Pomanda Power Search. Click here to view it now.\r\n\r\n ${shareUrl}`,
            subjectToShare: "Check out these results from the Pomanda Power Search.",
            eventPage: this.props.eventPage
        });
        this.props.sendActivityTracking({ eventType: this.props.shareButtonEventType, attribute3: shareUrl });
    }
    seeMoreBtnClick = () => {
        this.props.seeMoreIndustryModelClick({ toggleStatus: true, searchEntity: this.props.pageName });
        this.props.sendActivityTracking({ eventType: this.props.seeMoreButtonEventType });
    }

    render() {
        const { heading="" } = this.props;
        let today = new Date()
        today.setMonth(today.getMonth() - 1)
        let insightDate = `${today.toLocaleString('default', { month: 'long' })} ${today.getFullYear()}`

        return (
            <div className="industry-page-header">
                <div className="industry-page-date">
                    INSIGHTS - {insightDate.toUpperCase()}
                </div><br />
                <div className="industry-profile-page-header-heading">
                    <div className="industry-page-title">
                        {heading}
                        <MediaQuery minWidth={576}>
                            <button type="button" className="industry-page-seemore-button" data-toggle="modal"
                                onClick={this.seeMoreBtnClick} pomandae2e="SEE_MORE">
                                <div className="industry-see-more-btn-text" pomandae2e="See-More"><i className="fas fa-angle-down "></i>
                                <i className="industry-page-seemore" >SEE MORE</i>
                                </div>
                            </button>
                        </MediaQuery>
                        <MediaQuery maxWidth={575.98}>
                            <span className="industry-page-seemore-span">
                                <i className="fas fa-angle-down " onClick={this.seeMoreBtnClick} pomandae2e="industry-page-seemore-span"></i>
                            </span>
                        </MediaQuery>
                    </div>
                    <MediaQuery minWidth={576}>
                        <button className="industry-page-share-button" pomandae2e="SHARE" onClick={this.toggleShareHandler}>
                            SHARE</button>
                    </MediaQuery>
                </div>
            </div>
        )
    }
}

export default ProfilePageHeader;