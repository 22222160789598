
import localStorageApi from './localStorageApi';
import { sendActivityTrackingApi } from "./trackerApi";

export const refreshTokenApi = async (URL, method, body, headers) => {
    try {
        let userTrackerObj = localStorageApi.getUserData()
        const resttoken = localStorageApi.getCookie("resttoken")
        headers.set("resttoken", resttoken)
        if (userTrackerObj) {
            headers.set("trackerid", userTrackerObj.trackerId)
            headers.set("sessionid", userTrackerObj.sessionId)
            if (userTrackerObj.accessToken !== "") {
                headers.set("accesstoken", userTrackerObj.accessToken)
            }
        } else {
            headers.set("trackerid", "")
            headers.set("sessionid", "")
        }

        let sendData = {}
        if (method === "POST") {
            sendData = {
                method: method,
                body: JSON.stringify(body),
                headers: headers
            }
        } else if (method === "PUT") {
            sendData = {
                method: method,
                body: body,
                headers: headers
            }
        } else {
            sendData = {
                method: method,
                headers: headers
            }
        }

        let response = await fetch(URL, sendData);

        if (response.status === 200) {
            const data = await response.json();
            if (data.refreshToken && data.refreshToken !== 'undefined') {
                window.localStorage.setItem('refreshToken', data.refreshToken)
                localStorageApi.removeCookie("refreshToken")
                localStorageApi.setCookie('refreshToken', data.refreshToken)
                if (localStorageApi.getCookie("accessToken")) {
                    localStorageApi.removeCookie("accessToken")
                }
                localStorageApi.setCookie('accessToken', data.refreshToken)
                userTrackerObj.accessToken = data.refreshToken
                localStorageApi.setUserData(userTrackerObj)
                headers.set("refreshToken", data.refreshToken)
                headers.set("accesstoken", data.refreshToken)
            }
            return data;
        } else if (response.status === 403) {
            const data = await response.json();
            let userTrackerObj = localStorageApi.getUserData()
            userTrackerObj.accessToken = ""
            localStorageApi.setUserData(userTrackerObj)
            if (localStorageApi.getCookie("accessToken")) {
                localStorageApi.removeCookie("accessToken")
            }
            if (localStorageApi.getCookie("refreshToken")) {
                localStorageApi.removeCookie("refreshToken")
            }
            if (localStorageApi.getCookie("sessionEnd") === 'true'){
                userTrackerObj.sessionEnd = 'false'
                localStorageApi.setCookie('sessionEnd', 'false');
                localStorageApi.setUserData(userTrackerObj)
                const actToken = localStorageApi.getActTrackToken();
                const trackObj = {
                    token: actToken,
                    userId: data.userId, 
                    eventPage: 'EP_USER_AUTH_TOKEN', 
                    eventType: data.eventType }
                data.message ? window.alert(data.message) : window.alert('Your session has expired due to inactivity. Please log in again to continue')
                sendActivityTrackingApi(trackObj);
                window.location.assign("/")
            }
        } else if (response.status === 401) {
            const res = await response.json();
            return res
        } else if (response.status === 409) {
            const res = await response.json();
            return res
        } else {
            return await response.json();
        }
    } catch (e) {
        console.log("Error in refreshTokenApi", e);
        throw e
    }
};