import { PROSPER_USER_TYPE } from "../../common/config";
import {
    ET_SOLUTIONS_BUSINESS_PLAN_MENU_CLICK,
    ET_SOLUTIONS_CREDIT_REPORT_MENU_CLICK, ET_SOLUTIONS_ESTIMATE_MENU_CLICK,
    ET_SOLUTIONS_FIND_COMPANY_DIRECTOR_MENU_CLICK, ET_SOLUTIONS_INDUSTRY_MENU_CLICK,
    ET_SOLUTIONS_LEAD_GENERATION_MENU_CLICK, ET_SOLUTIONS_SCORECARD_MENU_CLICK,
    ET_SOLUTIONS_VALUE_BUSINESS_MENU_CLICK
} from "../../common/trackingConstants";


export const solutionsMenuItems = [
    {
        "cardHeading": "Value A Business",
        "cardText": "See what a business is worth - including small & private businesses",
        "icon": "value_yellow.svg",
        "iconOnHover": "value.svg",
        "button": "Learn more",
        link: "solutions/companyvaluation",
        eventType: ET_SOLUTIONS_VALUE_BUSINESS_MENU_CLICK,
        id: "companyvaluation"
    },
    {
        "cardHeading": "AI Business Plans",
        "cardText": "Create a professional business plan with helpful data insights",
        "icon": "businessPlan_yellow.svg",
        "iconOnHover": "businessPlan.svg",
        "button": "Learn more",
        eventType: ET_SOLUTIONS_BUSINESS_PLAN_MENU_CLICK,
        link: "solutions/businessplan",
        id: "businessplan"
    },
    {
        "cardHeading": "Lead Generation",
        "cardText": "Improve your sales by identifying & qualifying key prospects",
        "icon": "lead_gen_yellow.svg",
        "iconOnHover": "lead_gen.svg",
        "button": "Learn more",
        eventType: ET_SOLUTIONS_LEAD_GENERATION_MENU_CLICK,
        link: "solutions/leadgeneration",
        id: "leadgeneration"
    },
    {
        "cardHeading": "Financial Estimates",
        "cardText": "Help fill in the blanks left by Companies House with Pomanda’s estimates",
        "icon": "estimates_yellow.svg",
        "iconOnHover": "estimates.svg",
        "button": "Learn more",
        eventType: ET_SOLUTIONS_ESTIMATE_MENU_CLICK,
        link: "solutions/estimates",
        id: "estimates"
    },
    {
        "cardHeading": "Find Companies & Directors",
        "cardText": "Access billions of data points on UK Companies and directors",
        "icon": "find_companies_yellow.svg",
        "iconOnHover": "find_companies.svg",
        "button": "Learn more",
        link: "solutions/companyinformation",
        eventType: ET_SOLUTIONS_FIND_COMPANY_DIRECTOR_MENU_CLICK,
        id: "companyinformation"
    },
    {
        "cardHeading": "Instant Credit Reports",
        "cardText": "Check out customers and suppliers or see how your own business is rated",
        "icon": "instant_credit_reports_yellow.svg",
        "iconOnHover": "instant_credit_reports.svg",
        "button": "Learn more",
        link: "solutions/companycreditcheck",
        eventType: ET_SOLUTIONS_CREDIT_REPORT_MENU_CLICK,
        id: "companycreditcheck"
    },
    {
        "cardHeading": "Company Health Check",
        "cardText": "Identify a business’s strengths and weaknesses",
        "icon": "health_check_yellow.svg",
        "iconOnHover": "health_check.svg",
        "button": "Learn more",
        eventType: ET_SOLUTIONS_SCORECARD_MENU_CLICK,
        link: "solutions/scorecard",
        id: "healthcheck"
    },
    {
        "cardHeading": "Industry Insights",
        "cardText": "Research and statistics on all the major industries in the UK",
        "icon": "insights_yellow.svg",
        "iconOnHover": "insights.svg",
        "button": "Learn more",
        eventType: ET_SOLUTIONS_INDUSTRY_MENU_CLICK,
        link: "solutions/industry",
        id: "industry"
    }
]

export function getSolutionMenuItems(userType, iswhiteLabelConfig = false) {
    let solutionMenu = [];
    if (userType === PROSPER_USER_TYPE || iswhiteLabelConfig) {
        solutionMenu = solutionsMenuItems.filter(solutionObj => (solutionObj.id !== "companyvaluation" && solutionObj.id !== "companycreditcheck"))
    } else {
        solutionMenu = solutionsMenuItems;
    }

    return solutionMenu;
}

export const imageSection1 = [
    {
        pngImg: "industry1@2x.png",
        webpImg: "industry1@2x.webp",
        alt: "industry1@2x_img"
    },
    {
        pngImg: "industry2@2x.png",
        webpImg: "industry2@2x.webp",
        alt: "industry2@2x_img"
    },
    {
        pngImg: "industry3@2x.png",
        webpImg: "industry3@2x.webp",
        alt: "industry3@2x_img"
    }]
export const imageSection2 = [
    {
        pngImg: "industry4@2x.png",
        webpImg: "industry4@2x.webp",
        alt: "industry4@2x_img"
    }, {
        pngImg: "industry5@2x.png",
        webpImg: "industry5@2x.webp",
        alt: "industry5@2x_img"
    },
    {
        pngImg: "industry6@2x.png",
        webpImg: "industry6@2x.webp",
        alt: "industry6@2x_img"
    }, {
        pngImg: "industry7@2x.png",
        webpImg: "industry7@2x.webp",
        alt: "industry7@2x_img"
    }]