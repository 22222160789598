import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import './IndustryMix.css';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { INDUSTRY_ADD_LIMIT, isBrowser, SAVE_INDUSTRY_MIX_LOADING_MSG } from '../../../common/config';
import isEqual from 'lodash.isequal';
import Highlighter from "react-highlight-words";
import MediaQuery from 'react-responsive';
import {
    COMPANY_PROFILE, ET_OPEN_INDUSTRY_LIST_FOR_INDUSTRY_MIX,
    ET_ADD_INDUSTRIES_TO_BREAKDOWN, ET_REMOVE_INDUSTRY_FROM_BREAKDOWN,
    ET_CHANGE_INDUSTRY_BREAKDOWN_WEIGHT,
    ET_SAVE_INDUSTRY_MIX_CHANGES,
    ET_RESET_INDUSTRY_MIX_CHANGES,
    ET_CLOSE_INDUSTRY_MIX_MODAL
} from '../../../common/trackingConstants';
import localStorageApi from '../../../api/localStorageApi';
import { SR_UPDATE_INDUSTRIES } from '../../../common/subscriptionConfigConst';
import {
    resetIndustryMixChangesFailed, saveIndustryMixChangesFailed, setAddIndustryMixModal, setIndustryMixLoader,
    setIndustryMixUpdateModal, setIndustryMixUpdateSuccess, setUpdatedMixIndustries
} from '../../../redux/actions/companyAction';
import { useDispatch, useSelector } from 'react-redux';
import { getIndustryFilterDataRequest } from '../../../redux/actions/searchAction';
import { sendActivityTracking, setConfirmationModal } from '../../../redux/actions/commonAction';
import { setBusinessPlanOnboardObj } from '../../../redux/actions/businessPlanAction';
import { setBussTempMainPageData } from '../../../redux/actions/businessPlanTemplateAction';
import { addSelectedCompetitor } from '../../../redux/actions/competitorAnalysisAction';
import { setValuationOnboardDataIntoRedux } from '../../../redux/actions/valuationAction';

export const checkMixIndustriesUpdateValid = (industryList) => {
    const reducerFun = (accumulator, currentValue) => accumulator + Number(currentValue.value?.replace(/%/g, ""));
    const totalPercentage = industryList?.reduce(reducerFun, 0);
    return totalPercentage === 100 || industryList.length < 1 ? true : false;
}

export const IndustryMixUpdate = (props) => {

    const {
        industryBreakdownList = [],
        orignalIndustryBreakDownList = [],
        setIndustryBreakdownList = () => { },
        companyId = "",
        saveIndustryMixChanges = () => { },
        industryMixUpdateSuccess = false,
        pageType = "",
        screenWidth = 0,
        setMixIndustriesFilled = () => { },
        resetIndustryMixChangesError = "",
        saveIndustryChangesError = "",
        industryList = [],
        industryMixUpdateModalObj = {},
        userSubscription = {},
        valuationReportMapObj,
        isIndustryMixEdited = true
    } = props;
    const {
        headerClassName = "",
        headerText = "",
        headerSubHeading = "",
        headerSubHeadingTextCss = "",
        headerTextCss = "",
        showHeader = "",
        customSaveChangesBtnObj = {},
        headerBackBtnClick = "",
        eventPage = COMPANY_PROFILE,
        modalClassName = ""
    } = industryMixUpdateModalObj;

    const {
        saveIndMixBtnName = 'save changes',
        isAddCompetitorModalOpen = false
    } = customSaveChangesBtnObj;
    const dispatch = useDispatch();
    const { toggleStatus } = useSelector(store => store.company?.noFinancialValuationModal);
    const { industryEditFlag } = useSelector(store => store?.businessPlan.businessPlanOnboardObj);
    const valuationIndustryEditFlag = useSelector(store => store?.valuation?.valutionOnboardObj.industryEditFlag);
    const currentSelectedCompetitor = useSelector(state => state.competitiveAnalysis.currentSelectedCompetitor);
    const selectedCompetitorsMainCompany = useSelector(state => state.competitiveAnalysis?.mainCompanyREG);

    useEffect(() => {
        if (industryList.length < 1) {
            dispatch(getIndustryFilterDataRequest());
            dispatch(setIndustryMixLoader({ show: true, message: "Loading industries.." }));
        }
    }, [industryList]);

    const [isIndustriesUpdateNotValid, setMixIndustriesUpdateValid] = useState(false);

    const toggleIndustryMixConfirmationModal = (toggleStatus) => {
        dispatch(setConfirmationModal(toggleStatus));
    }

    const handleInputChange = (value, sicCode) => {
        let newValue = value.replace(/%/g, "");
        if (Number(newValue) > 100 || (value.includes("%") && value.indexOf("%") !== value.length - 1)) {
            return false;
        }
        const newIndustryBreakdownList = industryBreakdownList.map(industry => industry.SIC2007 === sicCode ? { ...industry, value: value } : industry);
        if (isIndustriesUpdateNotValid && checkMixIndustriesUpdateValid(newIndustryBreakdownList)) {
            setMixIndustriesUpdateValid(false);
        }
        setIndustryBreakdownList(newIndustryBreakdownList);
        dispatch(sendActivityTracking({
            eventPage,
            eventType: ET_CHANGE_INDUSTRY_BREAKDOWN_WEIGHT,
            attribute1: companyId,
            attribute2: sicCode,
            attribute3: value
        }));
    }

    const handleInputBlur = (value, sicCode) => {
        let newValue = value.replace(/%/g, "");
        if (isNaN(newValue) || Number(newValue) < 1) {
            newValue = 0;
        }
        newValue = `${newValue.toString()}%`;
        const newIndustryBreakdownList = industryBreakdownList.map(industry => industry.SIC2007 === sicCode ? { ...industry, value: newValue } : industry);
        setIndustryBreakdownList(newIndustryBreakdownList);
    }

    const handleAddIndustryClick = () => {
        const accessToken = localStorageApi.getUserAuthToken();
        dispatch(sendActivityTracking({
            eventPage,
            eventType: ET_OPEN_INDUSTRY_LIST_FOR_INDUSTRY_MIX,
            attribute1: companyId
        }));
        if (accessToken && accessToken !== "" && ((userSubscription?.productName === "PomandaPlus" && userSubscription?.active === "yes")
            || valuationReportMapObj?.status === "active") || isAddCompetitorModalOpen || industryEditFlag||valuationIndustryEditFlag) {
            dispatch(setAddIndustryMixModal(true));
            dispatch(setIndustryMixUpdateModal(false, {...industryMixUpdateModalObj}));
            industryMixUpdateSuccess && dispatch(setIndustryMixUpdateSuccess(false, {}));
        } else {
            dispatch(setIndustryMixUpdateModal(false));
            props.redirectToProductPage(SR_UPDATE_INDUSTRIES, companyId)
            //don't remove choose package
            // props.openChoosePackageModal(true, companyId, ADD_INDUSTRY_BTN_CLICK, SR_UPDATE_INDUSTRIES);
        }
    }

    const handleDeleteIndustry = (sicCode) => {
        const newIndustryBreakdownList = industryBreakdownList.filter(industry => industry.SIC2007 !== sicCode);
        setIndustryBreakdownList(newIndustryBreakdownList);
        checkMixIndustriesUpdateValid(newIndustryBreakdownList) && setMixIndustriesUpdateValid(false);
        dispatch(sendActivityTracking({
            eventPage,
            eventType: ET_REMOVE_INDUSTRY_FROM_BREAKDOWN,
            attribute1: companyId,
            attribute2: sicCode,
        }));
    }

    const checkMixIndustriesUpdateValid = (industryList) => {
        const reducerFun = (accumulator, currentValue) => accumulator + Number(currentValue.value.replace(/%/g, ""));
        const totalPercentage = industryList.reduce(reducerFun, 0);
        return totalPercentage === 100 ? true : false;
    }

    const handleCompetitorSaveIndustryMix = () => {
        dispatch(addSelectedCompetitor({ competitorId: currentSelectedCompetitor.REG, mainCompanyREG: selectedCompetitorsMainCompany }))
    }

    const handleSaveChanges = (isIndustryMixUpdated) => {
        
        const accessToken = localStorageApi.getUserAuthToken();
        dispatch(sendActivityTracking({
            eventPage,
            eventType: ET_SAVE_INDUSTRY_MIX_CHANGES,
            attribute1: companyId
        }));
        
        if (accessToken && accessToken !== "" && ((userSubscription?.productName === "PomandaPlus" && userSubscription?.active === "yes")
            || valuationReportMapObj?.status === "active") || isAddCompetitorModalOpen || industryEditFlag||valuationIndustryEditFlag) {
            const isValid = checkMixIndustriesUpdateValid(industryBreakdownList);
            if (!isValid) {
                setMixIndustriesUpdateValid(true);
                return false;
            }
            dispatch(setIndustryMixUpdateModal(false));
            if (toggleStatus === false && (isIndustryMixUpdated || industryEditFlag === false || valuationIndustryEditFlag===false)) {
                dispatch(setIndustryMixLoader({ show: true, message: SAVE_INDUSTRY_MIX_LOADING_MSG }));
                saveIndustryMixChanges({
                    companyId,
                    industryMixArr: industryBreakdownList.map(industry => {
                        return ({
                            ...industry,
                            value: industry.value.replace(/%/g, ""),
                            SIC2007Weight: industry.value.replace(/%/g, ""),
                            SICDesc: industry.name,
                            type: pageType
                        })
                    })
                });
            } else if (isAddCompetitorModalOpen) {
                handleCompetitorSaveIndustryMix()
            } else {
                dispatch(setUpdatedMixIndustries(industryBreakdownList));
            }
        } else {
            dispatch(setIndustryMixUpdateModal(false));
            props.redirectToProductPage(SR_UPDATE_INDUSTRIES, companyId)
            //don't remove choose package
            // props.openChoosePackageModal(true, companyId, SAVE_INDUSTRY_MIX_BTN_CLICK, SR_UPDATE_INDUSTRIES);
        }
    }

    const handleCloseClick = () => {
        dispatch(sendActivityTracking({
            eventPage,
            eventType: ET_CLOSE_INDUSTRY_MIX_MODAL,
            attribute1: companyId
        }))
        setIndustryBreakdownList(orignalIndustryBreakDownList);
        dispatch(setIndustryMixUpdateModal(false));
        industryMixUpdateSuccess && dispatch(setIndustryMixUpdateSuccess(false, {}));
        resetIndustryMixChangesError !== "" && dispatch(resetIndustryMixChangesFailed(""));
        saveIndustryChangesError !== "" && dispatch(saveIndustryMixChangesFailed(""));
        setMixIndustriesFilled(false);
    }

    const handleResetClick = () => {
        dispatch(sendActivityTracking({
            eventPage,
            eventType: ET_RESET_INDUSTRY_MIX_CHANGES,
            attribute1: companyId
        }))
        isIndustriesUpdateNotValid && setMixIndustriesUpdateValid(false);
        industryMixUpdateSuccess && dispatch(setIndustryMixUpdateSuccess(false, {}));
        saveIndustryChangesError !== "" && dispatch(saveIndustryMixChangesFailed(""));
        toggleIndustryMixConfirmationModal(true);
    }

    const getIndustryMixButtons = (isDesktop, { saveIndMixBtnName = "save changes" }) => {
        const newOrignalIndustryBreakDownList = orignalIndustryBreakDownList?.map(industry => { return ({ ...industry, value: industry?.value?.toString()?.replace(/%/g, "") }) });
        const newindustryBreakdownList = industryBreakdownList.map(industry => { return ({ ...industry, value: industry?.value?.replace(/%/g, "") }) });
        const isIndustryMixUpdated = !isEqual(newOrignalIndustryBreakDownList, newindustryBreakdownList);
        const saveChangesBtnStyle = (isIndustryMixUpdated && !isIndustriesUpdateNotValid) || isAddCompetitorModalOpen ? "industry-mix-yellow-border-btn" : "industry-mix-gray-border-btn disabled-industry-mix-btn";
        const saveChangesBackgroundStyle = isDesktop ? "" : "save-chnages-btn";
        return (
            <div className="industry-mix-btn-section">
                {!isDesktop ? null
                    : isIndustriesUpdateNotValid
                        ? <p className="industry-update-status industry-update-error">To save changes the Industry Mix total must equal 100%</p>
                        : industryMixUpdateSuccess
                            ? <p className="industry-update-status industry-update-success">Your changes have been saved!</p>
                            : resetIndustryMixChangesError !== ""
                                ? <p className="industry-update-status industry-update-error">{resetIndustryMixChangesError}</p>
                                : saveIndustryChangesError !== ""
                                    ? <p className="industry-update-status industry-update-error">{saveIndustryChangesError}</p>
                                    : null
                }
                <button type="button" className={`industry-mix-transparent-border-gray-btn industry-mix-btn ml-auto ${isIndustryMixEdited ? "" : "disabled-industry-mix-btn"} `}
                    onClick={isIndustryMixEdited ? () => handleResetClick(): null } pomandae2e="Reset"
                >
                    reset
                </button>
                <button type="button" className={`${saveChangesBtnStyle} industry-mix-btn ${saveChangesBackgroundStyle}`}
                    onClick={() => handleSaveChanges(isIndustryMixUpdated)} pomandae2e="save changes"
                >
                    {saveIndMixBtnName}
                </button>
            </div>
        )
    }

    const getSubHeadingAndErrorSuccess = (subHeading) => {
        const showErrorSuccess = screenWidth < 992 && (industryMixUpdateSuccess || isIndustriesUpdateNotValid) ? true : false;
        return (
            !showErrorSuccess ?
                    subHeading === "" ? `` :  <p className="industry-mix-modal-sub-heading">{subHeading}</p>
                : isIndustriesUpdateNotValid
                    ? <p className="industry-update-status industry-update-error">To save changes the Industry Mix total must equal 100%</p>
                    : industryMixUpdateSuccess
                        ? <p className="industry-update-status industry-update-success">Your changes have been saved!</p>
                        : null
        )
    }
    const addIndustryBtnStyle = industryBreakdownList.length < INDUSTRY_ADD_LIMIT ? "industry-mix-yellow-border-btn" : "industry-mix-gray-border-btn disabled-industry-mix-btn";
    return (
        <Modal id="industry_mix_update_modal"
            modalClassName={`industry-mix-update-modal ${modalClassName ? modalClassName : ""}`}
            backdropClassName="industry-mix-update-backdrop"
            isOpen={true} fade={false} backdrop="static">
            {showHeader && <ModalHeader className={`${headerClassName} align-items-start font-weight-bold`} toggle={() => handleCloseClick()}>
                {headerBackBtnClick && <i className="fa fa-angle-left font-weight-bold cursor-pointer" onClick={headerBackBtnClick} aria-hidden="true"></i>}
                <span className={`${headerTextCss} font-weight-bold`} dangerouslySetInnerHTML={{__html: headerText}}/>
                {headerSubHeading && <p className={`${headerSubHeadingTextCss}`}>{headerSubHeading}</p>}
            </ModalHeader>}
            <ModalBody>
                {!showHeader && <i className="fas fa-times industry-mix-close" pomandae2e="industry-mix-close" onClick={() => handleCloseClick()} />}
                {industryMixUpdateModalObj?.heading && <p className="industry-mix-modal-heading" dangerouslySetInnerHTML={{ __html: industryMixUpdateModalObj.heading }} />}
                {getSubHeadingAndErrorSuccess(industryMixUpdateModalObj.subHeading)}
                <div className="industry-mix-update-list">
                    {
                        industryBreakdownList.map((industry, index) => {
                            return (
                                <div className="industry-list-field" key={`industry-list-${index}`}>
                                    <p className="industry-list-text industry-list-name" pomandae2e={industry.name}>{industry.name}</p>
                                    <input type="text" className="industry-list-text industry-list-input" pomandae2e={`${industry.name + " PERCENTAGE"}`} name="" value={`${industry.value}`}
                                        onChange={(e) => handleInputChange(e.target.value, industry.SIC2007)}
                                        onBlur={(e) => handleInputBlur(e.target.value, industry.SIC2007)}
                                        autoComplete="off" autoCorrect="off" spellCheck="off"
                                    />
                                    <i className="fas fa-trash-alt industry-list-delete-icon" pomandae2e="delete" onClick={() => handleDeleteIndustry(industry.SIC2007)} />
                                </div>
                            )
                        })
                    }
                </div>
                <button type="button" className={`industry-mix-btn add-industry-button ${addIndustryBtnStyle}`}
                    onClick={() => handleAddIndustryClick()} pomandae2e="add industry"
                >
                    add industry
                    <i className="fas fa-plus-circle add-industry-icon" />
                </button>
                <MediaQuery minWidth={992}>
                    {getIndustryMixButtons(true, { saveIndMixBtnName })}
                </MediaQuery>
            </ModalBody>
            <MediaQuery maxWidth={991.98}>
                {getIndustryMixButtons(false, { saveIndMixBtnName })}
            </MediaQuery>
        </Modal>
    )
}

export const AddIndustryMix = (props) => {
    const {
        industryList = [],
        addIndustryToBreakdown = () => { },
        industryCount = 0,
        companyId = "",
        industryMixUpdateModalObj = {}
    } = props;
    const [industrySearchText, setIndustrySearchText] = useState(null);
    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [nodesFiltered, setNodesFiltered] = useState([]);
    const [selectedIndustries, setSelectedIndustries] = useState([]);
    const [industryCountLeft, setIndustryCountLeft] = useState(INDUSTRY_ADD_LIMIT - industryCount);
    // const [searchFilterTree, setSearchFilterTree] = useState(industryList);
    useEffect(() => {
        setNodesFiltered(industryList)
    }, [industryList?.length > 1])

    const { industryEditFlag } = useSelector(state => state?.businessPlan?.businessPlanOnboardObj)
    const valuationIndustryEditFlag = useSelector(store => store?.valuation?.valutionOnboardObj.industryEditFlag);
    const { businessTempIndustryEdit = false } = useSelector(state => state?.businessPlanTemplate.businessPlanTemplateMenuData.analysis.children.industryInsight.data);
    const searchLabel = "label";
    const startLabel = "";
    const endLabel = "";
    let searchResultsFound = [];

    const dispatch = useDispatch()

    useEffect(() => {
        industrySearchText !== null && filterTree();
    }, [industrySearchText]);

    useEffect(() => {
        // nodesFiltered.length === 0 && getSearchedNodes(searchFilterTree, industrySearchText, searchLabel)
        nodesFiltered?.length === 0 && getSearchedNodes(industryList, industrySearchText, searchLabel)
    }, [nodesFiltered]);
    const handleBackClick = () => {

        if (industryEditFlag || businessTempIndustryEdit) {
            industryEditFlag && dispatch(setBusinessPlanOnboardObj({
                industryEditFlag: false
            }))
            businessTempIndustryEdit && dispatch(setBussTempMainPageData({
                parentLabel: "analysis", 
                childLabel: "industryInsight", 
                data: { businessTempIndustryEdit: false }
            }))
        } else {
            valuationIndustryEditFlag ? dispatch(setValuationOnboardDataIntoRedux({
                industryEditFlag: false
            }))
            : dispatch(setIndustryMixUpdateModal(true));
        }
        dispatch(setAddIndustryMixModal(false));
    }

    const getLabelForTreeFilters = (targetNode) => {
        if (typeof targetNode.label === "string") {
            return targetNode.label;
        } else {
            let label = "";
            targetNode.label.props.children.map(node => {
                if (node && node.type === "span") {
                    label += node.props.children;
                } else if (node) {
                    label += node.props.textToHighlight;
                }
            })
            return label;
        }
    }

    const getSicCodeForTreeNode = (targetNode) => {
        const valueList = targetNode.value.split("-");
        return valueList.length > 0 ? valueList[0] : "";
    }

    const onCheck = (checkedArray, targetNode) => {
        let newIndustries = JSON.parse(JSON.stringify(selectedIndustries));
        const label = getLabelForTreeFilters(targetNode);
        const sicCode = getSicCodeForTreeNode(targetNode);
        if (targetNode.checked === false) {
            newIndustries = selectedIndustries.filter((industry) => {
                return industry.name?.toString()?.toLowerCase() !== label?.toString()?.toLowerCase();
            })
        } else if (industryCountLeft === 0) {
            return false;
        } else {
            newIndustries.push({
                name: label,
                value: "0%",
                SIC2007: sicCode
            })
        }
        setChecked(checkedArray);
        setIndustryCountLeft(INDUSTRY_ADD_LIMIT - industryCount - newIndustries.length);
        setSelectedIndustries(newIndustries);
    }

    const onFilterChange = (e) => {
        setIndustrySearchText(e.target.value);
    }

    const handleAddIndustriesClick = () => {
        let attributeObj = {};
        addIndustryToBreakdown(selectedIndustries);
        dispatch(setAddIndustryMixModal(false));

        if (industryEditFlag || businessTempIndustryEdit) {
            industryEditFlag && dispatch(setBusinessPlanOnboardObj({
                industryEditFlag: false
            }))
            businessTempIndustryEdit && dispatch(setBussTempMainPageData({
                parentLabel: "analysis", 
                childLabel: "industryInsight", 
                data: { businessTempIndustryEdit: false }
            }))
        } else {
            valuationIndustryEditFlag ? dispatch(setValuationOnboardDataIntoRedux({
                industryEditFlag: false
            }))
            : dispatch(setIndustryMixUpdateModal(true, {...industryMixUpdateModalObj}));
            selectedIndustries.forEach((industry, index) => {
                attributeObj[`attribute${index + 2}`] = industry.SIC2007;
            });
        }
        dispatch(sendActivityTracking({
            eventPage: COMPANY_PROFILE,
            eventType: ET_ADD_INDUSTRIES_TO_BREAKDOWN,
            attribute1: companyId,
            ...attributeObj
        }));
    }

    const filterTree = async () => {
        // setSearchFilterTree(industryList);
        setNodesFiltered(industryList);
        searchResultsFound = [];
        // Reset nodes back to unfiltered state
        if (!industrySearchText || industrySearchText === "") {
            searchResultsFound = [];
            return;
        }
        setNodesFiltered([]);
    }

    const getSearchedNodes = (data, filterText, label) => {
        for (let i = 0; i < data.length; i++) {
            const isValidResult = data[i].children && data[i].children.length > 0 ? false : true;
            if (isValidResult && filterText && data[i]['searchKeyword'] && data[i]['searchKeyword']?.toLocaleLowerCase().indexOf(filterText?.toLocaleLowerCase()) > -1) {
                let matchResult = { ...data[i] };
                matchResult.children = [];
                matchResult.label = <span>
                    {startLabel ? <span>{matchResult[startLabel] + " - "}</span> : null}
                    <Highlighter
                        highlightClassName="highlight-text"
                        searchWords={[filterText]}
                        autoEscape={true}
                        textToHighlight={matchResult[label]}
                        pomandae2e={[filterText]}
                    />
                    {endLabel ? <span>{" - " + matchResult[endLabel]}</span> : null}
                </span>
                searchResultsFound.push(matchResult);
            }
            if (data[i].children && data[i].children.length > 0) {
                getSearchedNodes(data[i].children, filterText, label);
            }
        }
        searchResultsFound.length !== 0 && setNodesFiltered(searchResultsFound);
    }

    return (
        <Modal id="add_industry_mix_modal" modalClassName="add-industry-mix-modal" backdropClassName="add-industry-mix-backdrop" isOpen={true} fade={false} backdrop="static">
            <ModalBody>
                <i className="fas fa-arrow-left add-industry-close" pomandae2e="industry-list back-arrow" onClick={handleBackClick} />
                <p className="industry-mix-modal-heading">Industry List</p>
                <p className="industry-mix-modal-sub-heading">
                    {industryCountLeft !== 0 ?
                        <span>You can add up to <span className="industry-count-left">({industryCountLeft})</span> more Industry(s).</span>
                        : <span>You have reached the maximum <span className="industry-count-left">({INDUSTRY_ADD_LIMIT})</span> number of Industry(s).</span>
                    }
                </p>
                <div id="industry_mix_list_section" className="industry-mix-list-section">
                    <div className="industry-search-input-section">
                        <input
                            className="industries-search-input"
                            pomandae2e="industry-search-input"
                            placeholder="Search for an Industry or a  Sic Code…"
                            type="text"
                            value={industrySearchText}
                            onChange={onFilterChange}
                            autoFocus={true} autoComplete="off" autoCorrect="off" spellCheck="off"
                        />
                    </div>
                    <CheckboxTree
                        noCascade={true}
                        onlyLeafCheckboxes={true}
                        key="industries_list_comp"
                        checked={checked}
                        expanded={expanded}
                        nodes={nodesFiltered}
                        onCheck={onCheck}
                        onExpand={(industries) => setExpanded(industries)}
                        icons={{
                            check: <span className="industry-mix-checkbox-check" />,
                            uncheck: <span pomandae2e="uncheck" className="industry-mix-checkbox-uncheck" />,
                            halfCheck: <span className="industry-mix-checkbox-uncheck" />,
                        }}
                    />
                </div>
                {checked.length > 0 && <button type="button" pomandae2e="add-industries-btn" className="industry-mix-btn industry-mix-yellow-background-btn add-industries-btn"
                    onClick={handleAddIndustriesClick}
                >
                    add industry(s)
                </button>}
            </ModalBody>
        </Modal>
    )
}

export const IndustryMixLoader = (props) => {
    const {
        message = ""
    } = props;
    return (
        <Modal id="industry_mix_loader" modalClassName="industry-mix-loader-modal" backdropClassName="industry-mix-loader-backdrop" isOpen={true} fade={false} backdrop="static">
            <ModalBody>
                <div className="industry-mix-loader-section">
                    <p className="industry-mix-loader-text">{message}</p>
                    <div className="industry-mix-loading-outer">
                        <span className="industry-mix-loading-bar"></span>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}
