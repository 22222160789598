import React, { Component } from "react";
import { connect } from "react-redux";
import { EP_SOLUTIONS, ET_SOLUTIONS_PAGE_ONLOAD } from "../../common/trackingConstants";
import { sendActivityTracking, setHeaderSingleSearchOptions, setHideHeader } from "../../redux/actions/commonAction";
import {resetAllSearchParams} from '../../redux/actions/searchAction';
import HomepageFooterV3 from "../HomePage/HomepageFooterV3/HomepageFooterV3";
import HomePageCardComponent from "../HomePageCard/HomePageCardComponent";
import { getSolutionMenuItems } from "./Constants";
import './SolutionsHome.css';
import { PROSPER_USER_TYPE } from "../../common/config";
   
class SolutionsHomePage extends Component {
    constructor() {
        super();
    }

    componentDidMount() {
        const {
            companyQueryData = {}
        } = this.props;
        this.props.dispatchSendActivityTracking({
            eventPage: EP_SOLUTIONS,
            eventType: ET_SOLUTIONS_PAGE_ONLOAD
        });
        this.props.dispatchSetHeaderSingleSearchOptions({
            eventPage: EP_SOLUTIONS
        });
        window.scrollTo({ top: 0 });

        let newCompanyQueryData = JSON.parse(JSON.stringify(companyQueryData))
        newCompanyQueryData.isolatedSearchText  = ""
        this.props.dispatchResetAllSearchParams(newCompanyQueryData);
        this.props.dispatchSetHideHeader({
            animation: false,
            backgroundDesktop: "transparent",
            backgroundTab: "transparent",
            backgroundMob: "transparent"
        })
    }

    onButtonClick = (eventType) => {
        this.props.dispatchSendActivityTracking({
            eventPage: EP_SOLUTIONS,
            eventType
        });
    }

    componentWillUnmount = () => {
        this.props.dispatchSetHeaderSingleSearchOptions({
            eventPage: ""
        });
        this.props.dispatchSetHideHeader({
            animation: true,
            backgroundDesktop: "blue",
            backgroundTab: "blue",
            backgroundMob: "blue"
        })
    }
    
    render() {
        const { userInfo = {}, whiteLabelConfig = {}
    } = this.props;
        const iswhiteLabelConfig = whiteLabelConfig?.header ? true : false
        const prosperCss = userInfo?.userType === PROSPER_USER_TYPE ? "prosper-solution-page" : ""

        return (
            <div className={`solution-homepage ${prosperCss}`}>
                <div className="solution-homepage-header">
                    <p>Our Solutions</p>
                </div>
                <div className="solution-card-div">
                    <HomePageCardComponent
                        cardDataList={getSolutionMenuItems(userInfo?.userType, iswhiteLabelConfig)}
                        onButtonClick={(e) => this.onButtonClick(e)}
                    />
                </div>
                <div className="homepage-footer-section-wrapper">
                    <div className="homepage-footer-section">
                        <HomepageFooterV3 />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    userInfo : state.userProfile.userInfo,
    whiteLabelConfig: state.common.whiteLabelConfig,
    companyQueryData: state.searchReducer.companyQueryData
})
const mapDispatchToProps = (dispatch) => ({
    dispatchSendActivityTracking: (trackingObj) => dispatch(sendActivityTracking(trackingObj)),
    dispatchSetHeaderSingleSearchOptions: optionObj => dispatch(setHeaderSingleSearchOptions(optionObj)),
    dispatchResetAllSearchParams: searchParam => dispatch(resetAllSearchParams(searchParam)),
    dispatchSetHideHeader: (headerProps) => dispatch(setHideHeader(headerProps))
})

export default connect(mapStateToProps, mapDispatchToProps)(SolutionsHomePage)