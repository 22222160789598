import React, { Component } from 'react'
import { connect } from 'react-redux'
import './BusinessPlanPPCHomePage.css'
import "../SwoopLandingPage/SwoopLandingPage.css"
import { PROSPER_USER_TYPE, REACT_APP_GOOGLE_INDEX, isBrowser } from '../../common/config';
import { sendActivityTracking, setHeaderSingleSearchOptions, setHideHeader } from '../../redux/actions/commonAction';
import { Helmet } from 'react-helmet';
import InfiniteScrollComponent from './InfiniteScrollComponent/InfiniteScrollComponent';
import HomepageFooterV3 from '../HomePage/HomepageFooterV3/HomepageFooterV3';
import { BUILD_YOUR_BUSINESS_PLAN_BTN_CLICK, ET_BUSINESS_PLAN_PPC_HOMEPAGE_SCROLL, ET_BUSINESS_PLAN_SAMPLE_CLICK, ET_CONTACT_US_FOR_ENTERPRISE_BTN_CLICK, ET_EXISTING_BUSINESS_CARD_CLICK, ET_START_MY_AI_BUSINESS_PLAN_BTN_CLICK, ET_START_NOW_BTN_CLICK, ET_START_WITH_AI_BTN_CLICK, ET_STILL_AN_BUSINESS_IDEA_CARD_CLICK } from '../../common/trackingConstants';
import CarouselWithScrollBar from './CarouselWithScrollBar/CarouselWithScrollBar';
import DynamicSvg from '../../DynamicSvg';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import MediaQuery from 'react-responsive';
import HeroSectionWithBgVideo from '../HeroSectionWithBgVideo/HeroSectionWithBgVideo';
import SelectBusinessTypeCard from '../SwoopLandingPage/SelectBusinessTypeCard';
import { getCurrencySymbol } from '../../common/CurrencyConverter';
import { AI_PRODUCTS } from '../HomePage/Homepage';

const companySeoTitle = "Pomanda AI | AI Business Plans"
const metaData = "Combining Pomanda AI’s market-leading industry data with AI to generate customisable business plans for both new and existing companies, anywhere in the world."

const ALPHA_SECTION_CONTENT = ["Executive Summary", "Company Description", "History & Milestones", "Key People", "Ownership", "Shareholders", "Business Vision",
    "Objectives", "Industry Insight", "Target Market", "Customer Personas", "Strengths & Weaknesses", "Opportunities & Threats", "Competitive Analysis",
    "Competitive Analysis- Charts", "Products & Services", "Pricing Model & Strategy", "Customer Acquisition Strategy", "Customer Acquisition Channels", "Sales Forecast", "Business Locations",
    "Key Suppliers", "Current & Future Staffing", "Financial Projections", "Funding Requirements & Uses", "Business Valuation", "Appendix"]

const POWER_BUSINESS_PLAN_CONTENT = [{
    desc: 'Pomanda turned writing a business plan into an editing exercise rather than starting from a blank page',
    name: 'Sophie C',
    companyName: 'Reg Tech Entrepreneur'
}, {
    desc: 'Being able to choose which sections to include in the Business Plan allows me to quickly customise the end result for different audiences',
    name: ' David F',
    companyName: 'Corporate Finance Advisor'
},
{
    desc: "Pomanda's Business Plan gave me a great foundation when applying for the recovery loan scheme",
    name: 'Zara T',
    companyName: 'Local Business Owner'
}]

const selectBusinessTypeCards = [{
    heading: "My Business Is Still Only An Idea",
    btnText: "GET YOUR IDEA STARTED",
    componentId: "business_plan_basic_company_info_page",
    icon: "/assets/svgs/bulb_square_icon.svg",
    width: "48",
    height: "48",
    businessType: "new"
}, {
    heading: "My Business Is Up And Running",
    btnText: "BUILD YOUR BUSINESS PLAN",
    componentId: "business_plan_location_page",
    icon: "/assets/svgs/handshake_icon.svg",
    width: "48",
    height: "48",
    businessType: "existing"
}];

const businessPlanPackages = [{
    heading: "Single",
    isEnterprise: false,
    price: 120,
    quantity: 1,
    btnText: "START NOW",
    componentId: "single_pack_businessplan_card",
    eventType: ET_START_NOW_BTN_CLICK
}, {
    heading: "Enterprise",
    subHeading: "Interested in a business plan package for larger organisations? Get in touch to learn more about our enterprise offering.",
    btnText: "CONTACT US",
    isEnterprise: true,
    componentId: "enterprise_pack_businessplan_card",
    eventType: ET_CONTACT_US_FOR_ENTERPRISE_BTN_CLICK
}]


const businessPlanHomePageSections = ["business_plan_home_ppc_header_section", "business_plan_home_body_section", "business_plan_ppc_alpha_section", "business_plan_ppc_footer_header_section", "business_plan_home_ppc_footer_section"];

class BusinessPlanPPCHomePage extends Component {
    constructor() {
        super();
        this.state = {
            searchTextValue: ""
        }
    }
    componentDidMount() {
        this.props.dispatchSetHeaderSingleSearchOptions({
            eventPage: this.props.eventPage
        });
        this.props.dispatchSendActivityTracking({
            eventPage: this.props.eventPage,
            eventType: this.props.eventType
        })
        window.scrollTo({ top: 0 });
    }
    componentDidUpdate() {
        const {
            headerProps = {}
        } = this.props;
        headerProps.backgroundDesktop !== 'transparent' && this.props.dispatchSetHideHeader({
            backgroundDesktop: "transparent",
            backgroundTab: "transparent",
            backgroundMob: "transparent",
            showSingleSearchbar: false,
            showPowerSearchNav: false
        });
    }
    componentWillUnmount = () => {
        this.props.dispatchSetHideHeader({
            backgroundDesktop: "blue",
            backgroundTab: "blue",
            backgroundMob: "blue"
        });
    }
    onBtnClick = (eventType = ET_START_WITH_AI_BTN_CLICK) => {
        this.props.history.push('/businessplanonboard')
        this.props.dispatchSendActivityTracking({
            eventPage: this.props.eventPage,
            eventType
        })
    }

    handleBusinessTypeCardClick = (businessType) => {
        this.props.dispatchSendActivityTracking({
            eventPage: this.props.eventPage,
            eventType: businessType === "new" ? ET_STILL_AN_BUSINESS_IDEA_CARD_CLICK : ET_EXISTING_BUSINESS_CARD_CLICK
        })
        this.props.history.push(`/businessplanonboard/${businessType}`);
    }

    handleBuyBusinessPlan = (isEnterprise, eventType) => {
        const url = "/contact";
        this.props.dispatchSendActivityTracking({
            eventPage: this.props.eventPage,
            eventType
        })
        if (!isEnterprise) {
            if (this.props.screen?.width < 576) {
                this.props.history.push('/businessplanonboard');
                return
            }
            const element = document.getElementById("select_business_plan_card_wrapper");
            if (element) {
                const header = document.getElementById("header-navbar").getBoundingClientRect().height;
                window.scrollTo({
                    top: element.offsetTop - header,
                    behavior: "smooth"
                })
            }
        } else {
            this.props.history.push(url);
        }
    }

    render() {
        const {
            staticContext = {},
            history = {},
            screen = {},
            userInfo = {},
            countryBasedCurrency
        } = this.props;
        const selectedCurrency = userInfo?.aiCurrency || countryBasedCurrency
        let href, origin;
        if (isBrowser) {
            href = window.location.href
            origin = window.location.origin
        } else {
            href = `${staticContext.baseHref}${history.location.pathname}`
            origin = staticContext.baseHref
        }
        return (
            <div id='business_plan_home_ppc'>
                <Helmet>
                    <meta name="og:url" content={href} />
                    <meta property="og:title" content={companySeoTitle} />
                    <meta property="og:description" content={metaData} />
                    <meta property="og:image" content={`${origin}/assets/images/sharecompany.png`} />
                    <meta name="twitter:title" content={companySeoTitle} />
                    <meta name="twitter:image" content={`${origin}/assets/images/sharecompany.png`} />
                    <meta name="twitter:url" content={href} />
                    <title>{companySeoTitle}</title>
                    <meta name="description" content={metaData} />
                    <link rel="canonical" href={href} />
                    {!REACT_APP_GOOGLE_INDEX && <meta name="robots" content="noindex" />}
                </Helmet>
                <div id='business_plan_home_ppc_header_section' className='business-plan-home-ppc-header-section'>
                    <HeroSectionWithBgVideo
                        heading="AI Powered Business Plans"
                        subHeading="Combining Pomanda’s company and industry data with market leading artificial intelligence to produce customisable business plans for both new and established companies, anywhere in the world."
                        patnerObj={null}
                        buttonComp={screen?.width < 575.98 ? {
                            text: "BUILD YOUR PLAN",
                            cssClass: "hero-section-button",
                            onBtnClick: () => this.onBtnClick(BUILD_YOUR_BUSINESS_PLAN_BTN_CLICK)
                        } : null}
                    />
                    <MediaQuery minWidth={576}>
                        <div className="select-business-type-cards-container" id='select_business_plan_card_wrapper'>
                            {selectBusinessTypeCards.map((card) => {
                                return <SelectBusinessTypeCard
                                    {...card}
                                    onBtnClick={() => this.handleBusinessTypeCardClick(card.businessType)}
                                    key={card.componentId}
                                    textLink={{
                                        text: "View Sample",
                                        url: `/assets/Download/business_plan_sample_report.pdf`
                                    }}
                                />
                            })}
                        </div>
                    </MediaQuery>
                </div>
                <div id='business_plan_ppc_alpha_section' className='business-plan-ppc-alpha-component'>
                    <div className='business-plan-home-ppc-body-section-wrapper'>
                        <div className='business-plan-home-ppc-width-50-100-100 business-plan-home-ppc-alpha-section'>
                            <p className='business-plan-home-ppc-body-left-section-heading mb-0'>
                                What’s included ?
                            </p>
                            <p className='text-color-1D2939 bp-mt-32-24-24 mb-0 business-plan-home-ppc-font-18-18-16'>
                                Combine Pomanda's data with cutting-edge AI for a 27-section business plan. Customisable for 60 days.
                            </p>
                            <p className='business-plan-home-ppc-font-18-18-16 bp-mt-32-24-24 font-weight-600 mb-0'>Include up to 27 sections:</p>
                            <div className='border-bottom-light-gray mt-2'></div>
                            <CarouselWithScrollBar />
                        </div>
                        <div className='business-plan-home-ppc-width-50-100-100 business-plan-home-ppc-body-right-section'>
                            <picture>
                                <source media='(min-width: 576px) and (max-width: 991.98px)' srcSet='/assets/webpImages/pom_ai_business_plan_dount_charts_tablet.webp' />
                                <source media='(min-width: 576px) and (max-width: 991.98px)' srcSet='/assets/images/pom_ai_business_plan_dount_charts_tablet.png' />
                                <source srcSet='/assets/webpImages/business_plan_dount_charts.webp' />
                                <img src='/assets/images/business_plan_dount_charts.png'
                                    width="100%" height="100%" alt='business plan dount charts'
                                    className='business-plan-ppc-dount-chart-icon' />
                            </picture>
                        </div>
                    </div>
                </div>
                <div className='business-plan-packages-section'>
                    <h2 className='business-plan-home-ppc-font-32-26-26 mb-0'>Explore Pomanda’s Business Plan Packages</h2>
                    <div className='select-business-type-cards-container'>
                        {businessPlanPackages.map((packageObj) => <SelectBusinessTypeCard
                            {...packageObj}
                            subHeading={packageObj.isEnterprise ? packageObj.subHeading : `<span>${getCurrencySymbol(selectedCurrency)}${AI_PRODUCTS[1]?.price[selectedCurrency].value}<span class="text-color-939393">/</span><span class="package-tag text-color-939393">${packageObj.quantity} Business Plan</span></span>`}
                            key={packageObj.componentId}
                            onBtnClick={() => this.handleBuyBusinessPlan(packageObj.isEnterprise, packageObj.eventType)}
                        />)}
                    </div>
                </div>
                <div id='business_plan_home_body_section' className='business-plan-home-ppc-body-section-wrapper'>
                    <div className='business-plan-home-ppc-width-50-100-100 business-plan-home-ppc-body-left-section'>
                        <p className='business-plan-home-ppc-body-left-section-heading'>
                            Whether you're pre-startup with an innovative idea or an established business looking to forge ahead, Pomanda is your ultimate ally in creating powerful business plans.
                        </p>
                        <img src='/assets/svgs/cube.svg' height={50} width={50} alt='cube'
                            className='business-plan-ppc-left-section-cube left-section-right-cube' />
                        <img src='/assets/svgs/cube.svg' height={100} width={100} alt='cube'
                            className='business-plan-ppc-left-section-cube left-section-left-cube' />
                    </div>
                    <div className='business-plan-home-ppc-width-50-100-100 business-plan-home-ppc-body-right-section business-plan-comp-max-heght'>
                        <img src='/assets/svgs/cube.svg' height={50} width={50} alt='cube'
                            className='business-plan-ppc-left-section-cube right-section-left-cube' />
                        <div className={screen?.width <= 991.98 ? "horizontal-scroll-container" : "vertical-scroll-container"}>
                            <div className='horizontal-scroll-group powerful-business-plan-scroll-container'>
                                {POWER_BUSINESS_PLAN_CONTENT.map((obj, index) =>
                                    <div className='powerful-business-plan-scroll-content-box' key={`power_business_plan_content${index}`}>
                                        <DynamicSvg src='/assets/svgs/yellow_quote.svg' alt='feedback_quotes_img' width={20} height={20}
                                            className='rotate-180 mb-1' />
                                        <p className='business-plan-ppc-font-24-24-19'>{obj.desc}</p>
                                        <p className='business-plan-ppc-font-16-12-12 mb-0'>{obj.name}</p>
                                        <p className='business-plan-ppc-font-16-12-12 business-plan-ppc-scroll-content-div-footer-text'>{obj.companyName}</p>
                                    </div>)}
                            </div>

                            <div className='horizontal-scroll-group powerful-business-plan-scroll-container'>
                                {POWER_BUSINESS_PLAN_CONTENT.map((obj, index) =>
                                    <div className='powerful-business-plan-scroll-content-box' key={`power_business_plan_content${index}`}>
                                        <DynamicSvg src='/assets/svgs/yellow_quote.svg' alt='feedback_quotes_img' width={24} height={20}
                                            className='rotate-180' />
                                        <p className='business-plan-ppc-font-24-24-19'>{obj.desc}</p>
                                        <p className='business-plan-ppc-font-16-12-12 mb-0'>{obj.name}</p>
                                        <p className='business-plan-ppc-font-16-12-12 business-plan-ppc-scroll-content-div-footer-text'>{obj.companyName}</p>
                                    </div>)}
                            </div>
                        </div>
                        <img src='/assets/svgs/cube.svg' height={50} width={50} alt='cube'
                            className='business-plan-ppc-left-section-cube right-section-right-cube' />
                    </div>
                </div>
                <div id='business_plan_ppc_footer_header_section' className='business-plan-home-ppc-ai-section'>
                    <div className='business-plan-home-ppc-header-section-max-content'>
                        <h1 className="business-plan-home-ppc-header-section-heading" >
                            Supercharge your business plan with Pomanda AI
                        </h1>
                        <div className="business-plan-home-ppc-header-btn" onClick={() => this.onBtnClick()} >
                            START WITH AI
                        </div>
                        <InfiniteScrollComponent
                            scrollContent={ALPHA_SECTION_CONTENT} />
                    </div>
                </div>
                <div id='business_plan_home_ppc_footer_section' className='business-plan-home-ppc-footer-section'>
                    <HomepageFooterV3 />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userInfo: state.userProfile.userInfo,
    countryBasedCurrency: state.userProfile.countryBasedCurrency,
    screen: state.common.screen,
    headerProps: state.common.headerProps
});

const mapDispatchToProps = (dispatch) => ({
    dispatchSetHideHeader: (headerProps) => dispatch(setHideHeader(headerProps)),
    dispatchSetHeaderSingleSearchOptions: (optionsObj) => dispatch(setHeaderSingleSearchOptions(optionsObj)),
    dispatchSendActivityTracking: (activityObject) => dispatch(sendActivityTracking(activityObject)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BusinessPlanPPCHomePage)