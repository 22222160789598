import { REMOVE_VALUATION_ONBOARD_USER_DATA, SET_VALUATION_ONBOARD_USER_DATA } from "./actionConstants";

export const setValuationOnboardDataIntoRedux = (valuationData) => ({
    type: SET_VALUATION_ONBOARD_USER_DATA,
    payload: { valuationData }
});

export const removeValuationOnboardDataIntoRedux = () => ({
    type: REMOVE_VALUATION_ONBOARD_USER_DATA,
    payload: {}
});