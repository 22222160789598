import { call, put, takeLatest } from "redux-saga/effects";
import { GET_COUNTRY_LIST_REQUEST } from "../redux/actions/actionConstants";
import { getCountryListFailed, getCountryListSuccess } from "../redux/actions/countryAction";
import { getCountryListApiCall } from "../api/countryApi";

function* getCountryListRequestSaga() {
    try{
        const response = yield call(getCountryListApiCall);
        if(response.error){
            yield put(getCountryListFailed(response.message))
        } else {
            yield put(getCountryListSuccess(response.valuationCountryList))
        }
    } catch(error) {
        console.log(error);
        yield put(getCountryListFailed(error.message));
    }
}

export function* watchGetCountryListRequest() {
    yield takeLatest(GET_COUNTRY_LIST_REQUEST, getCountryListRequestSaga);
}