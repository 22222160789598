import { GET_COUNTRY_LIST_FAILED, GET_COUNTRY_LIST_SUCCESS } from "../actions/actionConstants";

const countryReducer = (state={}, action) => {
    switch(action.type) {
        case GET_COUNTRY_LIST_SUCCESS:
            return {...state, countryList: action.payload.countryListObj}
        case GET_COUNTRY_LIST_FAILED:
            return {...state, countryListError: action.payload.error}
        default: 
        return state;
    }
}

export default countryReducer;