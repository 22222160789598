import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import IsolatedSearchDropdown from './IsolatedSearchDropdown';
export default class IsolatedSearchModalBar extends Component {

    render() {
        const {
            isOpen = false,
            inputPlaceholder = "",
            inputSearchValue = "",
            inputId="",
            eventCreated= "",
            eventType= "",
            eventPage="",
            eventCreatedOption="",
            companyList = [],
            companyCount = 0,
            singleSearchTypes = [],
            btnLink,
            handleSearchClick = () => {},
            eventCreatedIconClick = "",
            eventTypeKeyDown="",
            eventCreatedKeyDown="",
            prevPath="",
            userType = "",
            DropdownItemTag="",
            optionOnClick = () => { },
            dropdownItemClassName = ""
        } = this.props;
        return (
            <Modal className="single-search-modal-comp isolated-search-modal" isOpen={isOpen} fade={false} backdrop="static">
                <ModalBody>
                    <div className="search-modal-header">
                        <i className="fa fa-angle-left left-angle-icon" pomandae2e="closebutton" onClick={()=>this.props.handleModalBackLink(false)}></i>
                        <input type="text" id={inputId} pomandae2e="searchbar"
                            placeholder={inputPlaceholder} 
                            onKeyDown={(e) => this.props.handleOnKeyDown(e, eventCreatedKeyDown, eventTypeKeyDown)} 
                            onChange={(e) => this.props.handleOnChange(e, eventType, eventCreated, eventPage )} 
                            value={inputSearchValue} tabIndex="0" autoFocus={true} autoComplete="off"
                            autoCorrect="off" spellCheck="off" 
                        />
                        <i className="fa fa-search search-modal-icon ml-auto" pomandae2e="searchinputicon" onClick={() => handleSearchClick({
                            eventType : eventCreatedIconClick,
                            attribute1 : eventType
                        })}></i>
                    </div>
                    <div className="dropdown">
                        <IsolatedSearchDropdown
                            id={inputId}
                            isShow={true}
                            searchTextboxValue={inputSearchValue}
                            companyList={companyList}
                            companyCount={companyCount}
                            eventPage={eventPage}
                            eventCreatedOptionClick={eventCreatedOption}
                            countOptionOnclick={this.props.countOptionOnclick}
                            companyCountOptionOnclick={this.props.companyCountOptionOnclick}
                            singleSearchTypes={singleSearchTypes}
                            btnLink={btnLink}
                            prevPath={prevPath}
                            userType={userType}
                            optionOnClick={optionOnClick}
                            dropdownItemClassName={dropdownItemClassName}
                            DropdownItemTag={DropdownItemTag}
                        />
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}