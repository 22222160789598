import { SHOW_DAILY_SEARCH_EXPIRE_MODAL } from "../../common/trackingConstants";
import {
  GET_COMPANY_PROFILE_SUCCESS,
  SET_CURRENT_BALANCESHEET_CARD,
  SET_CURRENT_PROFITLOSS_CARD,
  GET_COMPANY_HOUSE_FILINGS_SUCCESS,
  CHANGE_FINANCIAL_TAB,
  CHANGE_FINANCIALS_DATA_INDEX,
  GET_DIRECTOR_STATUS_LIST_SUCCESS,
  GET_DIRECTOR_SHOW_MORE_SUCCESS,
  GET_SHAREHOLDER_TYPE_LIST_SUCCESS,
  GET_SHAREHOLDER_SHOW_MORE_SUCCESS,
  EMPTY_COMPANY_PROFILE,
  // GET_INDUSTRY_MULTIPLE_DATA,
  GET_INDUSTRY_MULTIPLE_DATA_SUCCESS,
  GET_INDUSTRY_MULTIPLE_DATA_FAILED,
  GET_COMPANY_PAGE_URL,
  SET_SIMILAR_COMPANY_MODAL_TOGGLE_STATUS,
  TOGGLE_SUBMIT_INVESTMENT_INTEREST_MODAL,
  SUBMIT_INVESTMENT_INTEREST_FAILED,
  SET_INDUSTRY_MIX_UPDATE_MODAL,
  SET_ADD_INDUSTRY_MIX_MODAL,
  SAVE_INDUSTRY_MIX_CHANGES_FAILED,
  SET_INDUSTRY_MIX_LOADER,
  SET_INDUSTRY_MIX_UPDATE_SUCCESS,
  RESET_INDUSTRY_MIX_CHANGES_FAILED,
  SET_AGREE_YES_CHOICE,
  CHANGE_VALUATION_SECTION_DATA,
  SET_SIMILAR_COMPANIES_LOADING_FLAG,
  GET_COMPANY_CHARGES_SUCCESS,
  SET_COMPANY_CHARGES_MODAL,
  TOGGLE_VALUATION_INFO_MODAL,
  SET_FINANCIAL_VALUATION_USER_DATA,
  GET_COMPANY_VALUATION_SUCCESS,
  GET_COMPANY_VALUATION_USER_EDIT_SUCCESS,
  NO_FINANCIAL_VALUATION_MODAL,
  RESET_INDUSTRY_MIX_CHANGES_SUCCESS,
  TOGGLE_HEALTH_CHECK_MODAL,
  SET_BUY_CREDIT_REPORT_MODAL_DATA,
  TOGGLE_BUY_CREDIT_REPORT_MODAL,
  SET_UPDATED_MIX_INDUSTRIES,
  TOGGLE_REPORT_PACKAGE_DISPLAY,
  SET_COMPANY_PROFILE_VALUATION_MAPPING,
  GET_COMPANY_PROFILE_FAILED
} from "../actions/actionConstants";

let balanceSheetCards = {
  subCardList: [
    {
      heading: "net assets",
      subHeading: "Total assets minus all liabilities",
      value: "16M",
      margin: 2.60,
      labels: [],
      graphData: [],
      fillStartColor: 'rgba(50,153,225,1)',
      fillStopColor: 'rgba(255,255,255,0.4)',
      fillBorderColor: "#3299E1"
    },

    {
      heading: "total assets",
      subHeading: "The total value of all items of economic value owned by the company",
      value: "35M",
      margin: 9.20,
      labels: [],
      graphData: [],
      fillStartColor: 'rgba(50,153,225,1)',
      fillStopColor: 'rgba(255,255,255,0.4)',
      fillBorderColor: "#3299E1"

    },
    {
      heading: "cash",
      subHeading: "Cash held by the business",
      value: "1M",
      margin: 15.60,
      labels: [],
      graphData: [],
      fillStartColor: 'rgba(50,153,225,1)',
      fillStopColor: 'rgba(255,255,255,0.4)',
      fillBorderColor: "#3299E1"

    }
  ]
};
let profitLossCards = {
  subCardList: [
    {
      heading: "turnover",
      subHeading: "Turnover, or revenue, is the amount of sales generated by a company within the financial year.",
      value: "16M",
      margin: 2.60,
      labels: [],
      graphData: [],
      fillStartColor: 'rgba(50,153,225,1)',
      fillStopColor: 'rgba(255,255,255,0.4)',
      fillBorderColor: "#3299E1",
      type: ''
    },
    {
      heading: "operating profit",
      subHeading: "Operating Profit is the profit from business operations before deduction of interest and taxes",
      value: "35M",
      margin: 9.20,
      labels: [],
      graphData: [],
      fillStartColor: 'rgba(50,153,225,1)',
      fillStopColor: 'rgba(255,255,255,0.4)',
      fillBorderColor: "#3299E1",
      type: ''

    },
    {
      heading: "gross margin",
      subHeading: "The total sales minus the cost of goods sold represented as a percentage.",
      value: "1M",
      margin: 15.60,
      labels: [],
      graphData: [],
      fillStartColor: 'rgba(50,153,225,1)',
      fillStopColor: 'rgba(255,255,255,0.4)',
      fillBorderColor: "#3299E1",
      type: ""

    }
  ]
}

const companyReducer = (state = {
  financialTabName: "PL",
  valuationType: "PRICE_TO_BOOK",
  financialsDataIndex: 0,
  directorStatusList: [],
  shareholderTypeList: [],
  similarCompanyModalToggleStatus: false,
  industryMixUpdateModal: false,
  addIndustryMixModal: false,
  saveIndustryChangesError: "",
  industryMixLoader: { show: false },
  industryMixUpdateSuccess: false,
  resetIndustryMixChangesError: "",
  companyCharges: {},
  companyChargesModalStatus: false,
  finValuationUserChangeData: {},
  companyValuation: {},
  valuationCalModal: {
    infoModalSatus: false,
    leaveEmailModalSatus: false,
    resetModalSatus: false,
  },
  valuationSuccess: false,
  resetIndustryMixUpdateSuccess: false,
  updatedMixIndustries : [],
  noFinancialValuationModal : {toggleStatus : false, turnoverValue : ""},
  showDailySearchExpireModal: false
}, action) => {
  switch (action.type) {
    case GET_COMPANY_PROFILE_SUCCESS:
      let companyProfile = { ...action.payload.companyProfile };
      const {
        balanceSheet = {},
        profitLoss = {},
        compValuation = {}
      } = companyProfile;

      const {
        latestNetAsset,
        NetAssets_CAGR1Yr,
        netAssets = {},
        latestTotalAsset,
        TotalAssets_CAGR1Yr,
        totalAssets = {},
        latestCash,
        Cash_CAGR1Yr,
        cashData = {}
      } = balanceSheet;

      balanceSheetCards.subCardList = balanceSheetCards.subCardList.map((element, i) => {
        if (i === 0) {
          const {
            labels = [],
            graphData = []
          } = netAssets;
          element.value = latestNetAsset;
          element.margin = NetAssets_CAGR1Yr;
          element.labels = [...labels];
          element.graphData = [...graphData];
          return element;
        } else if (i === 1) {
          const {
            labels = [],
            graphData = []
          } = totalAssets;
          element.value = latestTotalAsset;
          element.margin = TotalAssets_CAGR1Yr;
          element.labels = [...labels];
          element.graphData = [...graphData];
          return element;
        } else {
          const {
            labels = [],
            graphData = []
          } = cashData;
          element.value = latestCash;
          element.margin = Cash_CAGR1Yr;
          element.labels = [...labels];
          element.graphData = [...graphData];
          return element;
        }
      });

      const {
        latestTurnover,
        Turnover_CAGR1Yr,
        turnoverData = {},
        latestOperatingProfit,
        OperatingProfit_CAGR1Yr,
        operatingProfitData = {},
        latestGrossMargin,
        greenValueGrossMargin,
        grossMarginData = {},
        latestTurnOverType = "",
        latestOperatingProfitType = "",
        latestGrossMarginType = ""
      } = profitLoss;
      profitLossCards.subCardList = profitLossCards.subCardList.map((profitLossElement, index) => {
        if (index === 0) {
          const {
            labels = [],
            graphData = []
          } = turnoverData;
          profitLossElement.uniqueName = "turnover";
          profitLossElement.value = latestTurnover;
          profitLossElement.margin = Turnover_CAGR1Yr;
          profitLossElement.labels = [...labels];
          profitLossElement.graphData = [...graphData];
          profitLossElement.type = latestTurnOverType
          return profitLossElement;
        } else if (index === 1) {
          const {
            labels = [],
            graphData = []
          } = operatingProfitData;
          profitLossElement.uniqueName = "operatingProfit";
          profitLossElement.value = latestOperatingProfit;
          profitLossElement.margin = OperatingProfit_CAGR1Yr;
          profitLossElement.labels = [...labels];
          profitLossElement.graphData = [...graphData];
          profitLossElement.type = latestOperatingProfitType
          return profitLossElement;
        } else {
          const {
            labels = [],
            graphData = []
          } = grossMarginData;
          profitLossElement.uniqueName = "grossMargin";
          profitLossElement.value = latestGrossMargin + "%";
          profitLossElement.margin = greenValueGrossMargin;
          profitLossElement.labels = [...labels];
          profitLossElement.graphData = [...graphData];
          profitLossElement.type = latestGrossMarginType
          return profitLossElement;
        }
      })
      companyProfile = {
        ...companyProfile,
        balanceSheetCards,
        currentBalanceSheetCard: balanceSheetCards.subCardList[0],
        profitLossCards,
        currentProfitLossCard: profitLossCards.subCardList[0]
      };
      let defaultValuationType = compValuation.defaultValuationType || state.valuationType;
      let compValuationAsPerType = compValuation && Object.keys(compValuation).length ? getCompValuationAsPerType(compValuation, defaultValuationType) : {};
      return {
        ...state, companyProfile, financialsDataIndex: 0, valuationType: defaultValuationType,
        compValuationAsPerSelectedType: compValuationAsPerType
      };
    case GET_COMPANY_HOUSE_FILINGS_SUCCESS:
      return { ...state, companyHouseFiling: action.payload.companyHouseFiling };
    case SET_CURRENT_BALANCESHEET_CARD:
      let companyProfileObj = { ...state.companyProfile };
      companyProfileObj.currentBalanceSheetCard = action.payload.selectedCard;
      return { ...state, companyProfile: companyProfileObj };
    case SET_CURRENT_PROFITLOSS_CARD:
      let companyProfileObj1 = { ...state.companyProfile };
      companyProfileObj1.currentProfitLossCard = action.payload.selectedCard;
      return { ...state, companyProfile: companyProfileObj1 };
    case CHANGE_FINANCIAL_TAB:
      return { ...state, financialTabName: action.payload.tabName };
    case CHANGE_FINANCIALS_DATA_INDEX:
      return { ...state, financialsDataIndex: action.payload.newIndex };
    case GET_DIRECTOR_STATUS_LIST_SUCCESS:
      return { ...state, directorStatusList: action.payload.directorStatusList }
    case GET_DIRECTOR_SHOW_MORE_SUCCESS:
      let compProfile = { ...state.companyProfile };
      compProfile.directors = action.payload.directorList
      compProfile.directorCount = action.payload.directorCount
      return { ...state, companyProfile: compProfile }

    case GET_SHAREHOLDER_TYPE_LIST_SUCCESS:
      return { ...state, shareholderTypeList: action.payload.shTypeList }
    case GET_SHAREHOLDER_SHOW_MORE_SUCCESS:
      let compProfileSh = { ...state.companyProfile };
      compProfileSh.shareholders = action.payload.shList
      compProfileSh.shareholderCount = action.payload.shareholderCount
      return { ...state, companyProfile: compProfileSh }
    case EMPTY_COMPANY_PROFILE:
      return { ...state, companyProfile: action.payload };
    case CHANGE_VALUATION_SECTION_DATA:
          let compValuationFrmState = { ...state.companyProfile?.compValuation };
          compValuationFrmState = "defaultValuationType" in compValuationFrmState ? compValuationFrmState : { ...state?.companyValuation?.compValuation }
          let compValuationAsPerSelectedType = compValuationFrmState && Object.keys(compValuationFrmState).length ? getCompValuationAsPerType(compValuationFrmState, action.payload.valuationType) : {};
          let changeFinalIndMultiple = getIndustryValAsPerType(action.payload.valuationType, state.companyValuation?.finalIndustryMultiples)

          return {
              ...state, valuationType: action.payload.valuationType,
              compValuationAsPerSelectedType, finalIndMultiple: changeFinalIndMultiple
          };
    case GET_INDUSTRY_MULTIPLE_DATA_SUCCESS:
      let companyProfileClone = { ...state.companyProfile };
      companyProfileClone.industryMultiple = { ...action.payload.industryMultiple };
      return { ...state, companyProfile: companyProfileClone };
    case GET_INDUSTRY_MULTIPLE_DATA_FAILED:
      return { ...state, industryMultipleError: action.payload.errorMessage };

    case GET_COMPANY_PAGE_URL:
      return { ...state, companyPageUrl: action.payload.companyPageUrl };
    case SET_SIMILAR_COMPANY_MODAL_TOGGLE_STATUS:
      return { ...state, similarCompanyModalToggleStatus: action.payload.flag }
    case TOGGLE_SUBMIT_INVESTMENT_INTEREST_MODAL:
      return {
        ...state,
        submitInterestModalStatus: {
          ...action.payload
        },
        submitInterestErrorMessage: ""
      };
    case SUBMIT_INVESTMENT_INTEREST_FAILED:
      return { ...state, submitInterestErrorMessage: action.payload.errorMessage }
    case SET_INDUSTRY_MIX_UPDATE_MODAL:
      return { ...state, industryMixUpdateModal: { ...action.payload } };
    case SET_ADD_INDUSTRY_MIX_MODAL:
      return { ...state, addIndustryMixModal: action.payload.toggleStatus }
    case SAVE_INDUSTRY_MIX_CHANGES_FAILED:
      return { ...state, saveIndustryChangesError: action.payload.error }
    case SET_INDUSTRY_MIX_LOADER:
      return { ...state, industryMixLoader: action.payload.loaderObj }
    case SET_INDUSTRY_MIX_UPDATE_SUCCESS:
      return { ...state, industryMixUpdateSuccess: action.payload.status }
    case RESET_INDUSTRY_MIX_CHANGES_FAILED:
      return { ...state, resetIndustryMixChangesError: action.payload.error };
    case SET_AGREE_YES_CHOICE:
      return { ...state, isAgreeYesClicked: action.payload.isAgreeYesClicked };
    case SET_SIMILAR_COMPANIES_LOADING_FLAG:
      return { ...state, isSimilarCompaniesLoading: action.payload.flag };
    case GET_COMPANY_CHARGES_SUCCESS:
      return { ...state, companyCharges: action.payload.charges };
    case SET_COMPANY_CHARGES_MODAL:
      return { ...state, companyChargesModalStatus: action.payload.toggleStatus };
    case TOGGLE_VALUATION_INFO_MODAL:
      return { ...state, valuationCalModal: action.payload.toggleStatus };
    case SET_FINANCIAL_VALUATION_USER_DATA:
      return { ...state, finValuationUserChangeData: action.payload.valuationUserData };
    case GET_COMPANY_VALUATION_SUCCESS:
      let companyValuation = { ...action.payload.companyValuation }
      let defaultValuationTypeForCal = companyValuation?.compValuation?.defaultValuationType;
      let compValuationAsPerTypeForCal = companyValuation?.compValuation &&
        Object.keys(companyValuation?.compValuation).length ?
        getCompValuationAsPerType(companyValuation?.compValuation, defaultValuationTypeForCal) : {};
      const finalIndMultiple = getIndustryValAsPerType(defaultValuationTypeForCal, companyValuation?.finalIndustryMultiples)

      return {
        ...state, companyValuation, valuationType: defaultValuationTypeForCal,
        compValuationAsPerSelectedType: compValuationAsPerTypeForCal,
        finalIndMultiple
      };
    case GET_COMPANY_VALUATION_USER_EDIT_SUCCESS:
      let userEdited = { ...action.payload.userEdited };
      let companyValuationClone = { ...state.companyValuation };
      if (Object.keys(userEdited?.newSliderRange).length > 0) {
        companyValuationClone["valuationMinMaxRange"] = userEdited?.newSliderRange;
      }

      let existedVauationType = state?.valuationType;
      const updatedFinalIndMultiple = getIndustryValAsPerType(existedVauationType, userEdited?.finalIndustryMultiples)
      let compValuationAsPerTypeUpdated = getCompValuationAsPerType(userEdited?.compValuation, existedVauationType)
          companyValuationClone["financialCalData"] = userEdited?.financialCalDataUpdated;
          companyValuationClone["finalIndustryMultiples"] = userEdited?.finalIndustryMultiples;
          companyValuationClone["compValuation"] = userEdited?.compValuation;
          return {
              ...state, compValuationAsPerSelectedType: compValuationAsPerTypeUpdated,
              finalIndMultiple: updatedFinalIndMultiple, companyValuation: companyValuationClone,
          }
    case NO_FINANCIAL_VALUATION_MODAL:
      return { ...state, noFinancialValuationModal: action.payload.noFinancialValuationModal };
    case TOGGLE_HEALTH_CHECK_MODAL:
      return { ...state, isHealthCheckModalOpen: action.payload.toggleStatus };
    case RESET_INDUSTRY_MIX_CHANGES_SUCCESS:
      return { ...state, valuationSuccess: action.payload.message };
    case SET_BUY_CREDIT_REPORT_MODAL_DATA: {
      return { ...state, buyCrModalObj: { ...action.payload.modalData } };
    }
    case TOGGLE_BUY_CREDIT_REPORT_MODAL: {
      return { ...state, buyCrModalObj: { toggleStatus: action.payload.toggleStatus } };
    }
    case SET_UPDATED_MIX_INDUSTRIES : {
      return {...state, updatedMixIndustries : action.payload}
    }
    case TOGGLE_REPORT_PACKAGE_DISPLAY : {
      return {...state, reportPackageDisplay : action.payload.reportPackageDisplay}
    }
    case SET_COMPANY_PROFILE_VALUATION_MAPPING : {
      let companyProfile = { ...state.companyProfile };
      companyProfile.valuationRptMapping = action.payload.valuationRptMapping;
      return { ...state, companyProfile }
    }
    case "RESET_INDUSTRY_MIX_UPDATE_SUCCESS": {
      return { ...state, resetIndustryMixUpdateSuccess: action.payload.status }
    }
    case SHOW_DAILY_SEARCH_EXPIRE_MODAL: {
      return {...state, showDailySearchExpireModal: action.payload.showDailySearchExpireModal}
    }
    case GET_COMPANY_PROFILE_FAILED: {
      const companyProfileError = action.payload.companyProfileFailedMsg
      return { ...state, companyProfileError }
    }
    default:
      return state;
  }
}

export default companyReducer;

const getCompValuationAsPerType = (compValuation, valuationType) => {
  if (valuationType === "EBITDA") {
    return compValuation.ebitdaValuation;
  } else if (valuationType === "TURNOVER") {
    return compValuation.turnoverValuation;
  } else {
    return compValuation.priceToBookValuation;
  }
}
const getIndustryValAsPerType = (valuationTypeTab, pomMulAdjustment) => {
  if (valuationTypeTab === "EBITDA") {
    return pomMulAdjustment?.EBITDAMultiple;
  } else if (valuationTypeTab === "TURNOVER") {
    return pomMulAdjustment?.turnoverMultiple;
  } else {
    return pomMulAdjustment?.netAssetsMultiple;
  }
}