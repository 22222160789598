import React from 'react';
import { Doughnut, defaults } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import './IndustryChartAndCompany.css';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { ET_SEE_ALL_BUTTON_CLICK, ET_DCHART_COMPANY_LINK_CLICK } from '../../../common/trackingConstants';

const industryChartOptions = {
    legend: {
        display: false,
    },
    animation: false,
    tooltips: { enabled: false },
    hover: { mode: null },
    maintainAspectRatio: false,
    responsive: true,
    cutoutPercentage: 50,
    plugins: {
        datalabels: {
            formatter: (value) => value + "%",
            color: "#ffffff",
            font: {
                size: ".6rem",
                lineHeight: "14px",
                style: "bolder"
            },
            display: false
        },
    }
}

const IndustryChart = (props) => {
    defaults.global.animation = false;
    const {
        heading = "",
        singleChartData = {},
        chartData = [],
        companyHeadings = {},
        comapnyDataList = [],
        keyProps = "",
        sendActivityTracking = () => { },
        seeMoreBtnPositionInPieChartSec = "",
        urlForSeeMoreInPieChartSec = "",
        showSeeMoreButton = true
    } = props;
    const handleButtonClick = () => {
        props.handleSeeAllButtonClick(urlForSeeMoreInPieChartSec, ET_SEE_ALL_BUTTON_CLICK);
    }
    const colorList = singleChartData.datasets[0].backgroundColor;
    return (
        <div key={keyProps} >
            <div className="industry-chart-heading" pomandae2e={heading}>{heading}</div>
            <div className='industry-donut-chart-comp-list-wrapper'>
                <div className="industry-chart-content-style">
                    <div className="industry-chart-section-style">
                        <Doughnut data={singleChartData} options={industryChartOptions} />
                    </div>
                    <div className="industry-label-section">
                        {chartData.map((data, index) => {
                            return (
                                <div className="industry-label-row" key={`industry_breakdown_${index}`}>
                                    <div className="industry-chart-label-point pt-1 mr-2" style={{ background: colorList[index] }}></div>
                                    <p className={"industry-chart-label-text mb-0"}>{data.percentage}% {data.tag}</p>
                                </div>
                            )
                        })}
                    </div>
                    {showSeeMoreButton && seeMoreBtnPositionInPieChartSec === "right"
                        && <button onClick={handleButtonClick}
                            className="industry-chart-button align-self-center" pomandae2e="SEE_ALL">SEE ALL</button>}
                </div>
                <div className="industry-company-list-after-donut-sec">
                    <div className="industry-list-headings-outer-div">
                        <div className="industry-list-headings-style">{companyHeadings.companyNameHeading}</div>
                        <MediaQuery minWidth={992}>
                            <div className="industry-list-headings-style">{companyHeadings.valueHeading}</div>
                        </MediaQuery>
                    </div>
                    {comapnyDataList.map((data, i) => {
                        const url = `/company/${data.reg}/${encodeURIComponent(data.company.toLowerCase().replace(/ /g, "-"))}`;
                        return (
                            <div key={`company-data-list-${i}`} className="industry-list-data-outer-div">
                                <Link to={url} className="industry-list-data-style industry-list-data-link-style"
                                    onClick={() => sendActivityTracking({ eventType: ET_DCHART_COMPANY_LINK_CLICK, attribute3: url })}
                                    pomandae2e={data.company}>{data.company}</Link>
                                <div className="industry-list-data-style industry-list-data-value-style" pomandae2e={data.value}>{data.value}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
            {showSeeMoreButton && seeMoreBtnPositionInPieChartSec === "bottom"
                && <button onClick={handleButtonClick}
                    className="industry-chart-button" pomandae2e="SEE_ALL">SEE ALL</button>}
        </div>
    );
}

export default IndustryChart;