import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DropdownTextOption } from '../../components/HomePage/HomepageComponents';
import { sendActivityTracking } from '../../redux/actions/commonAction';
import { convertToUkCurrencyWithoutPoundSymbol } from '../CurrencyConverter';
import { ET_SINGLE_SEARCH_COMPANY_CLICK, ET_VIEW_ALL_COMPANIES_BTN_CLICK } from '../trackingConstants';

class IsolatedSearchDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchtext: ""
        }
    }
    getOptionClickActivityTracking = (eventType, attribute = "") => {
        this.props.dispatchSendActivityTracking({
            eventPage: this.props.eventPage,
            eventType,
            attribute1: this.props.eventCreatedOptionClick,
            attribute2: attribute
        });
    }
    render() {
        const {
            buttonText = "View all companies",
            dropdownClass = "isolated-dropdown-div",
            webPimgSrc = "/assets/webpImages/company_logo_black.webp",
            imgSrc = "/assets/svgs/company_logo_black.svg",
            companyList = [],
            companyCount,
            searchTextboxValue,
            btnLink = () => { },
            dropDowBtnShow = true,
            userType = "",
            prevPath = "",
            DropdownItemTag="",
            optionOnClick = () => {},
            dropdownItemClassName = ""
        } = this.props
        const newCompanyList = companyList.slice(0, 5)
        const isCountOrText = searchTextboxValue ? companyCount : searchTextboxValue
        return (
            <>
                {companyCount > 0 &&
                    newCompanyList?.map((resultedItem, index) => <DropdownTextOption
                        key={index}
                        renderLink={userType === "basic"
                            ? `/solutions/companyvaluation/companies/${resultedItem.companyId}/selectpackage`
                            : `/solutions/companyvaluation/companies/${resultedItem.companyId}/selectpackage/ppc`}
                        imgSrc={imgSrc}
                        webPimgSrc={webPimgSrc}
                        name={resultedItem.companyName}
                        prevPath={prevPath}
                        optionOnClick={(e)=>optionOnClick(e,{companyId: resultedItem.companyId, companyName: resultedItem.companyName, status: resultedItem.status, address: resultedItem.address })}
                        dropdownClass={dropdownClass}
                        getOptionClickActivityTracking={() =>
                            this.getOptionClickActivityTracking(ET_SINGLE_SEARCH_COMPANY_CLICK, resultedItem.companyId)}
                        DropdownItemTag={DropdownItemTag} //send this prop as blank string or null if you want to render Link from DropdownTextOption component
                        dropdownItemClassName={dropdownItemClassName}
                    />)}
                {dropDowBtnShow && <div className="dropdown-item border-bottom-0">
                    <button type="button" className="isolated-dropdown-btn" onClick={() => btnLink({
                        eventType: ET_VIEW_ALL_COMPANIES_BTN_CLICK,
                        attribute1: "SEARCH_BAR_DROPDOWN_BTN"
                    })} >
                        {buttonText} { isCountOrText ? `(${convertToUkCurrencyWithoutPoundSymbol(companyCount)})` : ""}</button>
                </div>}
            </>
        )
    }
}
const mapDispatchToProps = (dispatch) => ({
    dispatchSendActivityTracking: (trackingObj) => dispatch(sendActivityTracking(trackingObj)),
})
export default withRouter(connect(null, mapDispatchToProps)(IsolatedSearchDropdown));