import {
    SET_SCREEN_WIDTH_HEIGHT, SEND_ACTIVITY_TRACKING, SET_HEADER_HIDE,
    GET_ONLOAD_DATA_API, GET_ONLOAD_DATA_API_SUCCESS, GET_ONLOAD_DATA_API_ERROR,
    CHECK_STORAGE_AND_SEND_TRK_DATA,CALL_TRACKER_API, TOGGLE_COMMON_MSG_MODAL,
    SET_CONFIRMATION_MODAL, SET_DELETE_SEARCH_CONFIRMATION, 
    TOGGLE_COMING_SOON_MODAL, TOGGLE_INFORMATION_MODAL, 
    TOGGLE_SHARE_URL_MODAL, TOGGLE_CONTENT_SHARE_MODAL, TOGGLE_SORT_MODAL,
    SET_HEADER_SEARCH_TEXT, SET_HEADER_SINGLE_SEARCH_OPTIONS,
    SET_STICKY_HEADER_PROPS, SET_LOADING,
    TOGGLE_TEMPLATE_LIST_MODAL,
    SET_SYSTEM_INFO, 
    SET_CHOOSE_PACKAGE_MODAL_DATA, SET_AB_TEST_USER_VARIANT, TOGGLE_SEEMORE_MODAL, 
    SET_WHITELIST_IP_STATUS, WHITE_LABEL_CONFIG_DATA, SET_GOOGLE_BOT_STATUS, SET_ACCESS_TOKEN_STATUS, SET_CURRENCY_DROPDOWN_STATUS
} from "./actionConstants";

export const setScreenWidthHeight = (screenObject) => ({
    type: SET_SCREEN_WIDTH_HEIGHT,
    payload: { screenObject }
});

export const sendActivityTracking = (activityObject) => ({
    type: SEND_ACTIVITY_TRACKING,
    payload: { activityObject }
})

export const setHideHeader = (headerProps) => ({
    type: SET_HEADER_HIDE,
    payload: { headerProps }
})

export const callOnloadApi = (latestTid) => ({
    type: GET_ONLOAD_DATA_API,
    payload: {latestTid:latestTid}
})
export const onloadApiSuccess = (data) => ({
    type: GET_ONLOAD_DATA_API_SUCCESS,
    payload: data
})
export const onloadApiError = () => ({
    type: GET_ONLOAD_DATA_API_ERROR,
    payload: {}
})

export const checkStorageAndSendTrkDataAction = () => ({
    type: CHECK_STORAGE_AND_SEND_TRK_DATA,
    payload: {  }
})

export const callTrackerAPIAction = (userTrackerObj) => ({
    type: CALL_TRACKER_API,
    payload: {userTrackerObj}
});

export const toggleCommonMsgModal = (modalInfoObj) => ({
    type: TOGGLE_COMMON_MSG_MODAL,
    payload: { modalInfoObj }
});

export const setConfirmationModal = (status) => ({
    type: SET_CONFIRMATION_MODAL,
    payload: { status }
});

export const setDeleteSearchConfirmation = (status) => ({
    type: SET_DELETE_SEARCH_CONFIRMATION,
    payload: { status }
});

export const toggleComingSoonModal = toggleStatus => ({
    type: TOGGLE_COMING_SOON_MODAL,
    payload: {toggleStatus}
});

export const toggleInformationModal = toggleStatus => ({
    type: TOGGLE_INFORMATION_MODAL,
    payload: {toggleStatus}
});

export const toggleShareUrlModal = shareUrlModalObj => ({
    type: TOGGLE_SHARE_URL_MODAL,
    payload: { shareUrlModalObj }
});

export const toggleContentShareModal = contentShareModalObj => ({
    type: TOGGLE_CONTENT_SHARE_MODAL,
    payload: { contentShareModalObj }
});

export const toggleSortModal = toggleStatus => ({
    type: TOGGLE_SORT_MODAL,
    payload: {toggleStatus}
});

export const setHeaderSearchText = searchText => ({
    type: SET_HEADER_SEARCH_TEXT,
    payload: {searchText}
});

export const setHeaderSingleSearchOptions = optionObj => ({
    type: SET_HEADER_SINGLE_SEARCH_OPTIONS,
    payload: {optionObj}
});

export const setStickyHeaderProps = headerProps => ({
    type: SET_STICKY_HEADER_PROPS,
    payload: { headerProps }
});

export const setLoading = loadingObj => ({
    type: SET_LOADING,
    payload: { loadingObj }
});

export const toggleTemplateListModal = toggleStatus => ({
    type: TOGGLE_TEMPLATE_LIST_MODAL,
    payload: { toggleStatus }
});

export const setSystemInfo = systemObj => ({
    type: SET_SYSTEM_INFO,
    payload: { systemObj }
});

export const setChoosePackageModalData = (modalData) => ({
    type: SET_CHOOSE_PACKAGE_MODAL_DATA,
    payload: { modalData }
});

export const setUserVariant = userVariant => ({
    type: SET_AB_TEST_USER_VARIANT,
    payload: userVariant
});

export const toggleSeemoreModal = (seeMoreModalObj) => ({
    type: TOGGLE_SEEMORE_MODAL,
    payload: { seeMoreModalObj }
});

export const setWhitelistIpStatus = status => ({
    type: SET_WHITELIST_IP_STATUS,
    payload: { status }
});

export const setWhiteLabelConfigData = (whiteLabelConfig) => ({
    type: WHITE_LABEL_CONFIG_DATA,
    payload: { whiteLabelConfig }
});

export const setGoogleBotStatus = status => ({
    type: SET_GOOGLE_BOT_STATUS,
    payload: { status }
});

export const setAccessTokenStatus = accessTokenStatus => ({
    type: SET_ACCESS_TOKEN_STATUS,
    payload: { accessTokenStatus }
})

export const setCurrencyDropdownStatus = status => ({
    type: SET_CURRENCY_DROPDOWN_STATUS,
    payload: { status }
})