import React from 'react';
import MediaQuery from 'react-responsive';
import './ProgressMenuComponent.css'

const progressMenuSteps = [
    {
        "componentId": "business_plan_get_started_page",
        "step": "Business Type",
        "text": "Established company or only an idea?",
        "showHeaderDetails": false
    },
    {
        "componentId": ["business_plan_compnay_search", "business_plan_official_company_info_page", "business_plan_basic_company_info_page"],
        "step": "Business Information",
        "text": "Enter basic company information",
        "showHeaderDetails": true
    },
    {
        "componentId": "business_plan_key_people",
        "step": "Key People",
        "text": "Enter the company's key decision-makers",
        "showHeaderDetails": true
    },
    {
        "componentId": "business_plan_industry_desc",
        "step": "AI Q&A",
        "text": "Answer a few questions about your business",
        "showHeaderDetails": true
    },
    {
        "componentId": "",
        "step": "Generate Business Plan",
        "text": "Get an AI business plan for your company",
        "showHeaderDetails": false
    }
]
const ProgressMenuComponent = (props) => {
    const { businessPlanActiveComponentId,
        iconObj = {
            activeIcon: '/assets/svgs/active-bullet.svg',
            inactiveIcon: '/assets/svgs/inactive-bullet.svg',
            compeleteIcon: '/assets/svgs/complete-bullet.svg',
            width: '32px',
            height: '32px'
        },
        propsProgressMenuSteps = progressMenuSteps
    } = props;
    const selectedIndex = propsProgressMenuSteps.findIndex(data => data.componentId.includes(businessPlanActiveComponentId));

    return (<div className='business-plan-progresss-menu-wrapper'>
        <div className='progress-menu-content-container'>
            {propsProgressMenuSteps.map((data, index) => {
                return (
                    <>
                        <MediaQuery minWidth={992}>
                            <div className='progress-menu-step-wrapper' key={index}>
                                <div className='progress-menu-icon-section'>
                                    <img src={`${selectedIndex === index ? iconObj.activeIcon : index < selectedIndex ? iconObj.compeleteIcon : iconObj.inactiveIcon}`} width="32px" height="32px" alt='bullet'></img>
                                    <div className={`progress-menu-connector ${index < selectedIndex ? 'pm-active-connector' : 'pm-inactive-connector'}`}></div>
                                </div>
                                <div className='d-flex flex-column'>
                                    <p className='progress-menu-step-heading'>{data.step}</p>
                                    <p className='progress-menu-step-description'>{data.text}</p>
                                </div>
                            </div>
                        </MediaQuery>
                        <MediaQuery maxWidth={991.98}>
                            <div className={`dot-indicator ${selectedIndex === index ? 'pm-active-dot-indicator' : 'pm-inactive-dot-indicator'}`} key={index}></div>
                        </MediaQuery>
                    </>
                )
            })}
        </div>
        <MediaQuery maxWidth={991.98}>
            {progressMenuSteps[selectedIndex].showHeaderDetails ? <div className='business-plan-progress-component-details d-flex'>
                <i onClick={() => onBackBtnClick(-1)} className="business-temp-font-24 cursor-pointer font-weight-bold fa text-color-98A2B3 fa-angle-left" aria-hidden="true"></i>
                <div className='ml-2 d-flex flex-column'>
                    <p className='mb-0 business-temp-font-16 font-weight-bold'>{progressMenuSteps[selectedIndex].step}</p>
                    <p className='mb-0 mt-1 text-color-98A2B3 business-temp-font-14'>{progressMenuSteps[selectedIndex].text}</p>
                </div>
            </div> : null}
        </MediaQuery>
    </div>
    )
}

export default ProgressMenuComponent;