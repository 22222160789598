import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { isBrowser, MAIL_RECIPIENT, REACT_APP_GOOGLE_INDEX } from "../../common/config";
import { SR_VALUATION_REPORT_PKG_PURCHASE, VALUATION_REPORT_PKG } from "../../common/subscriptionConfigConst";
import {
    ET_AUTOMATED_BUSS_VALUATION_GET_START_BTN_CLICK,
    ET_ENTERPRISE_PACKAGE_CONTACT_US_BTN_CLICK,
    ET_PPC_HOMEPAGE_LEARN_MORE_BTN_CLICK, ET_PPC_HOMEPAGE_SEE_PRICING_PLANS_BTN_CLICK, ET_PRODUCT_HOMEPAGE_SEARCHBAR_ENTER_CLICK,
    ET_PRODUCT_HOMEPAGE_SINGLE_SEARCH, ET_START_VALUATION_BTN_CLICK, ET_VALUATION_REPORT_ENTERPRISE_BUY_BTN_CLICK, ET_VALUATION_REPORT_PACKAGE_BUY_NOW_BTN_CLICK,
    ET_VALUATION_REPORT_PPC_HOMEPAGE_ONLOAD,
    ET_VALUATION_REPORT_PPC_HOMEPAGE_SCROLL,
    ET_VALUATION_REPORT_PPC_SAMPLE_REPORT_BTN_CLICK,
    ET_VIEW_SAMPLE_REPORT_CLICK,
    HEADER,
    VALUATION_REPORT_PPC_HOMEPAGE_SEARCHBAR_ENTER, VALUATION_REPORT_PPC_HOMEPAGE_SEARCHBAR_ICON,
    VALUATION_REPORT_PPC_HOMEPAGE_SEARCHBAR_OPTION, VALUATION_REPORT_PPC_HOMEPAGE_SEARCHBAR_SEARCH
} from "../../common/trackingConstants";
import {
    sendActivityTracking, setHeaderSingleSearchOptions, setHideHeader
} from "../../redux/actions/commonAction";
import { handleProductPlanButtonClick } from "../../redux/actions/productAction";
import { BusinessValuationSteps } from "../BusinessValuationSteps/BusinessValuationSteps";
import { resetAllSearchParams } from "../../redux/actions/searchAction";
import { setRestrictionForProsperUser } from "../../redux/actions/userAccountAction";
import HomepageFooterV3 from "../HomePage/HomepageFooterV3/HomepageFooterV3";
import { HowToValueBusiness } from "../HowToValueBusiness/howToValueBusiness";
import "./ValuationReportPPCHomePage.css";
import ValuationReportPPCHeader from "./ValuationReportPPCHeader/ValuationReportPPCHeader";
import BussValuationAndReportComp from "./BussValuationAndReportComp/BussValuationAndReportComp";
import "../BusinessPlanPPCHomePage/InfiniteScrollComponent/InfiniteScrollComponent.css"
import ValuationReportPPCPackageSection from "./ValuationReportPPCPackageSection/ValuationReportPPCPackageSection";
import { toggleSubmitInvestmentInterestModal } from "../../redux/actions/companyAction";
import { handleBgImagesLazyLoad } from "../../common/commonUtils";
import MediaQuery from "react-responsive";
import { getCurrencySymbol } from "../../common/CurrencyConverter";
import { AI_PRODUCTS } from "../HomePage/Homepage";

const companySeoTitle = "Get A Business Valuation | Pomanda’s Company Valuation Calculator";
const metaData = "Understand the Value of a company instantly with Pomanda, use Pomanda's Company Valuation Calculator to generate a Valuation Report using 6 different valuation methods including valuation multiples.";
const valuationBusinessSections = ["ppc_valuation_calc_footer_section", "customer_reviews_section", "how_value_business_section", "buss_valuation_calc_section", "valuation_report_packages", "valuation_report_ppc_steps_section", "valuation_report_ppc_header"]

class ValuationReportPPCHomePage extends React.Component {
    constructor() {
        super();
        this.state = {
            searchTextValue: "",
            activeSection: ""
        }
    }
    componentDidMount() {
        const {
            companyQueryData = {}
        } = this.props;
        companyQueryData.isolatedSearchText && this.setState({ searchTextValue: companyQueryData.isolatedSearchText })

        this.scrollToPackagesSection();
        const headerPropsRendered = this.props.userType === "basic" ? { showSingleSearchbar: false } : {
            showSingleSearchbar: false,
            customizedLogoType: "companyValPpc",
            showPowerSearchNav: false,
            isPricingMenuShow: false,
            showSolutionsNav: false,
            showAccountLoginNav: false,
            showSignupNav: false,
            showCompanyValuationNav: true,
            showMenuToggleTabMob: false,
            showViewSampleReportBtn: false
        };
        this.props.dispatchSetHideHeader({
            backgroundDesktop: "transparent",
            backgroundTab: "transparent",
            backgroundMob: "transparent",
            ...headerPropsRendered
        });
        this.props.dispatchSetHeaderSingleSearchOptions({
            eventPage: this.props.eventPage
        });
        this.props.dispatchResetAllSearchParams("");
        this.props.dispatchSendActivityTracking({
            eventPage: this.props.eventPage,
            eventType: ET_VALUATION_REPORT_PPC_HOMEPAGE_ONLOAD
        })
        this.props.dispatchSetRestrictionForProsperUser(true)
        window.scrollTo({ top: 0 });
        handleBgImagesLazyLoad();
        window.addEventListener("scroll", this.handlePageScroll)
    }
    componentWillUnmount = () => {
        const headerPropsRendered = {
            showSingleSearchbar: false,
            customizedLogoType: null,
            showPowerSearchNav: true,
            isPricingMenuShow: true,
            showSolutionsNav: true,
            showAccountLoginNav: true,
            showSignupNav: true,
            showCompanyValuationNav: false,
            showMenuToggleTabMob: true,
        }
        this.props.dispatchSetHideHeader({
            backgroundDesktop: "blue",
            backgroundTab: "blue",
            backgroundMob: "blue",
            ...headerPropsRendered
        });
        this.props.dispatchSetRestrictionForProsperUser(false)
    }
    viewAllBtnClick = (trackingObj) => {
        this.props.dispatchSendActivityTracking({
            eventPage: this.props.eventPage,
            ...trackingObj
        })
        if (this.props.userType === "basic") {
            this.props.history.push("/solutions/companyvaluation/companies")
        } else {
            this.props.history.push("/solutions/companyvaluation/companies/ppc")
        }
    }
    handlePageScroll = (e) => {
        const headerHeight = document.getElementById('header-navbar')?.getBoundingClientRect()?.height || 0;
        valuationBusinessSections.map((sectionId) => {
            const element = document.getElementById(sectionId);
            const elementDimensions = element?.getBoundingClientRect();
            if (elementDimensions && elementDimensions.top > headerHeight && elementDimensions.top <= headerHeight + 40) {
                if (this.state.activeSection !== sectionId) {
                    this.setState({ activeSection: sectionId });
                    this.props.dispatchSendActivityTracking({
                        eventPage: this.props.eventPage,
                        eventType: ET_VALUATION_REPORT_PPC_HOMEPAGE_SCROLL,
                        attribute1: sectionId
                    })
                }
            }
        })
    }
    handlePageScrollToStartValuation = () => {
        const element = document.getElementById('valuation_report_ppc_header');
        element.scrollIntoView();
        this.props.dispatchSendActivityTracking({
            eventPage: this.props.eventPage,
            eventType: ET_AUTOMATED_BUSS_VALUATION_GET_START_BTN_CLICK
        })
    }
    onBtnClick = (productId, isEnterprise, eventType) => {
        if (isEnterprise) {
            this.props.dispatchToggleSubmitInvestmentInterestModal({
                toggleStatus: true,
                subject: "Contact Us",
                subHeading: "",
                heading: "Contact Us",
                userMessage: "What can we help with?",
                descriptionLabel: "Get in touch",
                mailRecipient: MAIL_RECIPIENT,
                eventPage: this.props.eventPage,
                eventType: ET_ENTERPRISE_PACKAGE_CONTACT_US_BTN_CLICK
            })
        } else {
            this.props.history.push("/valuationonboard")
        }
        this.props.dispatchSendActivityTracking({
            eventPage: this.props.eventPage,
            eventType: eventType ? eventType : ET_VALUATION_REPORT_PACKAGE_BUY_NOW_BTN_CLICK,
            productId
        })
    }
    onViewSampleBtnClick = (eventType, url) => {
        const trackingObj = {
            eventPage: this.props.eventPage,
            eventType,
            attribute1: HEADER
        }
        this.props.dispatchSendActivityTracking(trackingObj);
        window.open(`${window.origin}${url}`, '_blank')
    }

    handleLearnMoreBtnClick = (attribute) => {
        const trackingObj = {
            eventPage: this.props.eventPage,
            eventType: ET_PPC_HOMEPAGE_LEARN_MORE_BTN_CLICK,
            attribute1: attribute
        }
        this.props.dispatchSendActivityTracking(trackingObj);
        this.props.history.push("/blog/613204a39096914feb052bf8")
    }

    scrollToPackagesSection = () => {
        const { history = {}, location = {} } = this.props;
        setTimeout(() => {
            if (location.hash && location.hash !== "") {
                let element = document.getElementById(location.hash.substr(1));
                let headerHeight = document.getElementById("header-navbar")?.getBoundingClientRect().height || 80;
                if (element) {
                    window.scrollTo({ top: element.getBoundingClientRect().top - headerHeight, behavior: 'smooth' });
                }
            }
        }, 500);
        setTimeout(() => {
            location.hash && location.hash !== "" && history.replace(window.location.pathname)
        }, 1000);
    }

    onSeePricingPlansBtnClick = () => {
        const trackingObj = {
            eventPage: this.props.eventPage,
            eventType: ET_PPC_HOMEPAGE_SEE_PRICING_PLANS_BTN_CLICK,
        }
        this.props.dispatchSendActivityTracking(trackingObj);
        const reportPackageElement = document.getElementById("valuation_report_packages");
        reportPackageElement?.scrollIntoView({ behavior: 'smooth' }, true);
    }

    getValuationHeaderTexts = (userType) => {
        return {
            headerText: `Get a Business Valuation Report <br> <span class="ai-valuation-highlighted-text"> in under 10 minutes </span>`,
            buttonText: "View Sample Report",
            eventType: ET_VALUATION_REPORT_PPC_SAMPLE_REPORT_BTN_CLICK,
            event: "scrollToPackages",
            url: '/assets/Download/PomandaBromptonBicycle.pdf'
        }
    }

    render() {
        const {
            staticContext = {},
            history = {},
            valuationRpCount = 0,
            userType = "",
            screen = {},
            selectedCurrency
        } = this.props;
        let href, origin;
        if (isBrowser) {
            href = window.location.href;
            origin = window.location.origin;
        } else {
            href = `${staticContext.baseHref}${history.location.pathname}`
            origin = staticContext.baseHref
        }

        const ALPHA_SECTION_CONTENT = ["Company Overview", "Key People", "Six Valuation Methods", "Market Approach", "Asset Approach", "Pomanda Tips"];
        const customerReview = [{
            reviewer: "Managing Director",
            message: "Everything you need to value your business at a very reasonable price.",
            rating: "/assets/svgs/five_stars.svg",
            alt: "five_stars_img"
        }, {
            reviewer: "Business Broker",
            message: `It’s great Pomanda’s industry multiples are fast moving and updated monthly.`,
            rating: "/assets/svgs/five_stars.svg",
            alt: "five_stars_img"
        }, {
            reviewer: "Business Owner",
            message: "The valuation calculator was simple to use and helped me understand what my business is worth now and what it could be worth in the future",
            rating: "/assets/svgs/five_stars.svg",
            alt: "five_stars_img"
        }, {
            reviewer: "Managing Director",
            message: "The preloaded financial data is really helpful and i really like that you can update the calculation as many times as you want for 30 days",
            rating: "/assets/svgs/four_and_half_stars.svg",
            alt: "four_and_half_stars_img"
        }, {
            reviewer: "CEO",
            message: "Great to have 6 different ways of valuing my business, all explained really clearly",
            rating: "/assets/svgs/four_stars.svg",
            alt: "four_stars_img"
        }, {
            reviewer: "Business Owner",
            message: "The valuation calculator was simple to use and was preloaded with my financial information",
            rating: "/assets/svgs/five_stars.svg",
            alt: "five_stars_img"
        }, {
            reviewer: "Business Owner",
            message: "Really easy to use. I liked the fact that I can go in and update the figures and in effect, do some what-if scenarios. Very flexible.",
            rating: "/assets/svgs/five_stars.svg",
            alt: "five_stars_img"
        }];

        const valuationReportHeaderObj = this.getValuationHeaderTexts(userType);
        return <div id="valuation-report-ppc-homepage">
            <Helmet>
                <meta name="og:url" content={href} />
                <meta property="og:title" content={companySeoTitle} />
                <meta property="og:description" content={metaData} />
                <meta property="og:image" content={`${origin}/assets/images/value_business_ppc_bg.png`} />
                <meta name="twitter:title" content={companySeoTitle} />
                <meta name="twitter:image" content={`${origin}/assets/images/value_business_ppc_bg.png`} />
                <meta name="twitter:url" content={href} />
                <title>{companySeoTitle}</title>
                <meta name="description" content={metaData} />
                <link rel="canonical" href={href} />
                {(!REACT_APP_GOOGLE_INDEX || this?.props?.userType !== "basic") && <meta name="robots" content="noindex" />}
            </Helmet>
            <div id="valuation_report_ppc_header" className="valuation-report-ppc-header-value-business">
                <ValuationReportPPCHeader
                    heading={valuationReportHeaderObj?.headerText}
                    eventPage={this.props.eventPage}
                    id="business_valuation_header_component"                                       
                />
            </div>
            <div id="valuation_report_ppc_steps_section" className="valuation-report-ppc-step-component">
                <BusinessValuationSteps
                    sendActivityTracking={this.props.dispatchSendActivityTracking}
                    eventPage={this.props.eventPage}
                />
            </div>
            <div id="buss_valuation_calc_section" className="buss-valuation-calc-wrapper lazy-bg">
                <BussValuationAndReportComp
                    heading="About Pomanda's Business Valuation Calculator & Report"
                    valuationAndCalculatorCards={[{
                        heading: "Valuation Calculator",
                        subHeading: "The Pomanda Valuation Calculator allows users to fully interact with the data, add their most recent figures, model future performance and see their company valuation recalculate in real time.",
                        imageObj: {
                            imageUrl: "/assets/images/valuation_calc_insights.png",
                            webpImageUrl: "/assets/webpImages/valuation_calc_insights.webp",
                            alt: "valuation_calc_insights",
                            width: "670",
                            height: "288",
                            className: ""
                        }
                    }, {
                        heading: "Professional Business Valuation",
                        subHeading: "Instantly generate a detailed, 19 page Professional Business Valuation Report that explains six valuation methodologies, the calculations and the most relevant uses for each valuation.",
                        imageObj: {
                            imageUrl: "/assets/images/prof_business_valuation_img.png",
                            webpImageUrl: "/assets/webpImages/prof_business_valuation_img.webp",
                            alt: "prof_business_valuation_img",
                            width: "456",
                            height: "289",
                            className: ""
                        }
                    }]}
                    automatedBusinessValuation={{
                        heading: "Automated Business Valuations",
                        linkNote: `Get Started <i class="fa fa-angle-right font-weight-600 ml-2" aria-hidden="true"></i>`,
                        subHeading: "Get an up-to-date business valuation by entering your latest financial data into Pomanda's Business Valuation Calculator",
                        imageObj: {
                            desktopImage: {
                                imageUrl: '/assets/svgs/pom_ai_automated_buss_val.svg',
                                webpImageUrl: '/assets/webpImages/pom_ai_automated_buss_val.webp',
                                width: '1200',
                                height: '350',
                                alt: 'automated_val_img'
                            },
                            mobileImage: {
                                imageUrl: '/assets/svgs/pom_ai_automated_buss_mobile_val.svg',
                                webpImageUrl: '/assets/webpImages/pom_ai_automated_buss_mobile_val.webp',
                                width: '343',
                                height: '288',
                                alt: 'automated_val_mobile_img'
                            }
                        },
                        linkNoteClick: this.handlePageScrollToStartValuation
                    }}
                />
            </div>
            <div className="valuation-report-packages lazy-bg" id="valuation_report_packages">
                <ValuationReportPPCPackageSection
                    heading={`Explore Pomanda’s Valuation Packages`}
                    onBtnClick={this.onBtnClick}
                    eventPage={this.props.eventPage}
                    packages={[{
                        productId: "ai_oneTime1ValuationReportPkg",
                        amount: `${getCurrencySymbol(selectedCurrency)} ${AI_PRODUCTS[0]?.price[selectedCurrency].value}`,
                        reports: 1,
                        label: "Single",
                        isEnterprise: false,
                        btnType: "white",
                    },
                    {
                        productId: "oneTimeEnterpriseValuationReportPkg",
                        label: "Enterprise",
                        textNote: "Interested in a valuation package for larger organisations? Get in touch to learn more about our Enterprise offering.",
                        isEnterprise: true,
                        eventType: ET_VALUATION_REPORT_ENTERPRISE_BUY_BTN_CLICK
                    }]}
                    valuationRpCount={valuationRpCount}
                />
            </div>
            <div id="how_value_business_section" className="how-to-value-business lazy-bg">
                <HowToValueBusiness
                    componentName="how-to-value-business"
                    heading="How does Pomanda value a Business?"
                    subHeading="Pomanda Plus subscribers can view 'Estimated Valuations' for any company on their profile page."
                    cardDataList={[
                        {
                            cardType: "How to value a business",
                            cardHeading: "Relative Valuation",
                            cardText: "At Pomanda our focus is on Relative Valuation, where the value of a company is based on the price of similar companies i.e. public traded companies in the same or related industries.",
                            icon: "solar_chart_icon.svg",
                            width: "52",
                            height: "52"
                        },
                        {
                            cardType: "How to value a business",
                            cardHeading: "Valuation Calculator",
                            cardText: "Using publicly traded companies ensures that each industry multiple is calculated from a sizeable, recognised and measurable dataset.",
                            icon: "white_calculator_icon.svg",
                            width: "52",
                            height: "52"
                        },
                        {
                            cardType: "How to value a business",
                            cardHeading: "Bespoke Multiples",
                            cardText: "We calculate monthly multiples for every industry, then we adjust for several factors (such as gross margin and Illiquidity) to create a bespoke Pomanda multiple for every company that we value.",
                            icon: "round_pie_chart_icon.svg",
                            width: "52",
                            height: "52"
                        }
                    ]}
                    headingTag="h2"
                />
            </div>
            <div id="customer_reviews_section" className="customer-reviews-wrapper">
                <div className="customer-reviews-container">
                    <h2 className="customer-reviews-heading m-0">Discover why business owners and professionals trust our service for their business valuations.</h2>
                    <div className={screen?.width && screen.width < 992 ? "horizontal-scroll-container" : "vertical-scroll-container"}>
                        <div className="horizontal-scroll-group powerful-business-plan-scroll-container">
                            {customerReview.map((reviewObj, index) => {
                                return <div className="customer-review-box" key={`customer_review_box_1_${index}`}>
                                    <div className="">
                                        <img data-src='/assets/svgs/yellow_quote.svg' alt='feedback_quotes_img' width={20} height={20}
                                            className='lazyload rotate-180 mb-1' />
                                        <p className="customer-message">{reviewObj.message}</p>
                                    </div>
                                    <p className="customer-reviewer">{reviewObj.reviewer}</p>
                                </div>
                            })}
                        </div>
                        <div className="horizontal-scroll-group powerful-business-plan-scroll-container mt-sm-4 ml-4 ml-sm-0">
                            {customerReview.map((reviewObj, index) => {
                                return <div className="customer-review-box" key={`customer_review_box_2_${index}`}>
                                    <div className="">
                                        <img data-src='/assets/svgs/yellow_quote.svg' alt='feedback_quotes_img' width={20} height={20}
                                            className='lazyload rotate-180 mb-1' />
                                        <p className="customer-message">{reviewObj.message}</p>
                                    </div>
                                    <p className="customer-reviewer">{reviewObj.reviewer}</p>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="ppc-valuation-report-homepage-footer-wrapper">
                <div className="ppc-valuation-report-homepage-footer">
                    <HomepageFooterV3 />
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => ({
    valuationRpCount: state.userProfile.userInfo?.valuationReportPkgSubscription?.valuationRpCount,
    companyQueryData: state.searchReducer.companyQueryData,
    screen: state.common.screen,
    selectedCurrency: state.userProfile.userInfo?.aiCurrency || state.userProfile?.countryBasedCurrency
})

const mapDispatchToProps = (dispatch) => ({
    dispatchSetHideHeader: (headerProps) => dispatch(setHideHeader(headerProps)),
    dispatchHandleProductPlanButtonClick: (productObj, eventPage) => dispatch(handleProductPlanButtonClick(productObj, eventPage)),
    dispatchSendActivityTracking: (activityObject) => dispatch(sendActivityTracking(activityObject)),
    dispatchSetHeaderSingleSearchOptions: (optionsObj) => dispatch(setHeaderSingleSearchOptions(optionsObj)),
    dispatchResetAllSearchParams: searchParam => dispatch(resetAllSearchParams(searchParam)),
    dispatchSetRestrictionForProsperUser: (isAccessable) => dispatch(setRestrictionForProsperUser(isAccessable)),
    dispatchToggleSubmitInvestmentInterestModal: (interestObj) => dispatch(toggleSubmitInvestmentInterestModal(interestObj))
})

export default connect(mapStateToProps, mapDispatchToProps)(ValuationReportPPCHomePage);