import React from "react";
import "./BussValuationAndReportComp.css";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { ET_AUTOMATED_BUSINESS_START_BROWSING_LINK_CLICK } from "../../../common/trackingConstants";

const BussValuationAndReportComp = (props) => {
    const {
        heading = "",
        valuationAndCalculatorCards = [],
        automatedBusinessValuation = {}
    } = props;
    return <div className="buss-valuation-report-comp">
        <h2 className="buss-valuation-heading m-0">{heading}</h2>
        <div className="buss-valuation-calculator-main">
            <div className="buss-valuation-calculator-container">
                {valuationAndCalculatorCards.map((card, index) => {
                    return <BoxWithImageAndFeatureDetails
                        heading={card.heading}
                        subHeading={card.subHeading}
                        imageObj={card.imageObj}
                        key={`valuationAndCalculatorCard_${index}`}
                    />
                })}
            </div>
            <div className="automated-bussiness-val-wrapper">
                <AutomatedBusinessValComp
                    {...automatedBusinessValuation}
                />
            </div>
        </div>
    </div>
}

const BoxWithImageAndFeatureDetails = (props) => {
    const {
        heading = "",
        subHeading = "",
        imageObj = {}
    } = props;
    return <div className="box-with-image-feature-details">
        <div className="feature-details-container">
            <h2 className="feature-details-heading">{heading}</h2>
            <p className="feature-details-subHeading m-0 pom-font-18-18-16">{subHeading}</p>
        </div>
        <div className="feature-image-conatiner">
            <picture>
                <source data-srcset={imageObj.webpImageUrl} />
                <img
                    data-src={imageObj.imageUrl}
                    width={imageObj.width}
                    className={`lazyload feature-details-image ${imageObj.className}`}
                    height={imageObj.height} alt={imageObj.alt}
                />
            </picture>
        </div>
    </div>
}

const AutomatedBusinessValComp = (props) => {
    const {
        heading = "",
        subHeading = "",
        linkNote = "",
        imageObj = {},
        linkNoteClick = () => { }
    } = props;

    return <div className="automated-bussiness-val-container">
        <div className="automated-bussiness-info">
            <h2 className="feature-details-heading m-0">{heading}</h2>
            <p className="pom-font-18-18-16 m-0">{subHeading}</p>
            <p className="pom-font-18 yellow-text-with-pointer m-0 font-weight-600" onClick={() => linkNoteClick({
                eventType: ET_AUTOMATED_BUSINESS_START_BROWSING_LINK_CLICK
            })} dangerouslySetInnerHTML={{ __html: linkNote }} />
        </div>
        <div className="automated-bussiness-image-container">
            <MediaQuery minWidth={576}>
                <picture>
                    <source data-srcset={imageObj.desktopImage.webpImageUrl} />
                    <img
                        data-src={imageObj.desktopImage.imageUrl}
                        width={imageObj.desktopImage.width}
                        className="lazyload automated-buss-image"
                        height={imageObj.desktopImage.height} alt={imageObj.desktopImage.alt}
                    />
                </picture>
            </MediaQuery>
            <MediaQuery maxWidth={575.98}>
                <picture>
                    <source data-srcset={imageObj.mobileImage.webpImageUrl} />
                    <img
                        data-src={imageObj.mobileImage.imageUrl}
                        width={imageObj.mobileImage.width}
                        className="lazyload automated-buss-image"
                        height={imageObj.mobileImage.height} alt={imageObj.mobileImage.alt}
                    />
                </picture>
            </MediaQuery>
        </div>
    </div>
}

export default BussValuationAndReportComp;